import React from "react";
import Card from "../../../../../components/Card/Card";
import ButtonMain from "../../../../../components/ButtonMain/ButtonMain";
import "./exchangePrizesCard.scss";
import useWindowSize from "../../../../../hooks/useWindowSize";

/**
 *
 * @param {String} imgSource
 * @param {String} prizeText
 * @param {String} prizeDescription
 * @param {Boolean} prizeDescription
 * @param {Function} openExchangeModal
 * @param {Function} redirectToFriends
 * 
 *
 *
 *
 *
 */

const ExchangePrizesCard = ({
	imgSource,
	prizeText,
	prizeDescription,
	hasEnoughFriends,
	openExchangeModal,
	redirectToFriends,
	requiredFriends,
	ruralkaLink
}) => {

	const { width } = useWindowSize();

	return (
		<div className="single-prize-container">
			{!hasEnoughFriends ? (
				<div className="friends-info not-enough-friends">
					<span className="not-enough">!Vaya! Necesitas más amigos para canjear este premio</span>
				</div>
			) : (
				<div className="friends-info enough-friends">
					<span className="enough">¡Genial! Puedes canjear este premio</span>
				</div>
			)}

			<Card>
				<div className="flex-container">
					{width > 980 ? (
						<div className="exchange-prizes-image">
							<img className="prize-cover" src={imgSource} alt="" />
						</div>
					) : null
					}
					<div className="exchange-prizes-text">
						<div className="header-prize">
							{width <= 980 ? (
								<div className="prize-image-container">
									<img className="prize-cover" src={imgSource} alt="" />
								</div>
							) : null
							}
							<div className="prize-header-text">
								<h2>{prizeText}</h2>
								<p className="optional-info">{requiredFriends} {requiredFriends !== 1 ? "Amigos" : "Amigo"}</p>
							</div>
						</div>
						<p>{prizeDescription}</p>
						{hasEnoughFriends ? <ButtonMain label="Canjea tu premio" action={openExchangeModal} /> : <ButtonMain label="Consigue más amigos" action={redirectToFriends} />}
						{
							ruralkaLink && <a target="_blank" style={{cursor: 'pointer'}} href={ruralkaLink.href}>
								<p><strong> {ruralkaLink.label}</strong></p>
							</a>
						}
					</div>
				</div>

			</Card>
		</div>
	);
};

export default ExchangePrizesCard;
