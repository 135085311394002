import React, { useEffect, useRef, useState } from "react";
import { MENUITEMS } from "../constants";
import Header from "../Header/Header";
import Menu from "../Menu/Menu";
import * as api from "../../services/services";
import { useAuth } from "../../provider";
import { useLocation } from "react-router-dom";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import Select from "../Select/Select";
/**
 *
 * @param {any} props
 * @description Este es el layout que se renderizará en las rutas privadas.
 * Se encarga también de hacer la llamada a la api que seteará los datos del usuario.
 *
 */

const Layout = (props) => {
	const { state, setUserData, handleLogout, listOfClinic, setUserToken, setIsClinicSelected, setPlanAmigo } = useAuth();
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [errorStatus, setErrorStatus] = useState(null);
	const [selectValue, setSelectValue] = useState(undefined);

	const location = useLocation();
	const getUserName = () => {
		return `${state.userData.nombre} ${state.userData.primerApellido} ${state.userData.segundoApellido}`;
	};

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			getDataAsync();
		}

		return () => {
			mounted = false;
		};
	}, []);


	const changePlanAmigo = async () => {
		let response = await api.getUserData(state.user);
		await setUserData(response);
		await setPlanAmigo(response.planAmigo);
	}

	useEffect(() => {
		if (!selectValue) {
			setIsClinicSelected(localStorage.isClinicSelected);
			return () => null;
		}
		if (listOfClinic.length > 1) {
			setUserToken(selectValue);
			localStorage.setItem('userToken', selectValue);
			changePlanAmigo();
		} else {
			setUserToken(state.userToken);
			localStorage.setItem('userToken', state.userToken);
		}
	}, [selectValue]);

	const getDataAsync = async () => {
		setLoading(state.userData ? false : true);
		setErrorMessage(null);
		setErrorStatus(null);
		if (!state.userData) {
			try {
				let response = await api.getUserData(state.user);
				await setUserData(response);
				setLoading(response ? false : true);
			} catch (error) {
				setErrorMessage(error);
				setErrorStatus(error);
			}
		}
	};

	const selectClinicAction = async (option) => {
		setSelectValue(option.value);
		await setIsClinicSelected(option.name)
		localStorage.setItem("isClinicSelected", option.name);
	}
	/**
	 * @description Renderizado del componente
	 */

	return (
		<React.Fragment>
			<div className="account-container">
				<Menu menuItems={MENUITEMS} />
				<div className="page-content">
					<Header
						userName={state.userData ? getUserName() : null}
						loading={loading}
						onLogout={handleLogout}
					></Header>
					{state.isClinicSelected && listOfClinic.length > 1 ?
						<span className="layout_select_clinic_style">
							<Select
								Options={listOfClinic.map((item) => ({ value: item.UserToken, name: item.ClinicName }))}
								width={"100%"}
								height={"60px"}
								actionOnSelect={(option) => { selectClinicAction(option) }}
							/>
						</span>
						: ""
					}
					<div className="main-content">
						<h1>
							{location.pathname.split("/")[2] === "Plan"
								? "Plan Amigo"
								: location.pathname.split("/")[2] === "SubirDocumento"
									? "Subir Documento"
									: location.pathname.split("/")[2]}
						</h1>
						<div className="content-wrapper">
							{errorMessage ? (
								<ErrorHandler errorMessage={errorMessage} errorStatus={errorStatus} />
							) : null}
							{props.children}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Layout;
