import React, { useState, useEffect } from "react";
import "./fuel.scss";
import * as api from "../../../../../services/plan-amigo-service";
import ExchangePrizesCard from "../ExchangePrizeCard/ExchangePrizesCard";
import { useHistory } from "react-router-dom";
import ButtonMain from "../../../../../components/ButtonMain/ButtonMain";
import MessageModal from "../../../../../components/MessageModal/MessageModal";
import { usePlanAmigo } from "../../../../../plan-amigo-provider";
import fuelCover from "../../../../../assets/images/car-gas.jpg";
import fuelIcon from "../../../../../assets/images/icons/gasoline-icon.svg"
import prizesIcon from "../../../../../assets/images/icons/gift-color-icon.svg";

import useWindowSize from "../../../../../hooks/useWindowSize";
import ModalBox from "../../../../../components/ModalBox/ModalBox";
import Loading from "../../../../../components/Loading/Loading";
import { useLocation } from "react-router-dom";
import Hotel from "../Hotel/Hotel";


function useQuery() {
	return new URLSearchParams(useLocation().search);
}


const PrizesDetail = ({ setCurrentLocation }) => {
	const [exchangeModalOpen, setExchangeModalOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [successMessage, setSuccessMessage] = useState(null);
	const [loading, setLoading] = useState(false);
	const { width } = useWindowSize();
	const { planAmigoState, setFriendsAndPrizes } = usePlanAmigo()
	const [fuelConfig, setfuelConfig] = useState()

	const history = useHistory();

	let query = useQuery();
	let prizeId = parseInt(query.get("prizeId")); // Igual que antes

	useEffect(() => {
		let fetch = true
		if (prizeId) {
			api.getPrize(prizeId)
				.then(res => {
					const prize = res.body;

					setfuelConfig(
						{
							subtitle: prize.title,
							prizeDescription:
								prize.desc_larga,
							friendsTarget: prize.puntos,
							disclaimer: prize.disclaimer,
							imagen: prize.imagen,
							ruralkaLink: prize.ruralkaLink ? prize.ruralkaLink : prizeId === 14 ? {
								label: 'Spas Clinica Baviera',
								href: 'https://www.ruralka.com/promocion/spas-clinica-baviera'
							} : null
						}
					)
				})
		} else {
			history.back()
		}

		return () => {
			fetch = false
		}
	}, [prizeId])



	/**
	 * Setea la localización a la url correcta
	 */

	useEffect(() => {
		setCurrentLocation(history.location.pathname);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history.location.pathname]);

	//////////////////////////
	/// Gestión de eventos
	//////////////////////////

	const toggleExchangeModal = () => setExchangeModalOpen(!exchangeModalOpen);
	const redirectToFriends = () =>
		history.push("/Paciente/Plan/Recomienda-clinica-baviera-tus-amigos");

	const exchange = async () => {
		try {
			setLoading(true);
			const data = {
				"tipo-premio": prizeId,
			};

			setExchangeModalOpen(false);

			const res = await api.requestExchangePrize(data);


			if (Number(res.status) === 200 || res.status === "ok") {
				const res = await api.requestPointsAndPrizesByDNI();
				const { puntos, premios, status, url_pedir_cita_amigos, status_code } =
					res;

				if (status_code === 3) {
					setFriendsAndPrizes(
						puntos.disponibles,
						[],
						premios,
						true,
						url_pedir_cita_amigos
					);
				}
				setLoading(false);
				setSuccessMessage("Has realizado tu solicitud con éxito");
			}

			if (res.status === 400 || res.body.status === "error") {
				setLoading(false);
				setExchangeModalOpen(false);
				setErrorMessage("Ha ocurrido un error con tu petición");
			}
		} catch (error) {
			setExchangeModalOpen(false);
			setErrorMessage(error);
			setLoading(false);
		}
	};


	return loading || !fuelConfig ? (
		<ModalBox>
			<Loading />
		</ModalBox>
	) : (
		<React.Fragment>
			{exchangeModalOpen && (
				<MessageModal modalToggle={toggleExchangeModal} isError={false}>
					<div className="modal-icon">
						<img src={prizesIcon} alt="" />
					</div>
					<h4>Canjea tu Premio</h4>
					<p className="modal-exchange-description">¿Estás seguro de que quieres canjear tu premio o prefieres esperar a acumular más amigos?</p>
					<div className="flex-container">
						<ButtonMain customClass="tertiary" label="Voy a esperar" action={toggleExchangeModal} />
						<ButtonMain label="Canjear ya" action={exchange} />
					</div>
				</MessageModal>
			)}
			{successMessage && (
				<MessageModal
					modalMessage="Has canjeado tu premio satisfactoriamente"
					setModalMessage={setSuccessMessage}
					isError={false}
				></MessageModal>
			)}
			{errorMessage && (
				<MessageModal
					modalMessage={errorMessage}
					setModalMessage={setErrorMessage}
					isError={true}
				></MessageModal>
			)}
			<ExchangePrizesCard
				prizeText={fuelConfig.subtitle}
				prizeDescription={fuelConfig.prizeDescription}
				imgSource={fuelConfig.imagen ?? fuelCover}
				requiredFriends={fuelConfig.friendsTarget}
				ruralkaLink={fuelConfig.ruralkaLink}
				hasEnoughFriends={fuelConfig.friendsTarget <= planAmigoState.numberOfFriends}
				openExchangeModal={toggleExchangeModal}
				redirectToFriends={redirectToFriends}
			/>
			{fuelConfig.disclaimer && <small className="info-amazon">*Amazon.es no patrocina esta promoción. Se aplican restricciones. Ver detalles en <a rel="noreferrer" href="https://www.amazon.es/gp/help/customer/display.html/?nodeId=200656270" target="_blank">Amazon.es/cheques-regalo-condiciones</a></small>
			}
			{prizeId === 5 && <div className='hotels-page'>
				<Hotel setCurrentLocation={setCurrentLocation} />
			</div>}
		</React.Fragment>
	);
};

export default PrizesDetail;
