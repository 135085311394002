import React from "react";
import "./updating.scss"

/**
 * 
 * @param {Boolean} isRefreshing 
 * @description Es el componente que renderiza un loader. 
 * A diferencia de Loading, sólo se ejecuta si hay información en el estado, 
 * Updating se renderiza cuando se está actualizando la información con una 
 * llamada a la API, haya información en el estado o no.  
 * 
 */


const Updating = ({ isRefreshing }) => {
  return (
    <React.Fragment>
      {isRefreshing ? (
        <div className="update-info updating-text">
          <span className="updating"></span>
          Actualizando...
        </div>
      ) : (
        <div className="update-info updated-text">
          <span className="updated"></span>
          Actualizado
        </div>
      )}
    </React.Fragment>
  );
};

export default Updating;
