import React, { useMemo, useReducer, useContext, useState, useEffect } from "react";
import axios from "axios";
import { AUTHENTICATE, API_USER, API_PASSWORD } from "./constants";
import { loginSession, getUserData } from "./services/services"

//IMPORT REDUCER, INITIAL STATE AND ACTION TYPES
import reducer, {
  initialState,
  LOGGED_IN,
  LOGGED_OUT,
  SET_USER_DATA,
  UPDATE_USER_DATA,
  SET_USER_INVOICES,
  SET_USER_REPORTS,
  SET_USER_REPORT_TYPES,
  SET_IS_LOADING,
  SET_USER_APPOINMENTS,
  SET_USER_MEDICATION,
  SET_USER_NOTES,
  SET_ERROR,
  CLEAR_ERROR,
  HAS_SATISFACTION_POLL,
  SESSION,
  IS_NEAR_BOTTOM,
  IS_NOT_NEAR_BOTTOM,
  SET_CLINIC_SELECTED,
  SET_USER_TOKEN,
  SET_USER_USER_TOKEN,
  SET_PLAN_AMIGO
} from "./reducer";

import planAmigoReducer, { DELETE_STATE } from "./plan-amigo-reducer"

import { useHistory } from "react-router-dom";

// CONFIG KEYS [Storage Keys]===================================
export const API_URL = "http://asp.clinicabaviera.com/APIPacienteTest/api";
export const TOKEN_KEY = "token";
export const USER_KEY = "user";
export const keys = [TOKEN_KEY, USER_KEY];

// CONTEXT ===================================
const AuthContext = React.createContext();

function AuthProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState || {});
  const [planAmigoState, dispatchPlanAmigo] = useReducer(planAmigoReducer, initialState || {})
  const [userResponse, setUserResponse] = useState({});
  const [listOfClinic, setListOfClinic] = useState({});
  const history = useHistory()

  // Get Auth state
  const getAuthState = async () => {
    try {
      //GET TOKEN && USER
      let userToken = await localStorage.getItem("userToken");
      if (userToken !== null) await handleSession(userToken);
      else await handleLogout();

      return { userToken };
    } catch (error) {
      console.log(error)
      // throw new Error(error);
    }
  };
  // Get Auth Token
  const getToken = async () => {
    try {
      let token = await localStorage.getItem(TOKEN_KEY);
      if (!token) {
        await handleToken();
        token = await localStorage.getItem(TOKEN_KEY);
      }
      return token;
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleSession = async (userToken) => {
    try {
      if (userToken) {
        const res = await loginSession(userToken)
        setListOfClinic(res.user);
        let newResponse = {
          token: res.user[0].UserToken,
          userToken: res.user[0].UserToken,
          user: {
            idClinica: res.user[0].ClinicId,
            idPaciente: res.user[0].PatientId,
            isPasswordReset: res.user[0].IsPasswordReset,
            isnewUser: res.user[0].IsNewUser,
            userToken: res.user[0].UserToken,
          }
        }
        const userData = await getUserData(newResponse.user);
        await setUserData(userData);
        handleLogin(newResponse)

      } else {
        console.log('Not token')
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  const handleToken = async () => {
    try {
      let tokenApiCall = await fetch(AUTHENTICATE, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ User: API_USER, Password: API_PASSWORD }),
      });
      const token = await tokenApiCall.json();
      await localStorage.setItem(TOKEN_KEY, token);

      //AXIOS AUTHORIZATION HEADER
      axios.defaults.headers.common["Authorization"] = token;

      // //DISPATCH TO REDUCER
      //dispatch({type: AUTHENTICATE_APP, token:token });

      return token;
    } catch (error) {
      throw new Error(error);
    }
  };

  // Handle Login
  const handleLogin = async (data) => {
    try {
      //STORE DATA
      let { token, user } = data;
      // await localStorage.setItem(USER_KEY, JSON.stringify(user));
      await localStorage.setItem("userToken", user.userToken);
      if (token) {
        await localStorage.setItem(TOKEN_KEY, token);
        //AXIOS AUTHORIZATION HEADER
        axios.defaults.headers.common["Authorization"] = `${data.token}`;

        //DISPATCH TO REDUCER
        dispatch({
          type: LOGGED_IN,
          token: token,
          user: user,
          userToken: user.userToken,
          isLoggedIn: user.isPasswordReset == 1 || user.isnewUser ? false : true,
        });

      } else {
        axios.defaults.headers.common["Authorization"] = `${data.token}`;
        token = await localStorage.getItem("token", user.userToken);
        const location = await localStorage.getItem("location", user.userToken);

        //DISPATCH TO REDUCER
        dispatch({
          type: SESSION,
          token: token,
          user,
          userToken: user.userToken,
          lastLocation: location,
          isLoggedIn: user.isPasswordReset === 1 || user.isnewUser ? false : true,
        });

      }

    } catch (error) {
      throw new Error(error);
    }
  };



  // HANDLE LOGOUT
  const handleLogout = async () => {
    try {
      //REMOVE DATA
      await localStorage.clear();
      setIsClinicSelected(undefined);
      //AXIOS AUTHORIZATION HEADER
      delete axios.defaults.headers.common["Authorization"];


      // DISPATCH TO PLAN AMIGO REDUCER
      dispatchPlanAmigo({ type: DELETE_STATE })
      //DISPATCH TO REDUCER
      dispatch({ type: LOGGED_OUT });




    } catch (error) {
      throw new Error(error);
    }
  };

  //UPDATE USER LOCAL STORAGE DATA AND DISPATCH TO REDUCER
  const updateUser = async (user) => {
    try {
      await localStorage.setItem(USER_KEY, JSON.stringify(user));
      dispatch({
        type: LOGGED_IN,
        user,
        isLoggedIn: user.isPasswordReset === 1 || user.isnewUser ? true : false,
      }); //DISPATCH TO REDUCER
    } catch (error) {
      throw new Error(error);
    }
  };

  // GET USER DATA

  const setUserData = async (response) => {
    try {
      dispatch({
        type: SET_USER_DATA,
        userData: response,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  // UPDATE USER DATA

  const updateUserData = async (email, telefono) => {
    try {
      dispatch({
        type: UPDATE_USER_DATA,
        email,
        telefono
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  // SET USER ALERTS

  const setUserAlerts = async (response) => {
    try {
      dispatch({
        type: UPDATE_USER_DATA,
        response,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  // UPDATE USER ALERTS

  const updateUserAlerts = async (updatedData) => {
    try {
      dispatch({
        type: UPDATE_USER_DATA,
        updatedData,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  // GET USER'S INVOICES AND BUDGETS

  const setInvoices = async (response) => {
    try {
      dispatch({
        type: SET_USER_INVOICES,
        userInvoices: response,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  // SET USER'S REPORTS

  const setReports = async (response) => {
    try {
      dispatch({
        type: SET_USER_REPORTS,
        userReports: response,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  // SET USER'S MEDICATION

  const setMedication = async (response) => {
    try {
      dispatch({
        type: SET_USER_MEDICATION,
        userMedication: response,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  // SET USER'S NOTES

  const setNotes = async (response) => {
    try {
      dispatch({
        type: SET_USER_NOTES,
        userNotes: response,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  // SET USER'S REPORTS

  const setUserReportTypes = async (response) => {
    try {
      dispatch({
        type: SET_USER_REPORT_TYPES,
        userReportTypes: response,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  // SET USER APPOINMENTS

  const setUserAppointments = async (userAppoinments) => {
    try {
      dispatch({
        type: SET_USER_APPOINMENTS,
        userAppoinments,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  // SET REDUX LOADING

  const setGlobalLoading = async (value) => {
    try {
      dispatch({
        type: SET_IS_LOADING,
        globalLoading: value,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  // SET SERVER ERROR

  const setGlobalError = async (error) => {
    try {
      dispatch({
        type: SET_ERROR,
        globalError: error,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const clearError = async () => {
    try {
      dispatch({
        type: CLEAR_ERROR,
      });
    } catch (error) {
      throw new Error(error);
    }
  };


  // SET SATISFACTION POLL


  const setSatisfactionPoll = async (value) => {
    try {
      dispatch({
        type: HAS_SATISFACTION_POLL,
        satisfactionPoll: value
      })
    } catch (error) {
      throw new Error(error);

    }
  }

  const setIsNearBottom = async () => {
    try {
      dispatch({
        type: IS_NEAR_BOTTOM,
      })
    } catch (error) {
      throw new Error(error);

    }
  }


  const setIsNotNearBottom = async () => {
    try {
      dispatch({
        type: IS_NOT_NEAR_BOTTOM,
      })
    } catch (error) {
      throw new Error(error);

    }
  }

  // SET USER TOKEN

  const setUserToken = async (value) => {
    try {
      dispatch({
        type: SET_USER_TOKEN,
        userToken: value
      })
    } catch (error) {
      throw new Error(error);

    }
  }
  // SET USER_USER TOKEN

  const setUserUserToken = async (value) => {
    try {
      dispatch({
        type: SET_USER_USER_TOKEN,
        userToken: value
      })
    } catch (error) {
      throw new Error(error);

    }
  }
  // SET PLAN_AMIGO

  const setPlanAmigo = async (value) => {
    try {
      dispatch({
        type: SET_PLAN_AMIGO,
        planAmigo: value
      })
    } catch (error) {
      throw new Error(error);

    }
  }
  // SET CLINIC SELECTED

  const setIsClinicSelected = async (value) => {
    try {
      dispatch({
        type: SET_CLINIC_SELECTED,
        isClinicSelected: value
      })
    } catch (error) {
      throw new Error(error);
    }
  }



  const value = useMemo(() => {
    return {
      state,
      getAuthState,
      handleLogin,
      handleLogout,
      handleToken,
      getToken,
      updateUser,
      userResponse,
      setUserResponse,
      updateUserData,
      setInvoices,
      setReports,
      setMedication,
      setNotes,
      setUserReportTypes,
      setUserData,
      setGlobalLoading,
      updateUserAlerts,
      setUserAlerts,
      setUserAppointments,
      setGlobalError,
      clearError,
      setSatisfactionPoll,
      setIsNearBottom,
      setIsNotNearBottom,
      setIsClinicSelected,
      listOfClinic,
      setListOfClinic,
      setUserToken,
      setUserUserToken,
      setPlanAmigo
    };
  }, [state]);

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}

const useAuth = () => useContext(AuthContext);
export { AuthContext, useAuth };
export default AuthProvider;