import React from "react";
import { Link } from "react-router-dom";
import "./linkButton.scss";

const LinkButton = ({ url, urlLabel, styles, type}) => {
	return (
		<Link to={url} className={`link-button-app ${type} primary`} {...styles}>
			<span className="link-button-label">{urlLabel}</span>
			<span className="link-button-icon arrow-right-white"></span>
		</Link>
	);
};

export default LinkButton;

