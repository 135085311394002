import React from "react";
import ButtonMain from "../../../../../components/ButtonMain/ButtonMain";
import "./requestReport.scss";

import { Form, Formik } from "formik";
import * as yup from "yup";
import { useAuth } from "../../../../../provider";
import ErrorDialog from "../../../../../components/ErrorDialog/ErrorDialog";

/**
 *
 * @param {Array} reportTypes
 * @param {Function} onSubmitAction
 *
 * @description Modal que contiene el formulario para solicitar un informe médico
 */

const RequestReport = ({ reportTypes, onSubmitAction }) => {
	const { state } = useAuth();
	const getType = (typeNumber) => reportTypes.find((t) => Number(t.value) === Number(typeNumber));

	const reviewSchema = yup.object({
		idClinica: yup.string().required(),
		idPaciente: yup.string().required(),
		idtipoinforme: yup.string().required(),
		comentarios: yup.string().when("idtipoinforme", {
			is: (idtipoinforme) => {
        const selectedType = getType(idtipoinforme);
				return selectedType.isRequired;
			},
			then: yup.string().required(`Debes de rellenar los comentarios`),
		}),
	});

	/////////////////////////////
	// Renderizado del componente
	/////////////////////////////

	return (
		<Formik
			initialValues={{
				idClinica: state.user.idClinica,
				idPaciente: state.user.idPaciente,
        idtipoinforme: reportTypes ? reportTypes[0]?.value : {},
        comentarios: ""
			}}
			validationSchema={reviewSchema}
			onSubmit={(values, actions) => {
				actions.resetForm();
				onSubmitAction(values);
			}}
		>
			{(props) => {
				return (
					<Form className="form-container" onSubmit={props.onSubmit}>
						<h3>Solicitar informe</h3>
						<p>Indica el tipo de informe que quieres solicitar:</p>
						<div className="radio-group">
							{reportTypes.map((type) => {
								return (
									<div className="form-control" key={type.key}>
										<label className="radio-label">
											<input
												type="radio"
												value={type.value}
												onChange={(e) => {
													props.setFieldValue("idtipoinforme", Number(e.target.value));
												}}
												checked={props.values.idtipoinforme === type.value}
											/>
											<span>{type.label}</span>
										</label>
									</div>
								);
							})}
						</div>
						<div className="form-control textarea-container">
							<label>
								<span>Comentarios</span>
								<textarea
									cols="30"
									placeholder="Comentarios"
									onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.comentarios}
                  name="comentarios"
								></textarea>

								{props.errors.comentarios ? (
									<ErrorDialog text={props.errors.comentarios} />
								) : null}
							</label>
						</div>
						<ButtonMain
							label="Continuar"
							action={props.handleSubmit}
							type="submit"
							isSubmitting={!props.isValid}
						/>
					</Form>
				);
			}}
		</Formik>
	);
};

export default RequestReport;
