import React from "react";
import { Link } from "react-router-dom";
import { setLabeltoClass } from "../../../helpers/helpers.js";
import "./tab-component.scss";

const TabComponent = ({ label, activeTab, handleClick, url, customClass }) => {
	const onClickHandler = () => {
		handleClick(url);
	};

	let className = activeTab.label === label ? "list-item list-active" : "list-item";

	// Revisar función
	//const styledLabel = setLabeltoClass(label);

	return (
		<Link onClick={onClickHandler} className={`${className} ${customClass}`} to={url}>
			<span>{label}</span>
		</Link>
	);
};

export default TabComponent;
