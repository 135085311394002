import React from "react";
import "./card.scss";

/**
 *
 * @param {any} props
 * @param {Component} props.children
 * @param {string} props.customClass
 *
 * @description Contenedor card que renderiza, además, los componentes que se le pasen por props (children)
 *
 */

const Card = (props) => {
  const { children, customClass } = props;
  return <div className={`card ${!customClass ? "" : customClass}`}>{children}</div>;
};

export default Card;
