export const MENUITEMS = [
  {
    id: 0,
    label: "Mi cuenta",
    cssClass: "account",
    link: "/Paciente/Cuenta",    
  },
  {
    id: 1,
    label: "Informes",
    cssClass: "reports",
    link: "/Paciente/Informes",
  },
  {
    id: 2,
    label: "Calendario",
    cssClass: "calendar",
    link: "/Paciente/Calendario",
  },
  {
    id: 3,
    label: "Contacto",
    cssClass: "contact",
    link: "/Paciente/Contacto",
  },
  {
    id: 4,
    label: "Plan Amigo",
    cssClass: "friend",
    link: "/Paciente/Plan",
  },
];


export const previousPathsArray = [
  {
    currentPath: "/Paciente/Plan/Premios/tarjeta-carburantes", 
    previousPath: "/Paciente/Plan/Premios"
  }, 
  {
    currentPath: "/Paciente/Plan/Premios/elige-tu-spa", 
    previousPath: "/Paciente/Plan/Premios"
  }, 
  {
    currentPath: "/Paciente/Plan/Premios/50€-en-tarjeta-decathlon", 
    previousPath: "/Paciente/Plan/Premios"
  }, 
  {
    currentPath: "/Paciente/Plan/Premios/tarjeta-amazon", 
    previousPath: "/Paciente/Plan/Premios"
  }, 
  {
    currentPath: "/Paciente/Plan/Premios/elige-tu-hotel-experiencia-ruralka", 
    previousPath: "/Paciente/Plan/Premios"
  }, 
]



export const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
