import React, { useEffect } from "react";
import { useAuth } from "../../provider";

const InfinityScroll = ({ children }) => {
	const { setIsNearBottom, setIsNotNearBottom } = useAuth();

	const scrollable = document.querySelector(".page-content");

	useEffect(() => {
		scrollable.addEventListener("scroll", e => onScroll(e));
		return () => scrollable.removeEventListener("scroll", e => onScroll(e) )
   		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onScroll = (e) => {
		const scrollTop = e.target.scrollTop;
		const scrollHeight = e.target.scrollHeight;
		const clientHeight = e.target.clientHeight;

		if (scrollTop + clientHeight + 200 >= scrollHeight) {
			setIsNearBottom();
		} else {
			setIsNotNearBottom(); 
		}
	};

	return <React.Fragment>{children}</React.Fragment>;
};

export default InfinityScroll;
