import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ExchangePrizesCard from "../ExchangePrizeCard/ExchangePrizesCard";
import * as api from "../../../../../services/plan-amigo-service";
import "./decathlon.scss";
import MessageModal from "../../../../../components/MessageModal/MessageModal";
import ButtonMain from "../../../../../components/ButtonMain/ButtonMain";
import { usePlanAmigo } from "../../../../../plan-amigo-provider";
import decathlonCover from "../../../../../assets/images/shopping-3.png";
import decathlonIcon from "../../../../../assets/images/icons/shopping-icon.svg"
import useWindowSize from "../../../../../hooks/useWindowSize";
import ModalBox from "../../../../../components/ModalBox/ModalBox";
import Loading from "../../../../../components/Loading/Loading";

const Decathlon = ({setCurrentLocation}) => {
	const decathlonConfig = {
		subtitle: "50€ en Tarjeta Decathlon",
		prizeDescription:
			"Prepárate para disfrutar de tu deporte favorito en Decathlon con esta tarjeta por valor de 50€.",
		friendsTarget: 3,
	};

	const [exchangeModalOpen, setExchangeModalOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [successMessage, setSuccessMessage] = useState(null);
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const {planAmigoState,setFriendsAndPrizes } = usePlanAmigo();
	const {width} = useWindowSize();


	/**
	 * Setea la localización a la url correcta
	 */

	useEffect(() => {
		setCurrentLocation(history.location.pathname);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history.location.pathname]);

	const redirectToFriends = () => 
		history.push("/Paciente/Plan/Recomienda-clinica-baviera-tus-amigos");

	const toggleExchangeModal = () => setExchangeModalOpen(!exchangeModalOpen);

	const exchangeDecathlon = async () => {
		try {
			setLoading(true);
			const data = {
				"tipo-premio": 13,
			};

			setExchangeModalOpen(false);


			const res = await api.requestExchangePrize(data);

			if (Number(res.status) === 200 || res.status === "ok") {
				const res = await api.requestPointsAndPrizesByDNI();
      	const { puntos, premios, status, url_pedir_cita_amigos, status_code } =
        res;

				if (status_code === 3) {
					setFriendsAndPrizes(
						puntos.disponibles,
						[],
						premios,
						true,
						url_pedir_cita_amigos
					);
				}

				setLoading(false);
				setSuccessMessage("Has realizado tu solicitud con éxito");
			}

			if (res.status === 400 || res.body.status === "error") {
				setLoading(false);
				setExchangeModalOpen(false);
				setErrorMessage("Ha ocurrido un error con tu petición");
			}
		} catch (error) {
			setExchangeModalOpen(false);
			setErrorMessage(error);
			setLoading(false);
		}
	};

	return (
		<React.Fragment>

			{loading && (
				<ModalBox>
					<Loading/>
				</ModalBox>
			)}
				{exchangeModalOpen && (
				<MessageModal modalToggle={toggleExchangeModal} isError={false}>
				<div className="modal-icon">
					<img src={decathlonIcon} alt=""/>
				</div>
				<h4>Canjea tu tarjeta</h4>
				<p className="modal-exchange-description">¿Estás seguro de que quieres canjear tu premio o prefieres esperar a acumular más amigos?</p>
				<div className="flex-container">
					<ButtonMain customClass="tertiary" label="Voy a esperar" action={toggleExchangeModal} />
					<ButtonMain label="Canjear ya" action={exchangeDecathlon} />
				</div>
			</MessageModal>
			)}
			{successMessage && (
				<MessageModal
					modalMessage="Has canjeado tu premio satisfactoriamente"
					setModalMessage={setSuccessMessage}
					isError={false}
				></MessageModal>
			)}
			{errorMessage && (
				<MessageModal
					modalMessage={errorMessage}
					setModalMessage={setErrorMessage}
					isError={true}
				></MessageModal>
			)}
			<ExchangePrizesCard
				prizeText={decathlonConfig.subtitle}
				prizeDescription={decathlonConfig.prizeDescription}
				hasEnoughFriends={decathlonConfig.friendsTarget <= planAmigoState.numberOfFriends}
				openExchangeModal={toggleExchangeModal}
				requiredFriends = {decathlonConfig.friendsTarget}

				imgSource={width <= 980 ? decathlonIcon : decathlonCover}
				redirectToFriends={redirectToFriends}
			/>
		</React.Fragment>
	);
};

export default Decathlon;
