import React, { useEffect, useState } from "react";
import isSameDay from "react-dates/lib/utils/isSameDay";

import "react-dates/initialize";
import moment from "moment";
import "moment/locale/es";

import "react-dates/lib/css/_datepicker.css";
import "./calendarPicker.scss";

import { DayPickerSingleDateController } from "react-dates";

import useWindowSize from "../../../../hooks/useWindowSize";

/**
 *
 * @param {Array} appointmentDates Lista de fechas en las que el paciente tiene una cita
 * @description Renderizado del componente de calendario
 */

const CalendarPicker = ({ appointmentDates, setSelectedDateString }) => {
  /////////////////////////////
  // Configuración del componente
  /////////////////////////////

  moment().locale("es");

  const [datesList, setDatesList] = useState([]);
  const [calendarWidth, setCalendarWidth] = useState(null);
  const [datesToString, setDatesToString] = useState("");
  const { width } = useWindowSize();
  const today = moment();

  const [initialDate, setInitialDate] = useState(today);
  const [focused, setFocused] = useState(false);

  const formatCalendarWidth = (width) => {
    //a partir de 1080 no debe ejecutarse la función
    if (width <= 320) return 35;
    else if (width <= 414 || width <= 1080) return 40;
    else if (width <= 980) return 50;
    else return 50;
  };

  useEffect(() => {
    setCalendarWidth(formatCalendarWidth(width));
  }, [width]);

  useEffect(() => {
    if (appointmentDates) {
      const formatedDates = appointmentDates.map((item) => moment(item.fecha));
      const datesInString = formatedDates.map((date) => {
        return formatDateToString(date);
      });
      setDatesList(formatedDates);
      setDatesToString(datesInString);
    }
  }, [appointmentDates]);

  const formatDateToString = (date) => {
    return moment(date).format("DD/MM/yyyy").split("/").join("");
  };

  /////////////////////////////
  // Renderizado del componente
  /////////////////////////////

  const handleDateChange = (date) => {
    setSelectedDateString(null);
    
    const selectedDateToString = formatDateToString(date);
    
    if (datesToString.includes(selectedDateToString)) {
      setSelectedDateString(selectedDateToString);
    }
  };


  return (
    <React.Fragment>
      <DayPickerSingleDateController
        numberOfMonths={1}
        hideKeyboardShortcutsPanel={true}
        daySize={calendarWidth}
        isDayHighlighted={(day1) => datesList.some((day2) => isSameDay(day1, day2))}
        date={initialDate} // momentPropTypes.momentObj or null
        onDateChange={handleDateChange} // PropTypes.func.isRequired
        focused={false} // PropTypes.bool
        onFocusChange={({ focused }) => setFocused({ focused })} // PropTypes.func.isRequired
      ></DayPickerSingleDateController>
    </React.Fragment>
  );
};

export default CalendarPicker;
