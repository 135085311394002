import React from 'react'
import "./prizeItem.scss"

const PrizeItem = ({prizeItem}) => {
    return (
        <div className="prize-item-container">
            <a href={prizeItem.url} target="_blank" rel="noreferrer">
                <div className="image-container">
                    <img src={prizeItem.image} alt=""/>
                </div>
                <div className="info-container">
                    <h3 className="spa-name">{prizeItem.name}</h3>
                    <p className="spa-province">{prizeItem.province}</p>
                    <p className="spa-description">{prizeItem.text}</p>
                </div>
            </a>
        </div>
    )
}

export default PrizeItem
