import React from "react";
import { useHistory } from "react-router-dom";
import { previousPathsArray } from "../constants";
import "./buttonBack.scss";

/**
 * @description Botón que una vez pulsado nos devuelve a la url anterior
 */

const ButtonBack = ({ goBack, useCustomUrl }) => {
	const history = useHistory();
	return (
		<div className="top-content">
			<button
				onClick={() => (useCustomUrl ? history.push(goBack()) : history.goBack())}
				className="back"
			>
				Volver
			</button>
		</div>
	);
};

export default ButtonBack;
