import React, { useState, useEffect, Suspense } from "react";
import PrizeListItem from "./PrizeListItem/PrizeListItem";
import * as api from "../../../../services/plan-amigo-service";
import "./prizes.scss";
import ModalBox from "../../../../components/ModalBox/ModalBox";
import Loading from "../../../../components/Loading/Loading";
import ErrorHandler from "../../../../components/ErrorHandler/ErrorHandler";
import ButtonMain from "../../../../components/ButtonMain/ButtonMain";
import { usePlanAmigo } from "../../../../plan-amigo-provider";
import moment from "moment";
import prizesIcon from "../../../../assets/images/icons/gift-icon-g.svg";
import { useHistory } from "react-router-dom";
import friendIcon1 from "../../../../assets/images/icons/1-friend-icon.svg";
import friendIcon2 from "../../../../assets/images/icons/2-friend-icon.svg";
import friendIcon3 from "../../../../assets/images/icons/3-friend-icon.svg";
import friendIcon4 from "../../../../assets/images/icons/4-friend-icon.svg";
import friendIcon5 from "../../../../assets/images/icons/5-friend-icon.svg";

const Prizes = ({ setCurrentLocation }) => {
	//////////////////////////
	/// Request necesarias
	//////////////////////////

	// GET currentUser => que podría ser traído desde Redux

	const [errorMessage, setErrorMessage] = useState(null);
	const [errorStatus, setErrorStatus] = useState(null);
	const [modalErrorOpen, setModalErrorOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [prizesData, setPrizesData] = useState([]);
	const { planAmigoState } = usePlanAmigo();
	const history = useHistory();

	/**
	 * Setea la localización a la url correcta
	 */

	useEffect(() => {
		setCurrentLocation(history.location.pathname);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history.location.pathname]);

	/**
	 * Llama a la función getDataAsync para setear prizesData cuando se carga el componente
	 */

	useEffect(() => {
		getPrizesData();
		return () => {};
	}, []);

	/**
	 * Setea la variable prizesData a la respuesta del servidor
	 */

	const imagesArr = [
		{
			imgElem: friendIcon1,
			altText: "Necesitas 1 amigo para poder canjear el premio",
		},
		{
			imgElem: friendIcon2,
			altText: "Necesitas 2 amigos para poder canjear el premio",
		},
		{
			imgElem: friendIcon3,
			altText: "Necesitas 3 amigos para poder canjear el premio",
		},
		{
			imgElem: friendIcon4,
			altText: "Necesitas 4 amigos para poder canjear el premio",
		},
		{
			imgElem: friendIcon5,
			altText: "Necesitas 5 amigos para poder canjear el premio",
		},
	];

	const buttonTexts = [
		"Canjea tu tarjeta",
		"Elige tu spa",
		"Hoy no pagas tu",
		"Canjea tu cheque",
		"Elige tu hotel",
	];

	const getPrizesData = async () => {
		try {
			setLoading(true);
			const prizesRes = await api.getPrizes();

			if (prizesRes.status === 200) {
				setPrizesData(prizesRes.body.datos);
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			setErrorMessage("Ha ocurrido un error inesperado. Vuelve a intentarlo");
			setModalErrorOpen(true);
		}
	};

	const renderErrorModal = () => {
		const LazyModal = React.lazy(() => import("../../../../components/ModalBox/ModalBox"));

		return (
			<Suspense
				fallback={
					<ModalBox>
						<Loading />
					</ModalBox>
				}
			>
				<LazyModal onToggle={setModalErrorOpen}>
					<ErrorHandler errorMessage={errorMessage} errorStatus={errorStatus} />
					<ButtonMain action={setModalErrorOpen} label="OK" actionParams={false} />
				</LazyModal>
			</Suspense>
		);
	};

	return (
		<React.Fragment>
			<div className="prizes-container">
				<ul>
					{" "}
					{modalErrorOpen && renderErrorModal()}
					{!loading ? (
						prizesData.map((prize, index) => {
							const prizeUrlToArr = prize.url.split("/");
							const url = `/Paciente/Plan/Premios/${prizeUrlToArr
								.splice(prizeUrlToArr.length - 1)
								.join("/")}`;
							return (
								<li key={prize.title}>
									<PrizeListItem
										title={prize.title}
										description={prize.description}
										url={url}
										points={prize.points}
										imgSource={prize.image}
										label={prize["button-label"]}
										idPremio={prize["id-premio"]}
										key={index}
										className="prize-list-item"
									/>
								</li>
							);
						})
					) : (
						<Loading />
					)}
				</ul>
			</div>
			<div className="exchanged-prizes-container">
				<div className="card-top-title">
					<img src={prizesIcon} alt="" />
					<h3>Premios canjeados</h3>
				</div>
				{planAmigoState.prizes && planAmigoState.prizes.length > 0 ? (
					<ul>
						{planAmigoState.prizes.map((prize, index) => {
							return (
								<React.Fragment key={index}>
									<li className="prize-item" key={index}>
										<span>{prize["tipo-premio"]}</span>
										<span>
											{moment(Number(prize["fecha-canjeo"])).locale("es").format("DD/MM/YYYY")}
										</span>
									</li>
									<hr />
								</React.Fragment>
							);
						})}
					</ul>
				) : (
					<p>Aún no has canjeado ningún premio</p>
				)}
			</div>
		</React.Fragment>
	);
};

export default Prizes;
