import React, { useState, Suspense } from "react";
import { useAuth } from "../../../../provider";
import * as api from "../../../../services/services";
import { Link, useHistory } from "react-router-dom";
import Loading from "../../../../components/Loading/Loading";
import ButtonMain from "../../../../components/ButtonMain/ButtonMain";
import iconLegal from "../../../../assets/images/icons/legal-g.svg";
import iconTrash from "../../../../assets/images/icons/trash-g.svg";
import "./settings.scss";
import ChangePassword from "./ChangePassword/ChangePassword";
// import Notifications from "./Notifications/Notifications";
import ButtonBack from "../../../../components/ButtonBack/ButtonBack";
import ModalBox from "../../../../components/ModalBox/ModalBox";
import iconLock from "../../../../assets/images/icons/lock-g.svg";
import ErrorHandler from "../../../../components/ErrorHandler/ErrorHandler";

/**
 * @description Componente en el que se editan las preferencias de usuario.
 *
 */

const Settings = () => {
	/////////////////////////////
	/////////////////////////////
	// Configuración del componente

	const { state, setGlobalError, handleLogout } = useAuth();
	const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	// const [errorModalToggle, setErrorModalToggle] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [deleteError, setDeleteError] = useState(null);

	const [passwordModalToggle, setPasswordToggleModal] = useState(false);
	// const [notificationsModalToggle, setNotificationsToggleModal] = useState(false);
	const [deleteModalToggle, setDeleteToggleModal] = useState(false);

	const [passwordHasChanged, setPasswordHasChanged] = useState(false);

	// const [notificationsValues, setNotificationsValues] = useState(false);
	// const [notificationsHasChanged, setnotificationsHasChanged] = useState(false);


	/**
	 * @description Lista de opciones disponibles en el menu de Ajustes
	 */

	const options = [
		{
			title: "Cambiar contraseña",
			icon: iconLock,
			onClick: () => {
				setPasswordHasChanged(false);
				setPasswordToggleModal(true);
			},
		},
		// {
		//   title: "Notificaciones",
		//   icon: iconAlerts,
		//   onClick: () => {
		//     setnotificationsHasChanged(false);
		//     setNotificationsToggleModal(true);
		//   },
		// },
		{
			title: "Eliminar cuenta",
			icon: iconTrash,
			onClick: () => setDeleteToggleModal(true),
		},
		{
			title: "Información legal",
			icon: iconLegal,
		},
	];

	/////////////////////////////
	// Gestión de eventos y servicios
	/////////////////////////////

	/**
	 * @see {renderNotificationsModal}
	 * @param {object} values Datos sobre los cambios en las preferencias del cliente. Actualmente inactivo
	 */

	// const onSaveNotifications = async (values) => {
	//   try {
	//     var parameters = {
	//       idClinica: state.user.idClinica,
	//       idPaciente: state.user.idPaciente,
	//       // Telefono: values.NotificacionesTelefono,
	//       Mail: values["mail-switch"],
	//     };
	//     const res = await api.setNotifications(parameters);
	//     setnotificationsHasChanged(res ? true : false);
	//     // setData(values);
	//   } catch (error) {
	//     setErrorMessage(error.message);
	//     setErrorStatus(error.status);
	//   }
	// };

	const handleDelete = async () => {
		setErrorMessage(null);
		try {
			await api.deleteUser(state.user);
		} catch (error) {
			setDeleteError(error.message);
		}
	};

	/**
	 *
	 * @param {any} values
	 * @param {string} values.password   *
	 * @description Gestiona el cambio de contraseña.
	 * Primero comprueba mediante
	 * un login que la contraseña actual es correcta.
	 * Después llama al servicio correspondiente
	 *
	 */

	const onSubmitChangePassword = async (values) => {

		setLoading(true);
		var parameters = {
			idClinica: state.user.idClinica,
			idPaciente: state.user.idPaciente,
			password: values.password,
		};
		const loginData = {
			User: state.userData.dni,
			Password: values.currentPassword,
		};

		try {
			const loginReq = await api.login(loginData);
			if (loginReq.user.idPaciente) {
				const response = await api.resetPassword(parameters);
				setLoading(response ? false : true);
				setPasswordHasChanged(response ? true : false);
			}
		} catch (error) {
			console.log(error)
			if (Number(error.message) >= 400) {
				setGlobalError(error.message);
			} else {
				setErrorMessage(error.message);
			}

			// setPasswordError(error.message);
		}
	};

	/**
	 * @description Función actualmente inactiva. Hace una llamada a la api que recupera la configuración de las notificaciones
	 */
	// const getUserNotificationsSettings = async () => {
	//   try {
	//     const res = await api.getNotifications(state.user);
	//     setNotificationsValues(res);
	//   } catch (error) {
	//     // setErrorMessage(error.message);
	//     // setErrorStatus(error.status);
	//   }
	// };

	/////////////////////////////
	// Funciones de renderizado
	/////////////////////////////

	/**
	 * @description Renderizado del modal de cambio en notificaciones. Actualmente inactivo
	 */
	// const renderNotificationsModal = () => {
	//   const LazyModal = React.lazy(() => import("../../../../components/ModalBox/ModalBox"));

	//   return (
	//     <Suspense
	//       fallback={
	//         <ModalBox>
	//           <Loading />
	//         </ModalBox>
	//       }
	//     >
	//       {notificationsHasChanged ? (
	//         <LazyModal onToggle={setNotificationsToggleModal}>Tu configuración de notificaciones ha cambiado</LazyModal>
	//       ) : (
	//         <LazyModal onToggle={setNotificationsToggleModal}>
	//           <Notifications
	//             onSubmitAction={onSaveNotifications}
	//             data={notificationsValues}
	//             error={error}
	//             errorMessage={errorMessage}
	//             errorStatus={errorStatus}
	//           />
	//         </LazyModal>
	//       )}
	//     </Suspense>
	//   );
	// };

	const deleteAccount = async () => {
		await handleDelete();
		await handleLogout();
		setModalDeleteOpen(false);
	};

	const renderErrorDelete = () => <ErrorHandler errorMessage={deleteError} />;

	/**
	 * @description Renderizado del modal de borrado de cuenta. Al ser un componente menos complejo que sus hermanos, no es necesario crear un nuevo archivo
	 */
	const renderDeleteAccountModal = () => {
		const closeModal = () => setDeleteToggleModal(false);
		const LazyModal = React.lazy(() => import("../../../../components/ModalBox/ModalBox"));
		return (
			<Suspense fallback={<Loading />}>
				<LazyModal onToggle={setDeleteToggleModal}>
					{deleteError ? renderErrorDelete() : null}
					<h3>Eliminar cuenta</h3>
					<div className="delete-account-details">
						<p>
							Si eliminas tu cuenta ya no tendrás acceso a tu Área Paciente. No podrás consultar ni ver
							tus informes, citas, justificantes, presupuestos, facturas… y no volverás a recibir
							notificaciones.
						</p>
						<p>
							<strong>¿Estas seguro de que deseas eliminar tu cuenta definitivamente?</strong>
						</p>
					</div>
					<div className="delete-actions">
						<ButtonMain action={closeModal} label="No, volver" iconClass="arrow-right-white" />
						<ButtonMain label="Sí, continuar" customClass="secondary" action={deleteAccount} />
					</div>
				</LazyModal>
			</Suspense>
		);
	};

	/**
	 * @description Renderizado del modal de cambio de password
	 */

	const renderChangePasswordModal = () => {
		const LazyModal = React.lazy(() => import("../../../../components/ModalBox/ModalBox"));

		return (
			<Suspense
				fallback={
					<ModalBox onToggle={setPasswordToggleModal}>
						<Loading />
					</ModalBox>
				}
			>
				{passwordHasChanged ? (
					<LazyModal onToggle={setPasswordToggleModal}>
						<div className="success-text">Contraseña cambiada.</div>
					</LazyModal>
				) : (
					<LazyModal onToggle={setPasswordToggleModal}>
						<ChangePassword
							setPasswordHasChanged={setPasswordHasChanged}
							passwordHasChanged={passwordHasChanged}
							onSubmitChangePassword={onSubmitChangePassword}
							loading={loading}
							errorMessage={errorMessage}
							setErrorMessage={setErrorMessage}
						/>
					</LazyModal>
				)}
			</Suspense>
		);
	};

	/**
	 * @description Renderizado de errores si estos se produjeran
	 */

	/**
	 * @description Renderizado de los componentes anteriores. Si no se activan los modales correspondientes no se renderizarán en el DOM.
	 */

	return (
		<React.Fragment>
			<ButtonBack />
			<ul className="settings-options-list">
				{options.map((option, index) => {
					if (option.title === "Información legal") {
						return (
							<li key={index} className="option">
								<img className="option-icon" src={option.icon} alt="" />
								<Link to="/Paciente/Ajustes/informacionlegal" target="_blank">
									{option.title}
								</Link>
							</li>
						);
					} else {
						return (
							<li key={index} className="option">
								<img className="option-icon" src={option.icon} alt="" />
								<button onClick={() => option.onClick()}>{option.title}</button>
							</li>
						);
					}
				})}
			</ul>

			{passwordModalToggle ? renderChangePasswordModal() : null}
			{/* {notificationsModalToggle ? renderNotificationsModal() : null} */}
			{deleteModalToggle ? renderDeleteAccountModal() : null}
		</React.Fragment>
	);
};

export default Settings;
