import React from "react";
import "./successHandler.scss";
const SuccessHandler = ({ successMessage }) => {
	return (
		<React.Fragment>
			{successMessage && <p className="success-text">{successMessage}</p>} 
		</React.Fragment>
	);
};

export default SuccessHandler;
