import React, { useEffect, useState } from 'react'
import withoutrecipes from '../../../../assets/images/withoutrecipes.png'
import ErrorDialog from '../../../../components/ErrorDialog/ErrorDialog'
import Loading from '../../../../components/Loading/Loading'
import ReportContent from '../../../../components/ReportContent/ReportContent'
import { useAuth } from '../../../../provider'
import { MedicationServices } from '../../../../services/medications'
import { ProtocolsServices } from '../../../../services/protocols'
import './Protocols.scss'
import ModalBox from '../../../../components/ModalBox/ModalBox'
import PDFViewer from '../../../../components/PDFViewer/PdfViewer'


/**
 *
 * @param {String} error
 */

const Protocols = ({ error, props }) => {
  const { state } = useAuth()
  const { idClinica, idPaciente } = state.user
  const [recipes, setRecipes] = useState(null)
  const [base64String, setBase64String] = useState(null)

  useEffect(() => {
    setRecipes(null);
    let isFetching = true
    const getProtocols = async () => {
      const result = await ProtocolsServices.getUserProtocols()
      return result
    }
    getProtocols().then(res => {
      if (isFetching) {
        setRecipes(res || [])
      }
    })
    return () => (isFetching = false)
  }, [state.userToken])

  const handleGetPDF = async (protocol) => {
    window.open(protocol.url, '_blank');
  }

  const renderElements = () => {
    return (
      <React.Fragment>
        {base64String &&
          <div style={{ width: '100%', height: '80vh' }}>
            <PDFViewer onClose={() => setBase64String(null)} base64String={base64String.base64str} fileName={base64String.fileName} />
          </div>
        }
        {recipes.map((item, index) => {
          return (
            <ReportContent
              key={index}
              title={item.nombreIndicacion}
              icon='report'
              buttonLabel='Ver protocolo'
              buttonAction={() => handleGetPDF(item)}
              protocolo={true}
              indication={item.indication}
            ></ReportContent>
          )
        })}
      </React.Fragment>
    )
  }

  return !recipes ? (
    <Loading></Loading>
  ) : recipes.length > 0 ? (
    renderElements()
  ) : (
    <div className='empty-container'>
      {error ? <ErrorDialog text={error} /> : null}
      <img src={withoutrecipes} alt='' />
      <p className='no-content-info'>En este momento no tienes protocolos disponibles.</p>
    </div>
  )
}

export default Protocols
