/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./App.scss";
import Snackbar from "./components/Snackbar/Snackbar";
import Router from "./router/Router";
import { useAuth } from "./provider";
import { useHistory, useLocation } from "react-router-dom";
import { useCustomUseLocation } from "./lastLocationProvider";
import * as planAmigoApi from "./services/plan-amigo-service";
import { usePlanAmigo } from "./plan-amigo-provider";
import moment from "moment";
//import { pushToTagManager } from "./services/google-tag-manager";
import * as api from "./services/services";
import * as pdfjs  from 'pdfjs-dist/es5/build/pdf';
pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.min.js';

const App = () => {
  const history = useHistory();
  const { state, getAuthState, setGlobalLoading } = useAuth();
  const { setUserHasPlan, setFriendsAndPrizes } = usePlanAmigo();
  const { setLastLocation } = useCustomUseLocation();
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    getAuthState();
    if (process.env.NODE_ENV !== "development")
      window.console.log = () => false;
  }, []);

  const location = useLocation();

  useEffect(() => {
    setError(state.globalError);
  }, [state.globalError]);

  const excludedPaths = ["/intro/login", "/intro/cambiarpassword"];

  useEffect(() => {
    if (!excludedPaths.includes(location.pathname) && !state.isLoggedIn) {
      setLastLocation(location.pathname);
    }
  }, [location]);

  //JOSE PLAN//
  useEffect(async () => {
    setUserHasPlan(false);
    setGlobalLoading(true);
    if (state.isLoggedIn) {
      const userDataResponse = await api.getUserData(state.user);
      setUserData(userDataResponse);
      if(userDataResponse.planAmigo){
        setTimeout(() => {
          checkIfUserHasPlanAmigo();
        }, 1000);
      }else{
        setGlobalLoading(false);
      }
    }
  }, [state.isLoggedIn]);
  //JOSE PLAN//
  /**
   * Cada vez que hay una redirección se envía esta información
   * a Google Tag Manager
   */

  useEffect(() => {
    
    if (state.isLoggedIn) {
      return history.listen((location) => {
        pushDataToTagManager(location, false);
      });
    } else {
      return history.listen((location) => {
        pushDataToTagManager(location, true);
      });
    }
  }, [history, state.isLoggedIn]);

  /**
   * Get user data
   */

  const pushDataToTagManager = async (location, isUndefined) => {
    let userData;

    if (!isUndefined) {
      if (!userData) {
        const userDataResponse = await api.getUserData(state.user);
        userData = userDataResponse;
      } else {
        userData = state.userData;
      }
    }

    const page = {
      url: `http://areapaciente.clinicabaviera${location.pathname}`,
      title: "Area Paciente",
      type: "area-paciente",
    };
    const user = {
      id: isUndefined ? undefined : state.user.idPaciente,
      name: isUndefined
        ? undefined
        : `${userData.nombre} ${userData.primerApellido} ${userData.segundoApellido}`,
      id_clinica: isUndefined ? undefined : state.user.idClinica,
      birthday: isUndefined
        ? undefined
        : moment(userData.fechaNacimiento).format("yyyy/MM/DD"),
    };
    const props = {
      page,
      user,
    };

    //pushToTagManager("page_view", props);
  };

  /**
   * Use Effect que controla el tag manager del login
   */

  

  /**
   * Comprueba si el usuario tiene el plan amigo activado.
   * Si es así lo notifica al estado para que luego se pueda renderizar el
   * contenido del plan amigo.
   */

  const checkIfUserHasPlanAmigo = async () => {
    const testDataRegister = null /* {
      hasPlan: false,
      userData: {
        dni: "73156352E",
        mail: "danielespesingles@gmail.com",
        nombre: "DANIEL ESPES INGLES",
      },
      code: 3,
      message: "Esto es una prueba",
    }; */
    try {
      const res = await planAmigoApi.requestPointsAndPrizesByDNI();
      const { puntos, premios,status ,url_pedir_cita_amigos , status_code} = res;
      
      if (status_code === 3) {
        setUserHasPlan({ hasPlan: true });
        setFriendsAndPrizes(
          puntos.disponibles,
          [],
          premios,
          true,
          url_pedir_cita_amigos
        );
      } else {
        let data;
        if (!testDataRegister)
          data = {
            hasPlan: false,
            code: status_code,
            message: status,
          };
         
        setUserHasPlan(testDataRegister || data);
      }
    } catch (error) {
      console.error(error);
      if (error.message === "403" || error.message === "404") {
        setUserHasPlan(false);
      }
    }
    setGlobalLoading(false);
  };

  return (
    <div>
      <Router />
      {state.globalError ? <Snackbar text={error} timeout={4000} /> : null}
    </div>
  );
};

export default App;
