import React from 'react';
import './menuItem.scss';
import { Link } from 'react-router-dom';

const MenuItem = (props) => {
  const { menuItem } = props

  
  return (
    <Link to={menuItem.link} className="menu-link ">
      <div className={`link-icon ${menuItem.cssClass}`}>
        {/*Aquí viene el icono como background*/}
      </div>
      <span>{menuItem.label}</span>
    </Link>
  )
}

export default MenuItem;
