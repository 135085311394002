import React, { useEffect, useState } from "react";
import PrizesListPage from "../../../../../components/PrizesListPage/PrizesListPage";
import * as api from "../../../../../services/plan-amigo-service";
import "./spa.scss";
import ExchangePrizesCard from "../ExchangePrizeCard/ExchangePrizesCard";
import ButtonMain from "../../../../../components/ButtonMain/ButtonMain";
import MessageModal from "../../../../../components/MessageModal/MessageModal";
import { useAuth } from "../../../../../provider";
import InfinityScroll from "../../../../../components/InfinityScroll/InfinityScroll";
import { usePlanAmigo } from "../../../../../plan-amigo-provider";
import { useHistory } from "react-router-dom";
import spaCover from "../../../../../assets/images/spa.jpg";
import spaIcon from "../../../../../assets/images/icons/spa-icon.svg";
import useWindowSize from "../../../../../hooks/useWindowSize";
import Loading from "../../../../../components/Loading/Loading";
import ModalBox from "../../../../../components/ModalBox/ModalBox";

const Spa = ({ setCurrentLocation }) => {
	const history = useHistory();

	/**
	 * Setea la localización a la url correcta
	 */

	useEffect(() => {
		setCurrentLocation(history.location.pathname);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history.location.pathname]);
	//////////////////////////
	/// Configuración del componente
	//////////////////////////

	const [provincesList, setProvincesList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [successMessage, setSuccessMessage] = useState(null);
	const [exchangeModalOpen, setExchangeModalOpen] = useState(false);
	const { state, setIsNotNearBottom } = useAuth();
	const [localSpalist, setLocalSpaList] = useState([]);
	const [numberOfSpas, setNumberOfSpas] = useState(0);
	const [isCitySelected, setIsCitySelected] = useState(false);
	const { width } = useWindowSize();

	const { planAmigoState, setSpaList, setFriendsAndPrizes } = usePlanAmigo();

	const { isNearBottom } = state;

	/**
	 * Se setea la lista de provincias para el select y la lista de spas
	 * @see getDataAsync => lista de spas
	 * @see filterProvinces => filtro de provincias
	 */

	useEffect(() => {
		setLoading(true);
		getDataAsync();

		// Primera llamada a getNextSpas para evitar que no se pinten algunas veces
		if ((planAmigoState.spaList.length > 0) & (numberOfSpas <= 10)) {
			getNextSpas(planAmigoState.spaList, numberOfSpas);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Se setea la lista de spas que se mostrarán en pantalla
	 */

	useEffect(() => {
		if (planAmigoState.spaList.length > 0 && !isNearBottom) {
			getNextSpas(planAmigoState.spaList, numberOfSpas);
			filterProvinces(planAmigoState.spaList);
			setIsNotNearBottom();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [planAmigoState.spaList]);

	/**
	 * Si el usuario está cerca del fondo, cargar más elementos
	 */

	useEffect(() => {
		if (isNearBottom && !isCitySelected && numberOfSpas !== 0) {
			getNextSpas(planAmigoState.spaList, numberOfSpas);
			setIsNotNearBottom();
		}
		if (isNearBottom && planAmigoState.spaList.length === localSpalist.length) {
			setIsNotNearBottom();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isNearBottom]);

	/////////////////////////////
	// Gestión de eventos y servicios
	/////////////////////////////

	/**
	 * Llamada a la api que devuelve en
	 * caso de éxito una lista de spas y un status de la petición
	 * y en caso de error un error
	 */

	const getDataAsync = async () => {
		try {
			setLoading(true);
			const spas = await api.getSpaList();
			if (spas.status === 200) {
				// Se setea la lista de spas en el state global

				setSpaList(spas.data.datos.hoteles);

				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			setErrorMessage(error);
		}
	};

	const redirectToFriends = () =>
		history.push("/Paciente/Plan/Recomienda-clinica-baviera-tus-amigos");

	/**
	 *
	 ** @param {Array.<{
	 * 		id: String,
	 * 		img_format: String,
	 * 		img_original: String,
	 * 		promo: String,
	 * 		prov: String,
	 * 		title: String,
	 * 		url: String,
	 * }>} spaList
	 * Filtra las provincias para obtener una lista de ciudades a
	 * partir de las cuales se renderizará el select de selección de provincia.
	 */

	const filterProvinces = (spaList) => {
		const provinceList = spaList.reduce(
			(acc, curr) => (acc.includes(curr.prov) ? acc : [...acc, curr.prov]),
			[]
		);
		setProvincesList(provinceList.sort((a, b) => a.localeCompare(b)));
	};

	/**
	 *
	 ** @param {Array.<{
	 * 		id: String,
	 * 		img_format: String,
	 * 		img_original: String,
	 * 		promo: String,
	 * 		prov: String,
	 * 		title: String,
	 * 		url: String,
	 * }>} spas
	 * @param {Number} size
	 *
	 *
	 *
	 */

	const getNextSpas = (spas, size) => {
		const elements = spas.slice(size, size + 10);
		setNumberOfSpas(size + 10);
		setLocalSpaList([...localSpalist, ...elements]);
		setLoading(false);
		return elements;
	};

	/**
	 *
	 * @param {String} province
	 * Filtra los spas para obtener aquellos cuya provincia coincide con el parámetro province
	 * Si se selecciona todas las provincias (value = "all") devuelve un listado de todas las provincias.
	 * Se setea el número de spas a 0 para que se vuelvan a cargar los spas de 0
	 */

	const filterSpas = (province) => {
		setNumberOfSpas(0);
		setIsCitySelected(true);
		if (province === "all") {
			setIsCitySelected(false);
			setIsNotNearBottom();
			getNextSpas(planAmigoState.spaList, numberOfSpas);
			return;
		}
		const filteredSpas = planAmigoState.spaList.filter((spa) => spa.prov === province);
		setLocalSpaList(filteredSpas);
	};

	const spaConfig = {
		title: "Spa",
		subtitle: "Estos son los centros Spa disponibles",
		prizeDescription: `¿Preparado para relajarte? Disfruta de una jornada de relax para ti y la persona que elijas con un circuito spa o masaje. Selecciona tu spa más cercano.`,
		prizesList: localSpalist,
		handleSelectChange: filterSpas,
		friendsTarget: 2,
	};

	//////////////////////////
	/// Gestión de eventos
	//////////////////////////

	/**
	 * Activa/desactiva el modal de canjeo de premio
	 */

	const toggleExchangeModal = () => setExchangeModalOpen(!exchangeModalOpen);

	/**
	 * Gestión de la llamada a la api que sirve para canjear el premio
	 */

	const exchangeSpa = async () => {
		try {
			//Abre modal de carga
			setLoading(true);
			const data = {
				"tipo-premio": 14,
			};

			//Cierra modal de intercambio

			setExchangeModalOpen(false);

			const res = await api.requestExchangePrize(data);

			if (Number(res.status) === 200 || res.status === "ok") {
				const res = await api.requestPointsAndPrizesByDNI();
				const { puntos, premios, status, url_pedir_cita_amigos, status_code } =
					res;

				if (status_code === 3) {
					setFriendsAndPrizes(
						puntos.disponibles,
						[],
						premios,
						true,
						url_pedir_cita_amigos
					);
				}
				setLoading(false);
				setSuccessMessage("Has realizado tu solicitud con éxito");
			}

			if (res.status === 400 || res.body.status === "error") {
				setLoading(false);
				setExchangeModalOpen(false);
				setErrorMessage("Ha ocurrido un error con tu petición");
			}
		} catch (error) {
			setExchangeModalOpen(false);
			setErrorMessage(error);
			setLoading(false);
		}
	};

	return (
		<React.Fragment>
			{loading && (
				<ModalBox>
					<Loading />
				</ModalBox>
			)}
			{exchangeModalOpen && (
				<MessageModal modalToggle={toggleExchangeModal} isError={false}>
					<div className="modal-icon">
						<img src={spaIcon} alt="" />
					</div>
					<h4>Canjea tu spa</h4>
					<p className="modal-exchange-description">
						¿Estás seguro de que quieres canjear tu premio o prefieres esperar a acumular más
						amigos?
					</p>
					<div className="flex-container">
						<ButtonMain customClass="tertiary" label="Voy a esperar" action={toggleExchangeModal} />
						<ButtonMain label="Canjear ya" action={exchangeSpa} />
					</div>
				</MessageModal>
			)}
			{successMessage && (
				<MessageModal
					modalMessage="Tu premio ha sido canjeado correctamente. Revisa tu email donde encontrarás toda la información."
					setModalMessage={setSuccessMessage}
					isError={false}
				></MessageModal>
			)}
			{errorMessage && (
				<MessageModal
					modalMessage={errorMessage}
					setModalMessage={setErrorMessage}
					isError={true}
				></MessageModal>
			)}

			{!loading && (
				<React.Fragment>
					<ExchangePrizesCard
						prizeText={spaConfig.title}
						prizeDescription={spaConfig.prizeDescription}
						openExchangeModal={toggleExchangeModal}
						imgSource={width <= 980 ? spaIcon : spaCover}
						requiredFriends = {spaConfig.friendsTarget}

						hasEnoughFriends={spaConfig.friendsTarget <= planAmigoState.numberOfFriends}
						redirectToFriends={redirectToFriends}
					/>
					<InfinityScroll>
						<PrizesListPage {...spaConfig} provincesList={provincesList} />
					</InfinityScroll>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

export default Spa;
