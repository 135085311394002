import axios from 'axios'
import * as c from '../constants'
import { base64ToBlob, downloadFile } from '../helpers/helpers'
import { getHeadersAsync } from './services'

export const ProtocolsServices = {
  /**
   * Devuelve las recetas que tiene el paciente en la clínica.
   * @param {{idClinica:String, idPaciente:String}} param;
   * @returns
   */
  getUserProtocols: async () => {
    let options = await getHeadersAsync()
    let url = `${c.PROTOCOLS_GET}`
    let res = await axios.get(url, options)
    
    return res.data
  },
  /**
   * Devuelve el array de bytes del PDF de la receta.
   * @param {{idClinica:String, idPaciente:String, idReceta: String, target: 'open' | 'download'}} param;
   * @returns
   */
  getRecetaPdf : async ({ idClinica, idPaciente, recipe, target= 'open'})=>{
    let options = await getHeadersAsync()
    let url = `${c.RECETAS_GET_PDF}?idReceta=${recipe.idReceta}`
    let res = await axios.get(url, options)
    if(res.data && target === 'open') return {base64str: res.data, fileName: recipe.medicamento}; //base64ToBlob(res.data);
    if(res.data && target === 'download') downloadFile(res.data, recipe.medicamento, 'pdf');
  }
}
