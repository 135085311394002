import React from "react";
import LinkButton from "../../../../../components/LinkButton/LinkButton";
import "./prizeListItem.scss";

const PrizeListItem = ({ title, description, url, label, imgSource, idPremio }) => {
	const prizeButtonLabel = label || "Canjea tu premio";

	function createMarkup() {
		return {__html: description};
	  }

	return (
		<div className="list-item-container">
				<div className="list-item-icon">
					<img src={imgSource} alt=""/>
				</div>
				<div className="list-item-title">
					<h3>{title}</h3>
				</div>
				<div className="list-item-info">
					<p dangerouslySetInnerHTML={createMarkup()}></p>
					<LinkButton url={'/Paciente/Plan/Premios/prize-detail'+`?prizeId=${idPremio}`} urlLabel={prizeButtonLabel} />
				</div>
		</div>
	);
};

export default PrizeListItem;
