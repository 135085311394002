import React from 'react'
import Card from '../../../../../components/Card/Card'
import logo from '../../../../../assets/images/lg-baviera-color.svg'
import './legalInfo.scss'

/**
 * @description Componente de información legal
 */

export default function LegalInfo () {
  return (
    <div className='legal-info'>
      <div className='logo-container'>
        <a href='/' className='logo'>
          <img alt='Clínica Baviera' src={logo} />
        </a>
      </div>
      <Card>
        <h1>Informaci&oacute;n general </h1>
        <p>
          Para dar cumplimiento con lo establecido en la Ley 34/2002, de 11 de
          julio, de servicios de la sociedad de la informaci&oacute;n y de
          comercio electr&oacute;nico, a continuaci&oacute;n se indican los
          datos de informaci&oacute;n general de este sitio Web:.
        </p>
        <p>Titular: CLINICA BAVIERA S.A</p>
        <p>
          Direcci&oacute;n: Paseo de la Castellana n&ordm; 20, 28046 - Madrid
        </p>
        <p>Contacto: 917819880</p>
        <p>info@clinicabaviera.com</p>
        <p>
          Datos Registrales: Registro Mercantil de Madrid, Hoja n&ordm; M-
          40728, inscripci&oacute;n 16, folio 190 , Tomo 12.405 de la
          Secci&oacute;n 8 del Libro de Sociedades.
        </p>
        <p>CIF: A-80240427</p>
        <p>
          Inscrita con el n&uacute;mero CS 2046 de registro sanitario concedido
          por la Consejer&iacute;a de Sanidad de la Comunidad de Madrid para
          ejercer como centro sanitario.
        </p>
        <p>
          El presente sitio Web ha sido dise&ntilde;ado para con la finalidad de
          crear un canal de comunicaci&oacute;n con los pacientes de
          Cl&iacute;nica Baviera para que puedan acceder a determinada
          informaci&oacute;n y gestionar sus citas.
        </p>
        <p>
          Mediante el Portal del Paciente se podr&aacute;n realizar gestiones
          relacionadas con:
        </p>
        <p>Citas</p>
        <p>Informes / Recetas / Justificantes</p>
        <p>Facturas y presupuestos</p>
        <p>Contactar con la cl&iacute;nica</p>
        <p>Plan amigo</p>
        <p>
          El hecho de acceder al Portal del Paciente implica el conocimiento y
          aceptaci&oacute;n de los siguientes t&eacute;rminos y condiciones:
        </p>
        <p>
          El acceso al Portal del Paciente es responsabilidad exclusiva de los
          usuarios.
        </p>
        <p>
          El acceso al Portal del Paciente no supone entablar ning&uacute;n tipo
          de relaci&oacute;n de car&aacute;cter comercial entre Cl&iacute;nica
          Baviera y el usuario.
        </p>
        <p>
          El acceso y navegaci&oacute;n por el Portal del Paciente supone
          aceptar y conocer las advertencias legales, condiciones y
          t&eacute;rminos de uso contenidas en &eacute;l.
        </p>
        <p>
          El acceso al Portal del Paciente atribuye a quien lo realice la
          condici&oacute;n de "usuario". Por el mero hecho de utilizar esta
          p&aacute;gina Web, el usuario acepta plenamente los t&eacute;rminos y
          condiciones aqu&iacute; establecidos y se compromete a hacerlo de
          conformidad con la ley y respetando las dem&aacute;s instrucciones de
          uso.
        </p>
        <p>
          El usuario declara bajo su responsabilidad ser mayor de edad y tener
          capacidad legal para operar en la p&aacute;gina Web. Se compromete a
          ser el responsable de todo cuanto realice en este lugar. Declara
          igualmente que toda la informaci&oacute;n que facilita es verdadera y
          exacta, sin limitaciones, reservas o falsedades y que no pertenece a
          terceras personas. De igual manera, el usuario ser&aacute; responsable
          de mantener de forma actualizada toda aquella informaci&oacute;n que
          haya facilitado a Cl&iacute;nica Baviera, de forma que &eacute;sta
          responda, en cada momento, a la situaci&oacute;n real del usuario y de
          los da&ntilde;os y perjuicios que se puedan ocasionar a Cl&iacute;nica
          Baviera o a terceros por los datos aportados, como consecuencia de la
          utilizaci&oacute;n de datos no actualizados, falsos o que no se
          corresponden con la realidad y por la utilizaci&oacute;n por parte de
          terceros de las contrase&ntilde;as personales del usuario.
        </p>
        <h1>Condiciones de acceso</h1>
        <p>
          Para acceder al Portal del Paciente es requisito imprescindible que el
          usuario se registre como tal en la mencionada p&aacute;gina web y
          aceptar las presentes condiciones de uso.
        </p>
        <p>
          Si el paciente desea acceder al Portal del Paciente la Cl&iacute;nica
          le enviar&aacute; al n&uacute;mero de tel&eacute;fono m&oacute;vil que
          nos ha aportado las claves de acceso, la cual una vez introducida
          deber&aacute; ser cambiada por otra de elecci&oacute;n del usuario.
          Esta misma contrase&ntilde;a le permitir&aacute; el acceso a su
          historial cl&iacute;nico, as&iacute; como al resto de servicios
          ofrecidos desde el Portal del Paciente.
        </p>
        <p>
          Puede registrarse cualquier persona f&iacute;sica, mayor de 16
          a&ntilde;os, paciente de Cl&iacute;nica Baviera y que suscriba las
          presentes Condiciones. En el caso de menores de 16 a&ntilde;os, los
          padres o tutores legales podr&aacute;n, previa acreditaci&oacute;n de
          su condici&oacute;n de representantes, solicitar la contrase&ntilde;a
          de acceso correspondiente al Portal del Paciente del menor.
        </p>
        <p>
          Los nombres de usuario y contrase&ntilde;as facilitados
          permitir&aacute;n identificar al usuario de forma personalizada e
          individualizada. Dichos nombres de usuario y contrase&ntilde;as tienen
          el car&aacute;cter de estrictamente personal, confidencial e
          intransferible. El usuario ser&aacute; responsable de mantener la
          confidencialidad de las mismas, no pudiendo divulgarlas a un tercero o
          cederlas. En consecuencia, el Usuario acepta expresamente que
          Cl&iacute;nica Baviera presuma, salvo comunicaci&oacute;n previa de
          extrav&iacute;o o sustracci&oacute;n de las mismas, que los usos del
          Portal del Paciente realizados utilizando la contrase&ntilde;a de
          identificaci&oacute;n, son realizados por quien figura como usuario
          registrado
        </p>
        <p>
          Cl&iacute;nica Baviera siempre que lo considere oportuno, podr&aacute;
          modificar unilateralmente y sin previo aviso la estructura y
          dise&ntilde;o del Portal del Paciente, as&iacute; como modificar o
          eliminar, sus servicios, contenidos y condiciones de acceso y/o
          utilizaci&oacute;n. Asimismo, Cl&iacute;nica Baviera podr&aacute;
          anular el acceso a los contenidos, total o parcialmente, a aquellos
          usuarios que incumplan las presentes condiciones.
        </p>
        <h1>Uso del servicio</h1>
        <p>
          El usuario se compromete en todo caso a utilizar el Portal del
          Paciente de modo diligente, correcto, l&iacute;cito de conformidad con
          la ley, el Aviso Legal, las presentes Condiciones, as&iacute; como con
          la moral y las buenas costumbres generalmente aceptadas y el orden
          p&uacute;blico.
        </p>
        <p>
          El acceso al Portal del Paciente, su uso y la utilizaci&oacute;n
          correcta de la informaci&oacute;n contenida en el mismo son
          responsabilidad del quien realiza estas acciones, no siendo
          responsable Cl&iacute;nica Baviera por el uso incorrecto,
          il&iacute;cito o negligente que pudiera llevar a cabo el usuario. Se
          proh&iacute;be la colocaci&oacute;n o transmisi&oacute;n de toda
          materia il&iacute;cita, calumniosa u obscena, o cualquiera otra que
          pudiera constituir o inducir a conductas que podr&iacute;an
          tipificarse como delictivas, ocasionar responsabilidad civil o
          vulnerar de otra forma la legislaci&oacute;n aplicable.
        </p>
        <p>
          Cl&iacute;nica Baviera no ser&aacute; responsable de los da&ntilde;os
          y perjuicios que pudieran derivarse de la utilizaci&oacute;n de
          versiones de navegadores no actualizadas, del mal funcionamiento del
          navegador, ya sea por configuraci&oacute;n inadecuada, presencia de
          virus inform&aacute;ticos o cualquier otra causa ajena a
          Cl&iacute;nica Baviera
        </p>
        <p>
          El usuario se compromete a hacer un uso adecuado de los contenidos del
          Portal del Paciente y a no emplearlos para incurrir en actividades
          il&iacute;citas o contrarias a la buena fe y al ordenamiento legal,
          provocar da&ntilde;os en los sistemas f&iacute;sicos y l&oacute;gicos
          de Cl&iacute;nica Baviera, de sus proveedores o de terceras personas,
          introducir o difundir en la red virus inform&aacute;ticos o
          cualesquiera otros sistemas f&iacute;sicos o l&oacute;gicos que sean
          susceptibles de provocar los da&ntilde;os anteriormente mencionados, a
          no intentar acceder, recoger o utilizar datos personales de otros
          usuarios sin su consentimiento expreso o contraviniendo lo dispuesto
          en la normativa sobre protecci&oacute;n de datos.
        </p>
        <p>
          La duraci&oacute;n de la prestaci&oacute;n del servicio mediante la
          web es de car&aacute;cter indefinido. Sin perjuicio de lo anterior,
          Cl&iacute;nica Baviera se reserva el derecho para, interrumpir,
          suspender o terminar la prestaci&oacute;n del servicio del Web o de
          cualquiera de los servicios que lo integran.
        </p>
        <p>
          Cl&iacute;nica Baviera estar&aacute; exenta de cualquier
          responsabilidad derivada del acceso y uso del Portal del Paciente
          (fallos en el rendimiento, interrupciones o demora en el
          funcionamiento de los servicios, fallo del sistema o de la
          l&iacute;nea, etc.), incluso aunque se produjera alg&uacute;n fallo o
          mal funcionamiento que causara da&ntilde;os en herramientas
          inform&aacute;ticas de terceras personas. En este sentido, el usuario
          accede al Portal del Paciente bajo su exclusiva responsabilidad.
        </p>
        <p>
          Cl&iacute;nica Baviera no ser&aacute; responsable en los supuestos de
          indisponibilidad del Portal del Paciente o sus contenidos por causas
          de fuerza mayor o suspensi&oacute;n temporal del mismo por razones
          t&eacute;cnicas. Por tanto, Cl&iacute;nica Baviera no asumir&aacute;
          responsabilidad alguna por los da&ntilde;os, p&eacute;rdidas que
          pudieran sufrir como consecuencia de sucesos que no hubieran podido
          preverse, o que previstos fueran inevitables, bien por caso fortuito o
          fuerza mayor.
        </p>
        <p>
          A pesar de que Cl&iacute;nica Baviera ha actuado de forma diligente y
          ha adoptado las medidas necesarias de acuerdo con el estado de la
          tecnolog&iacute;a, para evitar posibles brechas en la seguridad del
          Portal, no garantiza la imposibilidad absoluta de vulnerabilidad de
          las medidas de seguridad adoptadas.
        </p>
        <h1>Propiedad intelectual</h1>
        <p>
          Cl&iacute;nica Baviera es titular, de forma directa o indirecta, de
          todos los derechos de propiedad intelectual o derechos comparables en
          cuanto a estas p&aacute;ginas se refiere, tanto de los contenidos,
          como de los pos, dise&ntilde;o gr&aacute;fico y c&oacute;digos u otros
          elementos de cualquier &iacute;ndole que los integren.
        </p>
        <h1>Propiedad industrial</h1>
        <p>
          Las marcas, nombres comerciales, signos distintivos de cualquier clase
          y nombres de dominio contenidos en el Portal del Paciente de
          Cl&iacute;nica Baviera est&aacute;n registrados por esta Entidad y
          protegidos por ley.
        </p>
        <p>
          Los signos distintivos y nombres de dominio que aparecen en el Portal
          del Paciente utilizados para identificar a otros proveedores de bienes
          o servicios, pertenecen a sus respectivos propietarios.
        </p>
        <p>
          Nada de lo contenido en esta p&aacute;gina web ser&aacute;
          interpretado como renuncia o cesi&oacute;n a los derechos que
          corresponden a Cl&iacute;nica Baviera derivados de la propiedad
          intelectual o industrial o de derechos comparables sobre los bienes
          que sus respectivas normas regulan.
        </p>
        <h1>Jurisdicci&oacute;n y legislaci&oacute;n aplicable</h1>
        <p>
          El presente Portal del Paciente se regir&aacute; e interpretar&aacute;
          con arreglo a la legislaci&oacute;n espa&ntilde;ola. Cualquier
          controversia que pudiera derivarse del acceso o la utilizaci&oacute;n
          de la Web se someter&aacute; a la exclusiva jurisdicci&oacute;n de los
          Juzgados y Tribunales de Madrid, sin perjuicio de que resulte de
          aplicaci&oacute;n alguna disposici&oacute;n imperativa en contrario,
          en cuyo caso se estar&aacute; al contenido de la misma.
        </p>
        <h1>
          Bases legales y mec&aacute;nica del programa &ldquo;Plan Amigo&rdquo;
          de Cl&iacute;nica Baviera
        </h1>
        <p>
          CLINICA BAVIERA S.A, en adelante CLINICA BAVIERA, provista de CIF:
          A-80240427 y con domicilio en P&ordm; de la Castellana 20, 28046,
          Madrid, ha decidido poner en marcha el programa &ldquo;Plan
          Amigo&rsquo;&rsquo; con la finalidad de captar nuevos clientes y
          fidelizar a los mismos a trav&eacute;s del programa y la entrega de
          premios. Para ello, CLINICA BAVIERA cuenta con los servicios de
          Ruralka Hoteles con Estilo Propio S.L., sociedad de nacionalidad
          espa&ntilde;ola, con domicilio en calle Torrelaguna, 36 &ndash; 28027
          Madrid, con C.I.F.: B84145291, en adelante RURALKA.
        </p>
        <p>&Aacute;MBITO DE APLICACI&Oacute;N</p>
        <p>
          El presente programa se iniciar&aacute; el 13 de Diciembre de 2021 y
          tendr&aacute; una duraci&oacute;n de un (1) a&ntilde;o. Una vez
          finalizado el per&iacute;odo de duraci&oacute;n, el mismo podr&aacute;
          ser prorrogado si as&iacute; lo acuerda Cl&iacute;nica Baviera.
        </p>
        <p>
          El programa se realizar&aacute; en el &aacute;mbito de todo el
          territorio nacional (Pen&iacute;nsula, Baleares, Canarias, Ceuta y
          Melilla) pudiendo participar todos los clientes mayores de edad
          residentes en todo el territorio nacional (Pen&iacute;nsula, Baleares,
          Canarias, Ceuta y Melilla) que cumplan con los requisitos establecidos
          en estas bases.
        </p>
        <p>
          La participaci&oacute;n en este programa es de car&aacute;cter
          gratuito.
        </p>
        <p>1. SERVICIOS PROMOCIONADOS</p>
        <p>
          Los servicios promocionados y cuya contrataci&oacute;n dan acceso a
          participar en este programa son todas las cirug&iacute;as de
          trastornos oftalmol&oacute;gicos (en adelante los SERVICIOS) que hayan
          sido contratados por los clientes en cualquiera de los centros que
          CLINICA BAVIERA tiene en el territorio espa&ntilde;ol.
        </p>
        <p>2. MEC&Aacute;NICA PROMOCIONAL</p>
        <p>
          Los clientes podr&aacute;n participar en el programa &uacute;nicamente
          de la siguiente manera:
        </p>
        <p>
          Los clientes a los que aplicar&aacute; la promoci&oacute;n
          ser&aacute;n aquellos que accedan a los SERVICIOS por primera vez
          desde la fecha de inicio de la promoci&oacute;n.
        </p>
        <p>
          En la primera visita como paciente tras el SERVICIO (en el caso de
          nuevos clientes CLINICA BAVIERA) se les informa sobre el Plan Amigo
          (dentro de la plataforma &Aacute;rea Paciente) y c&oacute;mo pueden
          participar.
        </p>
        <p>
          Dichos clientes podr&aacute;n entrar directamente desde &Aacute;rea
          Paciente y quedar&aacute;n oficialmente dados de alta como usuarios.
        </p>
        <p>
          En este momento, podr&aacute;n acceder a una pantalla de
          gesti&oacute;n donde se encontrar&aacute;n las siguientes
          pesta&ntilde;as:
        </p>
        <p>
          Plan Amigo, con una explicaci&oacute;n detallada de c&oacute;mo puede
          participar en el programa.
        </p>
        <p>
          Premios, donde el cliente podr&aacute; ver los premios de los que
          puede disfrutar y canjearlos.
        </p>
        <p>
          Recomendar a través de un link o enlace de usuario que podrá obtener a
          través del Área de paciente y que estará asociado de manera única a
          Usted. La persona invitada por Usted será redireccionada a través de
          ese link o enlace a un formulario de solicitud de información en el
          que deberá registrarse. Será contactado por Clínica Baviera para darle
          cita en alguna de nuestras clínicas. De esta forma, Clínica Baviera
          verificará que efectivamente el link utilizado para gestionar la cita
          es el asociado con su usuario, con la finalidad de gestionar su
          participación y la de su amigo o familiar, de conformidad con las
          bases legales y mecánica de dicho programa.
        </p>
        <p>
          Si un paciente de CLINICA BAVIERA recomienda a un familiar o amigo y
          &eacute;ste consigue que dicho recomendado venga a una primera
          consulta en CLINICA BAVIERA, el paciente recomendador obtendr&aacute;
          un REGALO DIRECTO. Para obtener el resto de premios deber&aacute;
          animar a sus amigos a contratar los SERVICIOS de CLINICA BAVIERA.
          Cuantos m&aacute;s amigos recomendados por el cliente contraten el
          SERVICIO, m&aacute;s amigos tendr&aacute; en su cartera de amigos y
          podr&aacute; canjear los diferentes premios.
        </p>
        <p>3. PREMIOS, VALOR Y REDENCI&Oacute;N DE LOS MISMOS</p>
        <p>
          El programa incluye los siguientes premios que se obtendr&aacute;n en
          funci&oacute;n del n&uacute;mero de amigos conseguidos:
        </p>
        <p>
          <strong>
            REGALO DIRECTO: C&oacute;digo de 10&euro; para canjear en Amazon.es
          </strong>
        </p>
        <p>
          Este premio se conseguir&aacute; cada vez que un usuario de Plan Amigo
          consiga que un amigo recomendado venga a una de las cl&iacute;nicas a
          una primera consulta preoperatoria de cirug&iacute;a l&aacute;ser,
          presbicia o cataratas. Revisiones generales no incluidas.
        </p>
        <p>
          Una tarjeta monedero por valor de 10&euro; a canjear en Amazon.es.
        </p>
        <p>
          El resto de gastos derivados del premio y no contemplados en este
          apartado los deber&aacute; asumir el cliente.
        </p>
        <p>Las condiciones de este nivel de premios son:</p>
        <p>
          Para disfrutar en los doce meses siguientes a la comunicaci&oacute;n
          del premio. - El cliente recibir&aacute; un voucher on-line para que
          pueda canjear el premio. En dicho voucher, podr&aacute; consultar la
          fecha m&aacute;xima de utilizaci&oacute;n del premio.
        </p>
        <p>
          Para realizar el canje, el cliente deber&aacute; seguir las
          instrucciones detalladas en el voucher online.
        </p>
        <p>
          Premio no acumulable a otros ni canjeable por ning&uacute;n otro
          servicio ni por su contravalor en met&aacute;lico.
        </p>
        <p>
          Los clientes de la presente promoci&oacute;n no podr&aacute;n canjear
          el premio por otro distinto ni por la cantidad equivalente al mismo en
          met&aacute;lico.
        </p>
        <p>
          <strong>
            PREMIO 1: Tarjeta carburante de 20&euro; al conseguir 1
          </strong>{' '}
          <strong>amigo.</strong>
        </p>
        <p>
          Una tarjeta monedero por valor de 50&euro; a canjear en cualquier
          centro Decathlon del territorio nacional o a trav&eacute;s de su
          p&aacute;gina web oficial.
        </p>
        <p>
          El resto de gastos derivados del premio y no contemplados en este
          apartado los deber&aacute; asumir el cliente.
        </p>
        <p>Las condiciones de este nivel de premios son:</p>
        <p>
          Para disfrutar en los doce meses siguientes a la comunicaci&oacute;n
          del premio. - El cliente recibir&aacute; un voucher on-line para que
          pueda canjear el premio. En dicho voucher, podr&aacute; consultar la
          fecha m&aacute;xima de utilizaci&oacute;n del premio.
        </p>
        <p>
          Para realizar el canje, el cliente deber&aacute; seguir las
          instrucciones detalladas en el voucher online.
        </p>
        <p>
          Premio no acumulable a otros ni canjeable por ning&uacute;n otro
          servicio ni por su contravalor en met&aacute;lico.
        </p>
        <p>
          Los clientes de la presente promoci&oacute;n no podr&aacute;n canjear
          el premio por otro distinto ni por la cantidad equivalente al mismo en
          met&aacute;lico.
          <br />{' '}
        </p>
        <p>
          <strong>
            PREMIO 2: Un circuito spa o masaje (para 2 personas) en los
            establecimientos RURALKA al conseguir 2 amigos.
          </strong>
        </p>
        <p>
          Un circuito spa o masaje seg&uacute;n los servicios concretos
          ofrecidos por el establecimiento.
        </p>
        <p>Para una persona mayor de 18 a&ntilde;os.</p>
        <p>
          El cliente podr&aacute; escoger entre cualquiera de los
          establecimientos adheridos, del que ser&aacute; informado a
          trav&eacute;s de la p&aacute;gina web promocional al canjear el
          premio.
        </p>
        <p>
          El resto de gastos derivados del premio y no contemplados en este
          apartado los deber&aacute; asumir el cliente.
        </p>
        <p>Las condiciones de este nivel de premios son:</p>
        <p>
          Reserva sujeta a la disponibilidad de plazas del establecimiento
          escogido.
        </p>
        <p>
          Para disfrutar en los doce meses siguientes a la comunicaci&oacute;n
          del premio.
        </p>
        <p>
          Una vez que el cliente realice la reserva, el establecimiento
          ser&aacute; intransferible.
        </p>
        <p>El listado de establecimientos es susceptible de ser modificado.</p>
        <p>
          El cliente recibir&aacute; un voucher on line para que pueda canjear
          el premio.
        </p>
        <p>
          Las reservas bajo disponibilidad efectuadas con este voucher,
          est&aacute;n sujetas a las pol&iacute;ticas de cancelaci&oacute;n
          marcadas por los establecimientos de Ruralka seg&uacute;n la
          &eacute;poca del a&ntilde;o.
        </p>
        <p>
          Para realizar la reserva el cliente deber&aacute; llamar al 91 405 87
          34 o enviar un mail a&nbsp;
          <a
            href='https://areapaciente.clinicabaviera.com/Paciente/Ajustes/reservas@ruralka.com'
            target='_blank'
          >
            reservas@ruralka.com
          </a>
        </p>
        <p>
          Premio no acumulable a otros ni canjeable por ning&uacute;n otro
          servicio ni por su contravalor en met&aacute;lico.
        </p>
        <p>
          Una vez fijada la fecha del premio, los gastos ocasionados por
          modificaci&oacute;n o cancelaci&oacute;n deber&aacute;n ser abonados
          por el cliente.
        </p>
        <p>
          Los clientes del presente programa no podr&aacute;n canjear el premio
          por otro distinto ni por la cantidad equivalente al mismo en
          met&aacute;lico.
        </p>
        <p>
          <strong>
            PREMIO 3: Tarjeta Decathlon de 50&euro; al conseguir 3 amigos.
          </strong>
        </p>
        <p>
          Una tarjeta monedero por valor de 50&euro; a canjear en cualquier
          centro Decathlon del territorio nacional o a trav&eacute;s de su
          p&aacute;gina web oficial.
        </p>
        <p>
          El resto de gastos derivados del premio y no contemplados en este
          apartado los deber&aacute; asumir el cliente.
        </p>
        <p>Las condiciones de este nivel de premios son:</p>
        <p>
          Para disfrutar en los doce meses siguientes a la comunicaci&oacute;n
          del premio. - El cliente recibir&aacute; un voucher on-line para que
          pueda canjear el premio. En dicho voucher, podr&aacute; consultar la
          fecha m&aacute;xima de utilizaci&oacute;n del premio.
        </p>
        <p>
          Para realizar el canje, el cliente deber&aacute; seguir las
          instrucciones detalladas en el voucher online.
        </p>
        <p>
          Premio no acumulable a otros ni canjeable por ning&uacute;n otro
          servicio ni por su contravalor en met&aacute;lico.
        </p>
        <p>
          Los clientes de la presente promoci&oacute;n no podr&aacute;n canjear
          el premio por otro distinto ni por la cantidad equivalente al mismo en
          met&aacute;lico.
          <br />{' '}
        </p>
        <p>
          <strong>
            PREMIO 4: Cheque regalo de Amazon.es de 100&euro; al conseguir 4
            amigos.
          </strong>
        </p>
        <p>
          Una tarjeta monedero por valor de 100&euro; a canjear en Amazon.es.
        </p>
        <p>
          El resto de gastos derivados del premio y no contemplados en este
          apartado los deber&aacute; asumir el cliente.
        </p>
        <p>Las condiciones de este nivel de premios son:</p>
        <p>
          Para disfrutar en los doce meses siguientes a la comunicaci&oacute;n
          del premio. - El cliente recibir&aacute; un voucher on-line para que
          pueda canjear el premio. En dicho voucher, podr&aacute; consultar la
          fecha m&aacute;xima de utilizaci&oacute;n del premio.
        </p>
        <p>
          Para realizar el canje, el cliente deber&aacute; seguir las
          instrucciones detalladas en el voucher online.
        </p>
        <p>
          Premio no acumulable a otros ni canjeable por ning&uacute;n otro
          servicio ni por su contravalor en met&aacute;lico.
        </p>
        <p>
          Los clientes de la presente promoci&oacute;n no podr&aacute;n canjear
          el premio por otro distinto ni por la cantidad equivalente al mismo en
          met&aacute;lico.
        </p>
        <p>
          <strong>
            PREMIO 5: Experiencias Ruralka que incluye 2 noches de hotel en
            r&eacute;gimen de alojamiento y desayuno para 2 personas m&aacute;s
            una experiencia a elegir
          </strong>{' '}
          (spa, relax, gastron&oacute;mica, enol&oacute;gica, aventura, golf,
          rom&aacute;ntica o familiar) al conseguir 5 amigos a y en base a las
          siguientes condiciones:
        </p>
        <p>
          Dos noches en un hotel con encanto en r&eacute;gimen de alojamiento y
          desayuno m&aacute;s experiencia.
        </p>
        <p>Para dos personas mayores de 18 a&ntilde;os.</p>
        <p>
          El cliente podr&aacute; escoger entre cualquiera de los hoteles
          adheridos al circuito Ruralka, del que ser&aacute; informado a
          trav&eacute;s de la p&aacute;gina web promocional al canjear el
          premio.
        </p>
        <p>
          El resto de gastos derivados del premio y no contemplados en este
          apartado los deber&aacute; asumir el cliente.
        </p>
        <p>Las condiciones de este nivel de premios son:</p>
        <p>
          Reserva sujeta a la disponibilidad de plazas del establecimiento
          escogido.
        </p>
        <p>
          Para disfrutar en los doce meses siguientes a la comunicaci&oacute;n
          del premio.
        </p>
        <p>
          Una vez que el cliente realice la reserva, el hotel ser&aacute;
          intransferible.
        </p>
        <p>El listado de hoteles es susceptible de ser modificado.</p>
        <p>
          El cliente recibir&aacute; un voucher on line para que pueda canjear
          el premio.
        </p>
        <p>
          Las reservas bajo disponibilidad efectuadas con este voucher
          est&aacute;n sujetas a las pol&iacute;ticas de cancelaci&oacute;n
          marcadas por los hoteles de Ruralka seg&uacute;n la &eacute;poca del
          a&ntilde;o.
        </p>
        <p>
          Para realizar la reserva el cliente deber&aacute; llamar al 91 405 87
          34 o enviar un mail a&nbsp;
          <a
            href='https://areapaciente.clinicabaviera.com/Paciente/Ajustes/reservas@ruralka.com'
            target='_blank'
          >
            reservas@ruralka.com
          </a>
          .
        </p>
        <p>
          Premio no acumulable a otros ni canjeable por ning&uacute;n otro
          servicio ni por su contravalor en met&aacute;lico.
        </p>
        <p>
          Una vez fijada la fecha del premio, los gastos ocasionados por
          modificaci&oacute;n o cancelaci&oacute;n deber&aacute;n ser abonados
          por el cliente.
        </p>
        <p>
          Los clientes del presente programa no podr&aacute;n canjear el premio
          por otro distinto ni por la cantidad equivalente al mismo en
          met&aacute;lico.
        </p>
        <p>
          Las condiciones de uso de cada premio estar&aacute;n reflejadas en el
          voucher que reciba cada cliente al canjear su premio. Si el cliente
          tuviera cualquier duda sobre este tema, puede ponerse en contacto con
          RURALKA a trav&eacute;s de su tel&eacute;fono de atenci&oacute;n al
          cliente 914 058 734 o el mail a:&nbsp;
          <a
            href='https://areapaciente.clinicabaviera.com/Paciente/Ajustes/reservas@ruralka.com'
            target='_blank'
          >
            reservas@ruralka.com
          </a>
        </p>
        <p>
          CLINICA BAVIERA queda exenta de cualquier responsabilidad que surja en
          relaci&oacute;n a los premios.
        </p>
        <p>
          Para cualquier otra pregunta con respecto al Plan Amigo, pero no
          relacionada directamente con el canje de premios, puede ponerse en
          contacto con CLINICA BAVIERA a trav&eacute;s de su tel&eacute;fono de
          atenci&oacute;n al cliente 622 817 897 o en el mail a:&nbsp;
          <a href='mailto:infoplanamigo@clinicabaviera.com' target='_blank'>
            infoplanamigo@clinicabaviera.com
          </a>
        </p>
        <p>
          A los clientes verificados de cualquiera de los 5 tipos de premios que
          re&uacute;nan las condiciones detalladas en estas bases se les
          entregar&aacute;n los premios mediante el env&iacute;o de un email con
          voucher online para que pueda canjear su premio.
        </p>
        <p>
          Los premios no podr&aacute;n en ning&uacute;n supuesto ser objeto de
          cambio, alteraci&oacute;n o sustituci&oacute;n por otro premio, y en
          ning&uacute;n caso podr&aacute;n canjearse por su valor en
          met&aacute;lico. No obstante, CLINICA BAVIERA se reserva el derecho de
          modificar los PREMIOS o cambiarlos por otro de igual o superior
          importe. Los PREMIOS son transferibles a cualquier persona elegida por
          el cliente siempre y cuando &eacute;ste sea mayor de edad, y sea
          comunicado a RURALKA por cualquiera de los medios detallados en estas
          bases.
        </p>
        <p>4. CONSIDERACIONES GENERALES</p>
        <p>
          Este PROGRAMA es gratuito para el cliente y no implica en s&iacute;
          mismo un incremento del precio de los servicios que est&eacute;n
          promocionados.
        </p>
        <p>
          Participaci&oacute;n a trav&eacute;s de Internet: CLINICA BAVIERA no
          asume cualquier responsabilidad por da&ntilde;os y perjuicios de toda
          naturaleza que puedan deberse a la falta temporal de disponibilidad o
          de continuidad del funcionamiento de las redes de telecomunicaciones.
        </p>
        <p>
          Modificaciones y/o anexos: CLINICA BAVIERA se reserva el derecho a
          realizar modificaciones a las presentes bases legales o a&ntilde;adir
          anexos sucesivos sobre su mec&aacute;nica y premios, siempre que las
          mismas est&eacute;n justificadas o no perjudiquen a los clientes, y se
          comuniquen a &eacute;stos debidamente.
        </p>
        <p>
          CLINICA BAVIERA no asume responsabilidad alguna por los incidentes,
          tales como de forma enunciativa retrasos, p&eacute;rdidas, etc., que
          pudieran suceder durante los traslados, desplazamientos, actos y
          estancias en el caso de que el premio consista en un viaje, visita o
          asistencia a cualquier tipo de evento, actividad, degustaci&oacute;n
          gastron&oacute;mica o espect&aacute;culo. Tampoco responder&aacute; de
          los casos de fuerza mayor (tales como huelgas, etc.) que pudieran
          impedir a los clientes el disfrute total o parcial de su premio.
          CLINICA BAVIERA quedar&aacute; exenta de toda responsabilidad si
          concurriere alguno de los casos se&ntilde;alados. En caso de que este
          POGRAMA no pudiera realizarse, bien por fraudes detectados en la
          misma, errores t&eacute;cnicos, o cualquier otro motivo que no
          est&eacute; bajo el control de CLINICA BAVIERA o RURALKA y que afecte
          al normal desarrollo del PROGRAMA, CLINICA BAVIERA se reserva el
          derecho a cancelar, modificar, o suspender la misma, incluyendo la
          p&aacute;gina Web de participaci&oacute;n, as&iacute; como la facultad
          de interpretar las presentes Bases Legales, y el derecho de emprender
          acciones judiciales contra aquellas personas que pudieran realizar
          cualquier tipo de acto susceptible de ser considerado
          manipulaci&oacute;n o participaci&oacute;n fraudulenta en el PROGRAMA.
        </p>
        <p>6. CADUCIDAD DE LOS PREMIOS</p>
        <p>
          El disfrute de los premios deber&aacute; hacerse como m&aacute;ximo en
          el plazo de un (1) a&ntilde;o natural desde la validaci&oacute;n del
          premio.
        </p>
        <p>7. EXCLUSIONES A LA PARTICIPACI&Oacute;N</p>
        <p>
          No podr&aacute;n participar en el presente PROGRAMA como
          participantes, ni sus c&oacute;nyuges, ascendientes o descendientes de
          los trabajadores por cuenta ajena empleados en cualquiera de las
          sociedades y otras entidades con personalidad jur&iacute;dica que
          integran el grupo al que pertenece CLINICA BAVIERA y RURALKA.
        </p>
        <p>8. COMUNICACI&Oacute;N</p>
        <p>
          La comunicaci&oacute;n de este programa se realizar&aacute; tanto de
          forma personal en cada una de nuestras cl&iacute;nicas, como en las
          redes sociales que CLINICA BAVIERA estime oportuno y en la
          p&aacute;gina web de CLINICA BAVIERA. Asimismo, tambi&eacute;n
          podr&aacute; ser comunicada en cualquier otro soporte o medio que
          CLINICA BAVIERA considere oportuno para la buena comunicaci&oacute;n
          de la promoci&oacute;n a los consumidores finales.
        </p>
        <p>9. ACEPTACI&Oacute;N DE BASES</p>
        <p>
          Los clientes, por el mero hecho de registrarse en el presente
          PROGRAMA, aceptan las presentes bases legales de forma &iacute;ntegra,
          que podr&aacute;n consultar en la p&aacute;gina web de
          <a
            href='https://www.google.com/url?q=http://www.clinicabaviera.com&amp;sa=D&amp;ust=1480456133955000&amp;usg=AFQjCNFSmFIRtR4-FIpXFPPxYHuTSXXJcw'
            target='_blank'
          >
            www.clinicabaviera.com/planamigo
          </a>{' '}
          y que se encuentran depositadas ante el Notario de la ciudad de
          Madrid, D. Francisco L&oacute;pez Colmenarejo, n&uacute;mero de
          colegiado 9106659 con domicilio profesional en la Calle Germ&aacute;n
          P&eacute;rez Carrasco, 50, 28027 Madrid, a disposici&oacute;n de
          cualquier interesado que desee consultarlas y aceptan igualmente el
          criterio de CLINICA BAVIERA en cuanto a la resoluci&oacute;n de
          cualquier cuesti&oacute;n derivada del presente programa.
        </p>
        <p>10. CARGAS FISCALES Y GASTOS</p>
        <p>
          Los PREMIOS estar&aacute;n sujetos a retenci&oacute;n o ingreso a
          cuenta del Impuesto sobre la Renta de las Personas F&iacute;sicas
          aplicable en Espa&ntilde;a. En caso de que el valor del premio
          adjudicado a efectos de retenciones supere el importe de 300 &euro;,
          corresponder&aacute; y ser&aacute; por cuenta de CLINICA BAVIERA el
          pago del ingreso a cuenta correspondiente conforme a los tipos
          aplicables vigentes en el momento del devengo seg&uacute;n la
          normativa fiscal espa&ntilde;ola. El ingreso a cuenta no ser&aacute;
          repercutido a los ganadores, siendo asumido por CLINICA BAVIERA.
        </p>
        <p>
          Las repercusiones fiscales que la obtenci&oacute;n del Premio tenga en
          la fiscalidad del cliente ser&aacute;n por cuenta de &eacute;stos, por
          lo que CLINICA BAVIERA queda relevada de cualquier responsabilidad.
        </p>
        <p>
          Asimismo, y en su caso, CLINICA remitir&aacute; a los premiados, de
          acuerdo con la normativa expuesta, el correspondiente certificado de
          retenciones e ingresos a cuenta.
        </p>
      </Card>
    </div>
  )
}
