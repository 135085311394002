import * as c from "../plan-amigo-constants";

/**
 * Registro de un nuevo usuario dentro del area de plan amigo
 * @param {{dni:string, mail:string, nombre:string}} userData Datos devueltos por el propio backend de plan amigo
 */

export const registerUser =async () => {
  const token = localStorage.getItem("userToken");

  const headers = {
    Origin: "*",
    Authorization: token,
  };
  try {
    let url = c.REGISTER;
    const res = await fetch(url, {
      method: "POST",
      headers
    })
    .then(async (res) => {
      const result = await res.json();
      return result;
    })
      
		console.log('registerUser PA',res)
    return res;
  } catch (e) {
    throw handler(e);
  }
};

/**
 * @description
 */

/**
 * @description 400 Bad request - la petición no tiene todos los parámetros de forma correcta.
 * @description	404 Usuario no encontrado en el sistema
 * @returns {Object} La respuesta del servidor incluye el status, que en caso de éxito es "ok"
 * y datos.
 * @typedef {Object}  res
 * @property {Object} res
 * @property {String} res.status
 * @typedef {Object}  res.datos
 * @property {String} res.datos.title
 * @property {String} res.datos.description
 * @property {number} res.datos.points
 * @property {String} res.datos.url
 * @property {number} res.datos.id-premio
 */

export async function getPrizes() {
  const token = localStorage.getItem("userToken");

  const headers = {
    Origin: "*",
    Authorization: token,
  };
  try {
    let url = c.GET_PRIZES;
    const res = await fetch(url, {
      method: "GET",
      headers,
    })
      .then(async (res) => {
        const result = await res.json();
        return {
          body: result,
          status: res.status,
        };
      })
      .then((response) => response);

    return res;
  } catch (e) {
    throw handler(e);
  }
}


export async function getPrize(id) {
  const token = localStorage.getItem("userToken");

  const headers = {
    Origin: "*",
    Authorization: token,
  };
  try {
    let url = c.GET_PRIZE+id;
    const res = await fetch(url, {
      method: "GET",
      headers,
    })
      .then(async (res) => {
        const result = await res.json();
        return {
          body: result,
          status: res.status,
        };
      })
      .then((response) => response);

    return res;
  } catch (e) {
    throw handler(e);
  }
}

export async function getTextosEstaticos() {
  const token = localStorage.getItem("userToken");

  const headers = {
    Origin: "*",
    Authorization: token,
  };
  try {
    let url = c.GET_TEXTOS_ESTATICOS;
    const res = await fetch(url, {
      method: "GET",
      headers,
    })
      .then(async (res) => {
        const result = await res.json();
        return {
          body: result,
          status: res.status,
        };
      })
      .then((response) => response);

    return res;
  } catch (e) {
    throw handler(e);
  }
}

/**
 * Se obtiene una lista de spas del servidor
 * @returns {{status:String, data: Array.<{id: String, title: String, prov: String, img_original: String, img_format: String, url: String, promo: String}}}
 * @description La respuesta del servidor incluye el status, que en caso de éxito es "ok" y datos.
 *
 */

export async function getSpaList() {
  const token = localStorage.getItem("userToken");

  const headers = {
    Origin: "*",
    Authorization: token,
  };
  try {
    let url = c.GET_SPA_LIST;
    const res = await fetch(url, {
      method: "GET",
      headers,
    })
      .then(async (res) => {
        const result = await res.json();
        return {
          data: result,
          status: res.status,
        };
      })
      .then((response) => response);
    return res;
  } catch (e) {
    throw handler(e);
  }
}

/**
 * Se obtiene una lista de hoteles del servidor
 * @returns {{status:String, data: Array.<{id: String, title: String, prov: String, img_original: String, img_format: String, url: String, promo: String}}}
 * @description La respuesta del servidor incluye el status, que en caso de éxito es "ok" y datos.
 *
 */

export async function getHotelList() {
  const token = localStorage.getItem("userToken");

  const headers = {
    Origin: "*",
    Authorization: token,
  };
  try {
    let url = c.GET_HOTEL_LIST;
    const res = await fetch(url, {
      method: "GET",
      headers,
    })
      .then(async (res) => {
        const result = await res.json();
        return {
          data: result,
          status: res.status,
        };
      })
      .then((response) => response);
    return res;
  } catch (e) {
    throw handler(e);
  }
}

/**

 * @description Se solicita el número de puntos obtenidos por el usuario y el número de premios obtenidos. 
 * @param {Object} data
 * @param {String} data.dni
 * 
 * @returns {Object} La respuesta del servidor incluye el status, que en caso de éxito es "ok"
 * y datos. 
 * @description 400 Bad request - la petición no tiene todos los parámetros de forma correcta.
 * @description 404 Usuario no encontrado en el sistema
 * @typedef {Object} res 
 * @property {Object} res 
 * @property {String} res.status 
 * @property {Object} res.datos   
 */
export async function requestPointsAndPrizesByDNI(data) {
  const token = localStorage.getItem("userToken");
  const headers = {
    Origin: "*",
    Authorization: token,
  };

  try {
    if (data === null) {
      throw new Error("401");
    }

    const url = c.REQUEST_POINTS_PRIZES_BY_DNI;
    const res = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify({ dni: data }),
    })
      .then(async (res) => {
        if (res.status === 404) {
          throw handler(res.status);
        }
        const result = await res.json();

        return result.datos;
      })
      .then((response) => response);

    return res;
  } catch (e) {
    throw handler(e);
  }
}

/**
 * @description 400 Bad request - la petición no tiene todos los parámetros de forma correcta.
 * @description 404 Usuario no encontrado en el sistema
 * @description 403 Tipo premio no permitido
 * @description El usuario no tiene puntos suficientes para canjear el premio
 * @param {Object} data
 * @param {String} data.dni DNI del cliente
 * @param {number} data["tipo-premio"]
 * @returns {Object} La respuesta del servidor incluye el status, que en caso de éxito es "ok"
 * y datos.
 * @typedef {Object} res
 * @property {Object} res
 * @property {String} res.status
 * @property {Object} res.datos
 */

export async function requestExchangePrize(data) {
  const token = localStorage.getItem("userToken");

  const headers = {
    Origin: "*",
    Authorization: token,
  };

  try {
    if (data === null) {
      throw new Error("401");
    }

    const url = c.REQUEST_EXCHANGE_PRIZE;
    const res = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify({ ["tipo-premio"]: data["tipo-premio"] }),
    })
      .then(async (res) => {
        const result = await res.json();
        return {
          body: result,
          status: res.status,
        };
      })
      .then((response) => response);

    return res;
  } catch (e) {
    throw handler(e);
  }
}

/**
 * @description 400 Bad request - la petición no tiene todos los parámetros de forma correcta.
 * @description 400 faltan emails para invitaciones
 * @description 404 Usuario no encontrado en el sistema
 * @returns {Object} La respuesta del servidor incluye el status, que en caso de éxito es "ok"
 * y datos. 
 * @typedef {Object} res 
 * @property {Object} res 
 * @property {String} res.status 
 * @property {Object[Array]} res.datos
 * @param {Object} data
 * @param {String} data.dni
 * @param {[String]} data.invitaciones

 *    
 */

export async function requestInvitation(data) {
  const token = localStorage.getItem("userToken");

  const headers = {
    Origin: "*",
    Authorization: token,
  };
  try {
    if (data === null) {
      throw new Error("401");
    }
    const url = c.REQUEST_INVITATION;

    const res = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify({ invitaciones: data.invitaciones }),
    })
      .then(async (res) => {
        const result = await res.json();
        return {
          body: result,
          status: res.status,
        };
      })
      .then((response) => {
        return response;
      });

    return res;
  } catch (e) {
    throw handler(e);
  }
}

/**
 *
 * @returns {Object} La respuesta del servidor incluye el status, que en caso de éxito es "ok"
 * y datos.
 * @typedef {Object} res
 * @property {Object} res
 * @property {String} res.status
 * @property {Object} res.datos
 */

export function handler(err) {
  let error = err;
  // Error

  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    //console.log(error.response.data.Message);
    if (error.response.data.Message)
      return new Error(error.response.data.Message);
    // console.log(error.response.status);
    // console.log(error.response.headers);
    return new Error(error.response.data);
  } else if (error.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */
    //console.log(error.request);
    return new Error(error.request);
  } else {
    // Something happened in setting up the request and triggered an Error
    if (error && error.message) return new Error(error.message);
    else return new Error(error);
  }
}
