import React from "react";
import Card from "../../../components/Card/Card";
import logo from '../../../assets/images/lg-baviera-color.svg';
import './conditions.scss';

const Conditions = ({ onClose }) => {
  return (
    <div className="legal-info">
    <div className="logo-container">
      <a href="/" className="logo">
        <img alt="Clínica Baviera" src={logo} />
      </a>
    </div>
  <Card>
          <h1>Términos y condiciones Área del Paciente</h1>
          <p>
            El Área del Paciente (en adelante, Área Paciente) ha sido diseñado con la finalidad de
            crear un canal de comunicación entre Clínica Baviera (en adelante “CB”) y sus usuarios y
            pacientes, para éstos que puedan acceder a determinada información y gestionar sus
            citas.
          </p>
          <p>Mediante el Área Paciente se podrán realizar gestiones relacionadas con:</p>
          <ul>
            <li>Citas médicas con CB</li>
          </ul>
          <ul>
            <li>Informes / Recetas / Justificantes</li>
          </ul>
          <ul>
            <li>Facturas y presupuestos</li>
          </ul>
          <ul>
            <li>Contactar con la clínica</li>
          </ul>
          <ul>
            <li>Plan amigo</li>
          </ul>
          <p>El acceso al Área Paciente atribuye a quien lo realice la condición de "usuario".</p>
          <p>
            Para formalizar el registro en el Área Paciente será necesario aceptar los presentes
            términos y condiciones.
          </p>
          <p>El acceso al Área Paciente es responsabilidad exclusiva de los usuarios.</p>
          <p>
            El acceso al Área Paciente no supone entablar ningún tipo de relación de carácter
            comercial entre Clínica Baviera y el usuario.
          </p>
          <p>
            El acceso y navegación por el Área Paciente supone aceptar y conocer las advertencias
            legales, condiciones y términos de uso contenidas en la misma.
          </p>
          <p>
            El usuario declara bajo su responsabilidad ser mayor de 16 años. Se compromete a ser el
            responsable de todo cuanto realice en este lugar. Declara igualmente que toda la
            información que facilita es verdadera y exacta, sin limitaciones, reservas o falsedades
            y que no pertenece a terceras personas (a excepción de los datos que facilite en caso de
            participar en el Plan Amigo). De igual manera, el usuario será responsable de mantener
            de forma actualizada toda aquella información que haya facilitado a Clínica Baviera, de
            forma que ésta responda, en cada momento, a la situación real del usuario. El usuario
            será, asimismo, responsable de los daños y perjuicios que se puedan ocasionar a Clínica
            Baviera o a terceros por los datos aportados, como consecuencia de la utilización de
            datos no actualizados, falsos o que no se corresponden con la realidad y por la
            utilización por parte de terceros de las contraseñas personales del usuario.
          </p>
          <h2>Condiciones de Registro y acceso</h2>
          <p>
            Para acceder al Área Paciente es requisito imprescindible que el usuario se registre en
            el mismo que acepte las presentes condiciones de uso.
          </p>
          <p>
            Puede registrarse cualquier persona física, mayor de 16 años, y que suscriba las
            presentes Condiciones. En el caso de menores de 16 años, los padres o tutores legales
            podrán, previa acreditación de su condición de representantes, solicitar la contraseña
            de acceso correspondiente al Área Paciente del menor.
          </p>
          <p>
            Los nombres de usuario y contraseñas facilitados permitirán identificar al usuario de
            forma personalizada e individualizada. Dichos nombres de usuario y contraseñas tienen el
            carácter de estrictamente personal, confidencial e intransferible. El usuario será
            responsable de mantener la confidencialidad de las mismas, no pudiendo divulgarlas a un
            tercero o cederlas. En consecuencia, el usuario acepta expresamente que Clínica Baviera
            presuma, salvo comunicación previa de extravío o sustracción de las mismas, que los usos
            del Área Paciente realizados utilizando la contraseña de identificación, son realizados
            por quien figura como usuario registrado.{" "}
          </p>
          <p>
            Clínica Baviera siempre que lo considere oportuno, podrá modificar unilateralmente y sin
            previo aviso la estructura y diseño del Área Paciente, así como modificar o eliminar,
            sus servicios, contenidos y condiciones de acceso y/o utilización. Asimismo, Clínica
            Baviera podrá anular el acceso a los contenidos, total o parcialmente, a aquellos
            usuarios que incumplan las presentes condiciones.
          </p>
          <h2>Uso del servicio</h2>
          <p>
            El usuario se compromete en todo caso a utilizar el Área Paciente de modo diligente,
            correcto, lícito de conformidad con la ley, el Aviso Legal, las presentes condiciones,
            así como con la moral y las buenas costumbres generalmente aceptadas y el orden público.
          </p>
          <p>
            El acceso al Área Paciente, su uso y la utilización correcta de la información contenida
            en el mismo son responsabilidad del quien realiza estas acciones, no siendo responsable
            Clínica Baviera por el uso incorrecto, ilícito o negligente que pudiera llevar a cabo el
            usuario.
          </p>
          <p>
            Se prohíbe la colocación o transmisión de toda materia ilícita, calumniosa u obscena, o
            cualquiera otra que pudiera constituir o inducir a conductas que podrían tipificarse
            como delictivas, ocasionar responsabilidad civil o vulnerar de otra forma la legislación
            aplicable.
          </p>
          <p>
            Clínica Baviera no será responsable de los daños y perjuicios que pudieran derivarse de
            la utilización de versiones de navegadores no actualizadas, del mal funcionamiento del
            navegador o dispositivo utilizado, ya sea por configuración inadecuada, presencia de
            virus informáticos o cualquier otra causa ajena a Clínica Baviera.
          </p>
          <p>
            El usuario se compromete a hacer un uso adecuado de los contenidos del Área Paciente y a
            no emplearlos para incurrir en actividades ilícitas o contrarias a la buena fe y al
            ordenamiento legal, provocar daños en los sistemas físicos y lógicos de Clínica Baviera,
            de sus proveedores o de terceras personas, introducir o difundir en la red virus
            informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de
            provocar los daños anteriormente mencionados, a no intentar acceder, recoger o utilizar
            datos personales de otros usuarios sin su consentimiento expreso o contraviniendo lo
            dispuesto en la normativa sobre protección de datos.
          </p>
          <p>
            La duración de los servicios prestados mediante el Área Paciente de la web o de la App
            Clínica Baviera es de carácter indefinido. Sin perjuicio de lo anterior, Clínica Baviera
            se reserva el derecho a interrumpir, suspender o cerrar el Área Paciente o a suspender
            temporal o definitivamente cualquiera de los servicios que lo integran.
          </p>
          <p>
            Clínica Baviera estará exenta de cualquier responsabilidad derivada del acceso y uso del
            Área Paciente (fallos en el rendimiento, interrupciones o demora en el funcionamiento de
            los servicios, fallo del sistema o de la línea, etc.), incluso aunque se produjera algún
            fallo o mal funcionamiento que causara daños en herramientas informáticas de terceras
            personas. En este sentido, el usuario accede al Área Paciente bajo su exclusiva
            responsabilidad.
          </p>
          <p>
            Clínica Baviera no será responsable en los supuestos de indisponibilidad del Área
            Paciente o sus contenidos o servicios por causas de fuerza mayor o suspensión temporal
            del mismo por razones técnicas. Por tanto, Clínica Baviera no asumirá responsabilidad
            alguna por los daños pérdidas que pudieran sufrir los Usuarios como consecuencia de
            sucesos que no hubieran podido preverse, o que previstos fueran inevitables, bien por
            caso fortuito o fuerza mayor.
          </p>
          <p>
            A pesar de que Clínica Baviera ha actuado de forma diligente y ha adoptado las medidas
            necesarias de acuerdo con el estado de la tecnología, para evitar posibles brechas en la
            seguridad del Portal, no garantiza la imposibilidad absoluta de vulnerabilidad de las
            medidas de seguridad adoptadas.
          </p>
          <h2>Jurisdicción y legislación aplicable</h2>
          <p>
            Cualquier controversia que pudiera derivarse del acceso o la utilización de la Página
            Web o App se someterá a la exclusiva interpretación de la legislación española y a la
            jurisdicción de los Juzgados y Tribunales de Madrid, sin perjuicio de que resulte de
            aplicación alguna disposición imperativa en contrario, en cuyo caso se estará al
            contenido de la misma.
          </p>
      </Card>
    </div>
  );
};

export default Conditions;
