import React, { Suspense, useEffect, useState } from "react";
import happyFace from "../../../assets/images/happy-face.svg";
import normalFace from "../../../assets/images/normal-face.svg";
import sadFace from "../../../assets/images/sad-face.svg";
import * as api from "../../../services/services";
import { useAuth } from "../../../provider";
import "./clientPoll.scss";
import ModalBox from "../../../components/ModalBox/ModalBox";
import Loading from "../../../components/Loading/Loading";
import phoneBackground from "../../../assets/images/phone-hand-background.svg";
import happyBackground from "../../../assets/images/happy-background.svg";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";

/**
 * @description Renderizado de la encuesta de satisfacción
 * No existe un link en la aplicación que redirija aquí, 
 * sino que este componente y su ruta se renderizarán sólo 
 * si el paciente se ha operado en los dos últimos días
 */

const ClientPoll = () => {
  /////////////////////////////
  // Configuración del componente
  /////////////////////////////

  const { state } = useAuth();
  const [satisfactionGrades, setSatisfactionGrades] = useState([]);
  const [satisfactionModal, setSatisfactionModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorStatus, setErrorStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isHappy, setIsHappy] = useState(false);

  useEffect(() => {
    getDataAsync();
  }, []);

  /////////////////////////////
  // Gestión de eventos y servicios
  /////////////////////////////

  /**
   * @description Hace una llamada al backend para conseguir los tipos de puntuación
   */

  
  const getDataAsync = async () => {
    try {
      setLoading(true);

      let response = await api.getScores(state.user);
      if (response) {
        setSatisfactionGrades(response);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.message);
      setErrorStatus(error.status);
    }
  };

/**
 * 
 * @param {Number} values.IdGradoSatisfaccion 1 = SATISFECHO 2 y 3 = NO SATISFECHO   
 * 
 * @description Gestiona el click del usuario en cualquiera de los iconos de la encuesta de satisfacción. 
 * Sólo si se selecciona el primero (values.IdGradoSatisfaccion) se enviará una respuesta favorable
 */

  const onIconClick = (values) => {
    setSatisfactionModal(true);

    if (values.IdGradoSatisfaccion === 1) {
      setIsHappy(true);
      sendUserResponse(values);
    } else {
      setIsHappy(false);
      sendUserResponse(values);
    }
  };

  /**
   * 
   * @param {Object} values 
   * @description Helper para gestionar la llamada a la API. 
   * 
   */

  const sendUserResponse = async (values) => {
    const data = {
      ...values,
      ...state.user,
    };

    try {
      await api.requestScore(data);
    } catch (error) {
      setErrorMessage(error.message);
      setErrorStatus(error.status);
    }
  };

  /////////////////////////////
  // Funciones de renderizado
  /////////////////////////////

  const renderSatisfactionModal = () => {
    const LazyModal = React.lazy(() => import("../../../components/ModalBox/ModalBox"));
    const text = isHappy
      ? "Nos alegramos de que te encuentres bien. Disfruta de tu nueva vida sin gafas."
      : "Gracias por contarnos cómo te encuentras.";
    return (
      <Suspense
        fallback={
          <ModalBox>
            <Loading />
          </ModalBox>
        }
      >
        <LazyModal onToggle={setSatisfactionModal}>
          <div className="modal-poll-container">
            <h1>{text}</h1>
            <img src={isHappy ? happyBackground : phoneBackground} alt="" />
          </div>
        </LazyModal>
      </Suspense>
    );
  };

  /**
   * @description Renderizado de errores de servidor
   */

  const renderErrors = () =>
    errorMessage || errorStatus ? <ErrorHandler errorMessage={errorMessage} errorStatus={errorStatus} /> : null;

  /////////////////////////////
  // Renderizado del componente
  /////////////////////////////

  return loading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <h2 className="subtitle">¿Cómo te encuentras tras tu operación?</h2>
      {renderErrors()}
      {satisfactionModal ? renderSatisfactionModal() : null}
      <div className="client-poll">
        {satisfactionGrades.map((item, index) => {
          return (
            <button type="button" className="card-poll" onClick={() => onIconClick(item)} key={index}>
              <img
                src={item.IdGradoSatisfaccion === 1 ? happyFace : item.IdGradoSatisfaccion === 2 ? normalFace : sadFace}
                alt="Contento"
              />
              <p>
                {item.IdGradoSatisfaccion === 1
                  ? "Contento"
                  : item.IdGradoSatisfaccion === 2
                  ? "Normal"
                  : "Descontento"}
              </p>
            </button>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default ClientPoll;
