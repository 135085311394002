export const SET_LAST_LOCATION = "loc/SET_LAST_LOCATION";
export const DELETE_LAST_LOCATION = "loca/DELETE_LAST_LOCATION";

const initialState = {};

export const locationReducer = (state, action) => {
	switch (action.type) {
		case SET_LAST_LOCATION: {
			const { lastLocation } = action;
			return {
				lastLocation,
			};
		}
		case DELETE_LAST_LOCATION: {
			return {};
		}
		default:
			return state;
	}
};

export default locationReducer;
