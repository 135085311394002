import React, { Suspense } from "react";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import Loading from "../Loading/Loading";
import ModalBox from "../ModalBox/ModalBox";
import SuccessHandler from "../SuccessHandler/SuccessHandler";

const MessageModal = ({ image, setModalMessage, children, modalToggle, isError, modalMessage, noMessage }) => {
	const handleToggle = () => {
		if (modalToggle) {
			modalToggle();
		}
		if (setModalMessage) {
			setModalMessage(null);
		}
	};

	const MessageLazy = React.lazy(() => import("../ModalBox/ModalBox"));

	return (
		<Suspense
			fallback={
				<ModalBox onToggle={handleToggle}>
					<Loading />
				</ModalBox>
			}
		>

			
			<MessageLazy onToggle={handleToggle}>
				{isError ? (
					<ErrorHandler errorMessage={modalMessage} />
				) : (
					<SuccessHandler successMessage={modalMessage} image={image} />
				)}
				{children}
			</MessageLazy>
		</Suspense>
	);
};

export default MessageModal;
