import React from "react";
import PrizeItem from "../PrizeItem/PrizeItem";
import "./PrizesList.scss";

const PrizesList = ({ prizesList, provincesList, handleSelectChange }) => {
	const onChange = (e) => handleSelectChange(e.target.value);

	return (
		<React.Fragment>
			<div className="select-container">
				<label htmlFor="provinces">Elige tu provincia</label>
				<div className="select-element">
					<select name="select" onChange={onChange} id="provinces" >
						<option value="all">Todas</option>
						{provincesList &&
							provincesList?.map((province, index) => (
								<option value={province} key={index}>
									{province}
								</option>
							))}
					</select>
				</div>
			</div>
		<div className="prizes-list-container">
			{prizesList.map((prizeItem, index) => {
					const props = {
						name: prizeItem.title,
						province: prizeItem.prov,
						image: prizeItem.img_format,
						text: prizeItem.promo,
						url: prizeItem.url
					};
					return <PrizeItem prizeItem={props} key={index} />;
				})}
		</div>
		</React.Fragment>
	);
};

export default PrizesList;
