import React, { Suspense, useState, useEffect } from "react";

import "./recomend.scss";
import ErrorDialog from "../../../../components/ErrorDialog/ErrorDialog";
import ModalBox from "../../../../components/ModalBox/ModalBox";
import ButtonMain from "../../../../components/ButtonMain/ButtonMain";
import Loading from "../../../../components/Loading/Loading";
import inviteFriendIcon from "../../../../assets/images/icons/people-icon_.svg";
import "./recomend.scss";
import shareIcon from "../../../../assets/images/icons/share-icon.svg";
import { useHistory } from "react-router-dom";
import { usePlanAmigo } from "../../../../plan-amigo-provider";
import {
	FacebookIcon,
	FacebookShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
} from "react-share";
import Snackbar from "../../../../components/Snackbar/Snackbar";

const Recomend = ({ setCurrentLocation , data}) => {
	/////////////////////////////
	// Configuración del componente
	/////////////////////////////
	const [isUrlCopied, setIsUrlCopied] = useState(false);
	const [modalSuccessOpen, setModalSuccessOpen] = useState(false);
	const [modalErrorOpen, setModalErrorOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const { planAmigoState } = usePlanAmigo();

	/**
	 * Setea la localización a la url correcta
	 */

	useEffect(() => {
		setCurrentLocation(history.location.pathname);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history.location.pathname]);

	/////////////////////////////
	// Funciones de renderizado
	/////////////////////////////
	const renderSuccessModal = () => {
		const LazyModal = React.lazy(() => import("../../../../components/ModalBox/ModalBox"));

		return (
			<Suspense
				fallback={
					<ModalBox onToggle={setModalSuccessOpen}>
						<Loading />
					</ModalBox>
				}
			>
				<LazyModal onToggle={setModalSuccessOpen}>
					<div className="success-text">Tu petición se ha realizado con éxito</div>
				</LazyModal>
			</Suspense>
		);
	};

	/**
	 * Renderizado del componente de cargado
	 */

	const renderLoadingModal = () => {
		return (
			<ModalBox hasCloseButton={false}>
				<Loading />
			</ModalBox>
		);
	};

	/**
	 *
	 */

	const renderErrorModal = () => {
		const LazyModal = React.lazy(() => import("../../../../components/ModalBox/ModalBox"));
		return (
			<Suspense
				fallback={
					<ModalBox onToggle={setModalErrorOpen}>
						<Loading />
					</ModalBox>
				}
			>
				<LazyModal onToggle={setModalErrorOpen}>
					<ErrorDialog text={errorMessage} />
					<ButtonMain action={setModalErrorOpen} label="OK" actionParams={false} />
				</LazyModal>
			</Suspense>
		);
	};

	const copyTextToClipboard = () => {
		navigator.clipboard.writeText(planAmigoState.referralUrl).then(() => setIsUrlCopied(true));
		setTimeout(() => {
			setIsUrlCopied(false);
		}, 4000);
	};

	return (
		<React.Fragment>
			{modalSuccessOpen && renderSuccessModal()}
			{modalErrorOpen && renderErrorModal()}
			{loading && renderLoadingModal()}
			<div className="recomend-container">
				<div className="friend-list-container">
					<div className="card-top-title">
						<div className="wrapper-icon">
							<img src={inviteFriendIcon} alt="" />
							<h3>Envía el link a tus amigos</h3>
						</div>
						<ButtonMain
							iconClass={"btn-copy"}
							action={copyTextToClipboard}
							label={"Copiar link"}
							customClass={"small-button"}
						/>
					</div>
					<div className="recomend-link">{planAmigoState.referralUrl}</div>
				</div>
				<div className="social-network-container ">
				<div className="card-top-title">
					<div className="wrapper-icon">
						<img src={shareIcon} alt=".." />
						<h3>Comparte directamente en redes sociales</h3>
					</div>
					</div>
					<div className="social-network-container__icons">
						<div className="social-network-item">
							<FacebookShareButton url={planAmigoState.referralUrl}>
								<FacebookIcon round={true} size={50} className="social-icon" />
							</FacebookShareButton>
						</div>
						<div className="social-network-item">
							<TwitterShareButton url={planAmigoState.referralUrl}>
								<TwitterIcon round={true} size={50} fill={"#62bfe8"} />
							</TwitterShareButton>
						</div>
						<div className="social-network-item">
							<WhatsappShareButton url={planAmigoState.referralUrl}>
								<WhatsappIcon round={true} size={50} fill={"#62bfe8"} />
							</WhatsappShareButton>
						</div>
					</div>
				</div>
				<div className="block-bottom-info">
					<p>
						{data.disclaimer}
					</p>
				</div>
			</div>
			{isUrlCopied && <Snackbar text={"Url copiada"} />}
		</React.Fragment>
	);
};

export default Recomend;
