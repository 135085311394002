import React from "react";
import { Link } from "react-router-dom";
import { setLabeltoClass } from "../../../../../helpers/helpers.js";
import "./tab.scss";

/**
 * 
 * @param {String} label 
 * @param {Object} activeTab 
 * @param {Function} handleClick 
 * @param {String} url
 * @param {Function} setError
 * 
 * @description Renderiza la tab y ejecuta las funciones 
 * que permiten el cambio de tab y de ruta 
 *  
 */

const Tab = ({ label, activeTab, handleClick, url, setError }) => {
  const onClick = () => {
		setError(null);
		handleClick(label);
  };


  let className = activeTab.label === label ? "tab-list-item tab-list-active" : "tab-list-item";
  const styledLabel = setLabeltoClass(label)
  
  return (
    <Link onClick={onClick} className={`${className} ${styledLabel}`} to={url}>
      <span>{label}</span>
    </Link>
  );
};

export default Tab;
