import React from "react";
import PrizesList from "./PrizesList/PrizesList";
import "../../components/PrizesListPage/prizesListPage.scss";

const PrizesListPage = ({ prizesList, handleSelectChange, provincesList }) => {
	return (
		<div className="spa-hotels-container">
			<p className="availability-title">Estos son los centros disponibles</p>
			<div className="prizes-block-container">
				<PrizesList
					prizesList={prizesList}
					provincesList={provincesList}
					handleSelectChange={handleSelectChange}
				/>
			</div>
		</div>
	);
};

export default PrizesListPage;
