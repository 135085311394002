import React, { Suspense, useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import Card from "../../../components/Card/Card";
import InputBlock from "../../../components/InputBlock/InputBlock";
import ButtonMain from "../../../components/ButtonMain/ButtonMain";
import iconWarning from "../../../assets/images/icons/warning-color-icon.svg";
import "./setPassword.scss";
import ModalBox from "../../../components/ModalBox/ModalBox";
import { useAuth } from "../../../provider";
import * as api from "../../../services/services";
import { Link, useHistory } from "react-router-dom";
import ErrorDialog from "../../../components/ErrorDialog/ErrorDialog";
import ButtonBack from "../../../components/ButtonBack/ButtonBack";
import Loading from "../../../components/Loading/Loading";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import { useCustomUseLocation } from "../../../lastLocationProvider";

const SetPassword = () => {
	/////////////////////////////
	// Configuración del componente
	/////////////////////////////

	// const [hidePassword, setHidePassword] = useState(true);
	const [errorMessage, setErrorMessage] = React.useState(null);
	const [errorStatus, setErrorStatus] = React.useState(null);
	const [modalPrivacyOpen, setModalPrivacyOpen] = useState(false);
	const { state, userResponse, updateUser, handleLogout } = useAuth();
	const { deleteLastLocation, stateLocation } = useCustomUseLocation();
	const [passwordModalToggle, setPasswordToggleModal] = useState(false);

	let history = useHistory();

	/////////////////////////////
	// Gestión de eventos y servicios
	/////////////////////////////

	/**
	 *
	 * @param {object} values
	 * @see {handleSubmit}
	 * @description Llamada a la api que envía los datos del usuario para que su nueva contraseña se resetee
	 *
	 */

	const onSubmit = async (values) => {
		var parameters = {
			idPaciente: state.user.idPaciente,
			idClinica: state.user.idClinica,
			password: values.password,
		};
		try {

			const res = await api.resetPassword(parameters);

			return res;
		} catch (error) {
			setErrorStatus(error.status);
			setErrorMessage(error.message);
		}
	};

	/**
	 * @see {handleSubmit}
	 * @description Redirecciona cuando el usuario es nuevo
	 */

	const onValidation = async () => {
		await updateUser(userResponse.user);
		const url = stateLocation.lastLocation;
		deleteLastLocation();
		if (url !== undefined) {
			return history.push(url);
		} else {
			return history.push("/intro/login");
		}
	};

	/**
	 *
	 * @param {object} values
	 * @see {onSubmit}
	 * @description Gestiona la llamada a la api hecha en onSubmit
	 * Si la llamada tiene éxito se redirecciona mediante onValidation()
	 * Si no se crea un mensaje de error
	 */

	const handleSubmit = async (values) => {
		try {
			var response = await onSubmit(values);
			if (response) {
				await updateUser(userResponse.user);
				setPasswordToggleModal(true);
				
				setTimeout(() => {
					handleLogout()
					//history.push('/intro/login')
				}, 2000);
				//onValidation();
			}
		} catch (error) {
			setErrorStatus(error.status);
			setErrorMessage(error.message);
		}
	};

	/////////////////////////////
	// Funciones de renderizado
	/////////////////////////////

	/**
	 *
	 * @returns {Component}
	 * @description Gestiona la apertura del modal que contiene las condiciones de privacidad.
	 *
	 */

	const renderAcceptPrivacyModal = () => {
		const LazyPrivacy = React.lazy(() => import("../Privacy/Privacy"));

		return modalPrivacyOpen ? (
			<Suspense
				fallback={
					<ModalBox>
						<Loading />
					</ModalBox>
				}
			>
				<ModalBox onToggle={setModalPrivacyOpen}>
					<LazyPrivacy></LazyPrivacy>
				</ModalBox>
			</Suspense>
		) : null;
	};

	/**
	 *
	 * @returns {Component}
	 * @description Gestiona la apertura del modal que contiene las condiciones de uso.
	 *
	 */

	const [modalConditionsOpen, setModalConditionsOpen] = useState(false);
	const renderAcceptConditionsModal = () => {
		const LazyConditions = React.lazy(() => import("../Privacy/Privacy"));

		return modalConditionsOpen ? (
			<Suspense
				fallback={
					<ModalBox>
						<Loading />
					</ModalBox>
				}
			>
				<ModalBox onToggle={setModalConditionsOpen}>
					<LazyConditions></LazyConditions>
				</ModalBox>
			</Suspense>
		) : null;
	};

	/**
	 *
	 * @description Si hay un error devuelve un mensaje
	 *
	 */

	const renderErrors = () =>
		errorMessage || errorStatus ? (
			<ErrorHandler errorMessage={errorMessage} errorStatus={errorStatus} />
		) : null;

	/**
	 *
	 * @description Esquema de validación del formulario
	 * @see {renderFormik}
	 *
	 */

	const validationSchemaNewUser = yup.object({
		password: yup
			.string()
			.required("La contraseña es obligatoria")

			// La siguiente expresión regular indica que se requiere:
			//  -algún caracter numérico ***(?=\w*\d)***
			//  -algún caracter alfabético en mayúsculas ***(?=\w*[A-Z])***
			//  -entre 6 y 8 caracteres. ***{6,8}***

			.matches(
				/^(?=\w*\d)(?=\w*[A-Z]).{6,150}$/,
				"Las contraseñas deben tener al menos 6 caracteres y al menos una letra mayúscula (A-Z) y un número (0-9)."
			),
		confirmPassword: yup.string().when("password", {
			is: (val) => (val && val.length > 0 ? true : false),
			then: yup
				.string()
				.oneOf(
					[yup.ref("password")],
					"La contraseña y su confirmación no coinciden. Por favor verifique los valores introducidos."
				),
		}),
		acceptCondition: yup
			.boolean()
			.required("Para continuar acepta las Condiciones de Uso.")
			.oneOf([true], "Para continuar acepta las Condiciones de Uso."),
		acceptPrivacy: yup
			.boolean()
			.required("Para continuar acepta la Política de Privacidad.")
			.oneOf([true], "Para continuar acepta la Política de Privacidad."),
	});

	const validationSchemaOldUser = yup.object({
		password: yup
			.string()
			.required("La contraseña es obligatoria")
			.matches(
				/^(?=\w*\d)(?=\w*[A-Z]).{6,150}$/,
				"Las contraseñas deben tener al menos 6 caracteres y al menos una letra mayúscula (A-Z) y un número (0-9)."
			),
		confirmPassword: yup.string().when("password", {
			is: (val) => (val && val.length > 0 ? true : false),
			then: yup
				.string()
				.oneOf(
					[yup.ref("password")],
					"La contraseña y su confirmación no coinciden. Por favor verifique los valores introducidos."
				),
		}),
	});
	/**
	 *
	 * @returns {Component}
	 * @description Genera un componente formulario con la librería Formik que valida los valores User y Password
	 *
	 */

	const renderFormik = () => {
		return (
			<React.Fragment>
				{passwordModalToggle && (
					<ModalBox onToggle={() => {
						setPasswordToggleModal(false);
					}}>
						<div className="success-text">Contraseña cambiada, accede con tu nueva contraseña.</div>
					</ModalBox>
				)}
				<div className="top-container">
					<h1>Cambiar contraseña</h1>
				</div>
				<div className="flex-container">
					<div className="left">
						<div className="setPassword-requirements">
							<img className="warning-icon" src={iconWarning} alt="Icono alerta" />
							<p>Antes de empezar, debes cambiar la contraseña por una que tú elijas.</p>
							<p className="heavy-text">Tu nueva contraseña debe tener:</p>
							<ul className="requirements-list">
								<li>Mínimo 6 caracteres.</li>
								<li>Al menos, una letra mayúscula.</li>
								<li>Al menos, un número.</li>
							</ul>
						</div>
					</div>

					<Formik
						initialValues={{
							password: "",
							confirmPassword: "",
							acceptCondition: false,
							acceptPrivacy: false,
						}}
						validationSchema={
							state.user.isnewUser ? validationSchemaNewUser : validationSchemaOldUser
						}
						onSubmit={(values, actions) => {
							actions.resetForm();
							handleSubmit(values);
						}}
					>
						{(props) => (
							<Form
								onSubmit={(e) => {
									e.preventDefault();
									props.handleSubmit();
								}}
							>
								{/* <div className="set-password-container"> */}
								{renderErrors()}
								{/* Nueva contraseña input */}
								<div className="right">
									<div
										className="form-group"
										onClick={() => {
											if (errorMessage || errorStatus) {
												setErrorStatus(null);
												setErrorMessage(null);
											}
										}}
									>
										<InputBlock
											label="Introduce tu nueva contraseña"
											type="password"
											placeholder="*********"
											handleChange={props.handleChange}
											paramChange="password"
											values={props.values.password}
											handleBlur={props.handleBlur}
											paramBlur="password"
										/>
									</div>
									{props.touched.password && props.errors.password ? (
										<ErrorDialog text={props.errors.password} />
									) : null}

									{/* Confirmar contraseña */}

									<div
										className="form-group"
										onClick={() => {
											if (errorMessage || errorStatus) {
												setErrorStatus(null);
												setErrorMessage(null);
											}
										}}
									>
										<InputBlock
											label="Repite tu nueva contraseña"
											type="password"
											placeholder="*********"
											handleChange={props.handleChange}
											paramChange="confirmPassword"
											values={props.values.confirmPassword}
											handleBlur={props.handleBlur}
											paramBlur="confirmPassword"
										/>
										{props.touched.confirmPassword && props.errors.confirmPassword ? (
											<ErrorDialog text={props.errors.confirmPassword} />
										) : null}

										{/* Checkboxes */}

										{state.user.isnewUser && (
											<div className="checkboxes">
												<label className="legal-checkbox">
													<input
														type="checkbox"
														checked={props.values.acceptCondition}
														onChange={() =>
															props.setFieldValue("acceptCondition", !props.values.acceptCondition)
														}
													/>
													<span>
														He leído y acepto las{" "}
														<Link target="_blank" to="/intro/condiciones">
															Condiciones de uso
														</Link>
													</span>
												</label>

												{/* Aviso de aceptación de condiciones de uso */}
												{props.touched.acceptCondition && props.errors.acceptCondition ? (
													<ErrorDialog text={props.errors.acceptCondition} />
												) : null}

												<label className="legal-checkbox">
													<input
														type="checkbox"
														checked={props.values.acceptPrivacy}
														onChange={(e) => {
															window.localStorage.setItem('pa_privacy', !props.values.acceptPrivacy)
															props.setFieldValue("acceptPrivacy", !props.values.acceptPrivacy)
														}}
													/>
													<span>
														He leído y acepto la{" "}
														<Link target="_blank" to="/intro/privacidad">
															Política de privacidad
														</Link>
													</span>
												</label>
												{/* Aviso de aceptación de la política de privacidad */}

												{props.touched.acceptPrivacy && props.errors.acceptPrivacy ? (
													<ErrorDialog text={props.errors.acceptPrivacy} />
												) : null}
											</div>
										)}
									</div>
								</div>
								<ButtonMain
									label="Cambiar contraseña"
									iconClass="arrow-right-white"
									type="submit"
									action={props.handleSubmit}
									disabled={{ isSubmitting: props.isSubmitting, dirty: props.dirty }}
								/>
							</Form>
						)}
					</Formik>
				</div>
			</React.Fragment>
		);
	};

	/////////////////////////////
	// Renderizado del componente
	/////////////////////////////

	return (
		<div>
			<Card customClass="setpassword-card ">
				{!state.user?.isPasswordReset && !state.user?.isnewUser ? <ButtonBack /> : <br />}
				{renderFormik()}
				{renderAcceptConditionsModal()}
				{renderAcceptPrivacyModal()}
			</Card>
		</div>
	);
};

export default SetPassword;
