import React from "react";
import logo from "../../assets/images/lg-baviera-color.svg";

/**
 * 
 * @param {any} props 
 * @description Este es el layout que se renderizará en las rutas públicas. 
 * Es un componente puramente presentacional
 *   
 */


const SignInLayout = ({ children }) => {
  return (
    <div className="signIn-container">
      <div className="logo-container">
        <a href="/" className="logo">
          <img alt="Clínica Baviera" src={logo} />
        </a>
      </div>
      <div className="main-content">
        {children}
      </div>
    </div>
  );
};

export default SignInLayout;
