export const SET_FRIENDS_AND_PRIZES = "plan/SET_FRIENDS_AND_PRIZES";
export const DELETE_STATE = "plan/DELETE_STATE";
export const SET_HOTEL_LIST = "plan/SET_HOTEL_LIST";
export const SET_SPA_LIST = "plan/SET_SPA_LIST";
export const USER_HAS_PLAN = "plan/USER_HAS_PLAN";
export const SET_PRIVACY_TO_ACCEPTED = "plan/SET_PRIVACY_TO_ACCEPTED";

export const initialState = {
	hasAcceptedPrivacy: false,
	numberOfFriends: null,
	prizes: null,
	hasBeenUpdated: false,
	spaList: [],
	hotelList: [],
	isUserInPlan: false,
	referralUrl: null,
};

const planAmigoReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_PRIVACY_TO_ACCEPTED:
			let { isPrivacyAccepted } = action.data;

			return {
				...state,
				hasAcceptedPrivacy: isPrivacyAccepted,
			};

		case USER_HAS_PLAN:
			let { isUserInPlan } = action.data;
			return { ...state, isUserInPlan };

		case SET_FRIENDS_AND_PRIZES:
			let {
				numberOfFriends,
				prizes,
				friendsList,
				hasBeenUpdated,
				referralUrl = state.referralUrl,
			} = action.data;
			return { ...state, numberOfFriends, friendsList, prizes, hasBeenUpdated, referralUrl };

		case SET_HOTEL_LIST:
			return { ...state, hotelList: action.data };

		case SET_SPA_LIST:
			return { ...state, spaList: action.data };

		case DELETE_STATE:
			return { ...state, ...initialState };
		default:
			return state;
	}
};

export default planAmigoReducer;
