import React, { Suspense, useEffect, useState } from "react";
import ButtonMain from "../../../../components/ButtonMain/ButtonMain";
import ReportContent from "../../../../components/ReportContent/ReportContent";
import Loading from "../../../../components/Loading/Loading";
import { Form, Formik } from "formik";
import { useAuth } from "../../../../provider";
import ModalBox from "../../../../components/ModalBox/ModalBox";
import noFilesBackground from "../../../../assets/images/no-files.jpg";
import "./notes.scss";
import ErrorDialog from "../../../../components/ErrorDialog/ErrorDialog";

/**
 *
 * @param {Object} data Justificantes que se listarán en el renderizado
 * @param {Function} onSubmitAction
 * @param {Function} getReportPdf
 * @param {Function} setModalOpen
 * @param {Boolean} modalOpen
 * @param {Boolean} loading
 * @param {String} error
 *
 * @description Renderiza la lista de justificantes del
 * usuario al tiempo que emite hacia su padre (Reports.js)
 * los eventos de submit y de cerrar modal
 */

const Notes = ({ data, onSubmitAction, getReportPdf, modalOpen, setModalOpen, loading, error, reportTypes }) => {
	/////////////////////////////
	// Configuración del componente
	/////////////////////////////


	const { state } = useAuth();
	const openModal = () => setModalOpen(true);
	const [localReportTypes, setLocalReportTypes] = useState([]);

	useEffect(() => {
		setLocalReportTypes(mapReportTypes());
	}, []);

	const mapReportTypes = () => {
		let result = [];
		if(reportTypes){
			reportTypes.forEach((item) => {
				if (item.esJustificante) {
					result.push({
						label: item.Descripcion,
						value: item.IdTipoInforme,
						key: item.IdTipoInforme,
					});
				}
			});
		}
		return result;
	};

	/////////////////////////////
	// Funciones de renderizado
	/////////////////////////////

	const renderModal = () => {
		const LazyModal = React.lazy(() => import("../../../../components/ModalBox/ModalBox"));
		return (
			<Suspense
				fallback={
					<ModalBox>
						<Loading />
					</ModalBox>
				}
			>
				<LazyModal onToggle={setModalOpen}>
					{loading ? (
						<Loading />
					) : (
						<Formik
							initialValues={{
								idClinica: state.user.idClinica,
								idPaciente: state.user.idPaciente,
								idtipoinforme: reportTypes ? reportTypes[0]?.value : {},
							}}
							// validationSchema={validationSchema}
							onSubmit={(values, actions) => {
								actions.resetForm();
								onSubmitAction(values);
							}}
						>
							{(props) => {
								return (
									<React.Fragment>
										<h3>Solicitar justificante</h3>
										<p className="bottom-space">
											Desde aquí puedes solicitar los justificantes que necesites
										</p>
										<Form
											onSubmit={(e) => {
												e.preventDefault();
												props.handleSubmit(props.values);
											}}
											className="form-container"
										>
											{localReportTypes.map((type) => {
												return (
													<div className="form-control" key={type.key}>
														<label className="radio-label">
															<input
																type="radio"
																value={type.value}
																onChange={(e) => {
																	props.setFieldValue(
																		"idtipoinforme",
																		Number(e.target.value)
																	);
																}}
																checked={props.values.idtipoinforme === type.value}
															/>
															<span>{type.label}</span>
														</label>
													</div>
												);
											})}
											<br />
											<br />
											<ButtonMain label="Solicitar" type="submit" action={props.handleSubmit}/>
										</Form>
									</React.Fragment>
								);
							}}
						</Formik>
					)}
				</LazyModal>
			</Suspense>
		);
	};

	/**
	 * @description Si no hay ninguna fecha disponible se renderizará este contenido
	 */

	const renderEmpty = () => {
		return (
			<React.Fragment>
				<div className="empty-container">
					<img src={noFilesBackground} alt="" />
					<p className="no-content-info">En estos momentos no tienes justificantes disponibles.</p>
				</div>
			</React.Fragment>
		);
	};

	/**
	 * @description Renderizado de los elementos si no hay una petición exitosa
	 */

	const renderElements = () => {
		return (
			<React.Fragment>
				{modalOpen ? renderModal() : null}
				{data &&
					data.map((item, index) => {
						return (
							<ReportContent
								key={index}
								title={item.idJustificante}
								date={item.fecha}
								icon="note"
								buttonLabel="Descargar"
								buttonAction={()=>getReportPdf(item, true)}
								viewAction={()=> getReportPdf(item, false)}
								document={item}
								type={item.descripcion}
							/>
						);
					})}
			</React.Fragment>
		);
	};

	/////////////////////////////
	// Renderizado del componente
	/////////////////////////////

	const renderAll = () => {
		if (loading && !data) {
			return <Loading />;
		}
		if (data && data.length <= 0 && !loading && !error) {
			return renderEmpty();
		} else {
			return renderElements();
		}
	};

	return (
		<React.Fragment>
			<div className="request-report">
				{error ? <ErrorDialog text={error} /> : null}
				<p>Si lo necesitas puedes solicitar aquí tus justificantes</p>
				<ButtonMain label="Solicitar Justificante" action={openModal}></ButtonMain>
			</div>
			{renderAll()}
			{modalOpen ? renderModal() : null}
		</React.Fragment>
	);
};

export default Notes;
