import React, { Suspense, useState } from "react";
import DragAndDropFile from "../../../components/DragAndDropFile/DragAndDropFile";
import ButtonMain from "../../../components/ButtonMain/ButtonMain";
import "./fileUpload.scss";
import { useRef } from "react";
import ModalBox from "../../../components/ModalBox/ModalBox";
import Loading from "../../../components/Loading/Loading";
import { uploadFile, getFileList, getBase64File } from "../../../services/services";
import { useAuth } from "../../../provider";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import fileIcon from "../../../assets/images/icons/file-icon-g.svg";
import warningIcon from "../../../assets/images/icons/warning-color-icon.svg";
import successIcon from "../../../assets/images/icons/success-color-icon.svg";
import { useEffect } from "react";
import { useCustomUseLocation } from "../../../lastLocationProvider";
import ReportContent from "../../../components/ReportContent/ReportContent";
import {
	base64ToBlob,
	downloadFile,
	getFileExtension,
	viewFile,
} from "../../../helpers/helpers";

const FileUpload = () => {
	const inputRef = useRef();
	const [file, setFile] = useState(null);
	const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);
	const [successMessage, setSuccessMessage] = useState(null);
	const [sizeError, setSizeError] = useState(null);
	const [invalidFormatError, setInvalidFormatError] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [localFileList, setLocalFileList] = useState([]);
	const [isLoadingPastFiles, setIsLoadingPastFiles] = useState(false);
	const { state } = useAuth();
	const { deleteLastLocation, stateLocation } = useCustomUseLocation();

	// Se ejecuta al inciar el componente cargando la lista de archivos

	useEffect(() => {
		getUserFilesList();
		if (stateLocation.lastLocation) {
			deleteLastLocation();
		}
	}, []);

	/**
	 * Hace una llamada a la API para conseguir la lista de documentos subidos por el usuario.
	 *
	 */

	const getUserFilesList = async () => {
		setIsLoadingPastFiles(true);
		try {
			const data = {
				idClinica: state.user.idClinica,
				idPaciente: state.user.idPaciente,
			};
			const res = await getFileList(data);
			setLocalFileList(res);
			setIsLoadingPastFiles(false);
		} catch (error) {
			setIsLoadingPastFiles(false);
			console.log(error);
		}
	};

	const onFileDrop = (fileRaw) => {
		if (!fileRaw[0]) return;
		setErrorMessage(null);
		const fileSizeInMB = (fileRaw[0].size / (1024 * 1024)).toFixed(2);
		const acceptedImageTypes = ["image/jpeg", "image/png", "application/pdf"];
		const isFormatValid = acceptedImageTypes.includes(fileRaw[0].type);

		if (fileSizeInMB >= 5) {
			setSizeError("El archivo no puede ser mayor que 5MB");
			return;
		} else if (!isFormatValid) {
			setInvalidFormatError("El formato del archivo no es válido");
			return;
		}
		setFile(fileRaw[0]);
	};

	const triggerInputClick = () => {
		setFile(null);
		inputRef.current.click();
	};

	const onUploadFile = async () => {
		try {
			setIsLoadingModalOpen(true);
			const res = await uploadFile(state.user, file);
			if (res.status === 201) {
				setIsLoadingModalOpen(false);
				setFile(null);
				setSuccessMessage("Tu archivo se ha subido correctamente");
				await getUserFilesList();
			} else {
				setIsLoadingModalOpen(false);
				setFile(null);
				setErrorMessage("Ocurrió un error inesperado");
			}
		} catch (error) {
			setIsLoadingModalOpen(false);
			setErrorMessage(error.message);
			setFile(null);
		}
	};

	const onFileDownload = async (id, fileName, isDownload) => {
		try {
			setIsLoadingModalOpen(true);
			const data = {
				idClinica: state.user.idClinica,
				idPaciente: state.user.idPaciente,
				idArchivo: id,
			};

			const res = await getBase64File(data);
			if (res) {
				setIsLoadingModalOpen(false);
				const base64 = res.content;
				const fileExt = getFileExtension(fileName);
				if (isDownload) {
					downloadFile(base64, data.idArchivo, fileExt);
				} else {
					if (fileExt === "pdf") {
						base64ToBlob(base64);
					} else {
						viewFile(base64, fileExt);
					}
				}

				// openInNewTab(base64, fileName, fileExt);
			}
		} catch (error) {
			setIsLoadingModalOpen(false);
			setErrorMessage(error.message);
			setFile(null);
		}
	};

	const renderLoadingModal = () => {
		const LoadingModal = React.lazy(() => import("../../../components/ModalBox/ModalBox"));

		return (
			<Suspense
				fallback={
					<ModalBox>
						<Loading />
					</ModalBox>
				}
			>
				<LoadingModal hasCloseButton={true}>
					<Loading />
				</LoadingModal>
			</Suspense>
		);
	};

	const clearSuccessMessage = () => setSuccessMessage(null);

	const renderSuccesModal = () => {
		const SuccessModal = React.lazy(() => import("../../../components/ModalBox/ModalBox"));
		return (
			<Suspense
				fallback={
					<ModalBox>
						<Loading />
					</ModalBox>
				}
			>
				<SuccessModal onToggle={clearSuccessMessage}>
					<div className="modal-file-icon">
						<img src={successIcon} alt="Subida correcta" />
					</div>
					<p className="modal-file-text">{successMessage}</p>
				</SuccessModal>
			</Suspense>
		);
	};

	const renderUploadErrorModal = (uploadError, clearUploadError) => {
		const ErrorModal = React.lazy(() => import("../../../components/ModalBox/ModalBox"));

		return (
			<Suspense
				fallback={
					<ModalBox>
						<Loading />
					</ModalBox>
				}
			>
				<ErrorModal onToggle={clearUploadError}>
					<div className="modal-file-icon">
						<img src={warningIcon} alt="Subida incorecta" />
					</div>
					<p className="modal-file-text">{uploadError}</p>
				</ErrorModal>
			</Suspense>
		);
	};

	const renderErrors = () => <ErrorHandler errorStatus={401} />;

	return (
		<React.Fragment>
			{isLoadingModalOpen && renderLoadingModal()}
			{errorMessage && renderErrors()}
			{successMessage && renderSuccesModal()}
			{sizeError && renderUploadErrorModal(sizeError, setSizeError)}
			{invalidFormatError && renderUploadErrorModal(invalidFormatError, setInvalidFormatError)}
			<div className="drag-drop-container">
				<p>Sube un documento arrastrándolo a la imagen o pinchando en el botón</p>
				<DragAndDropFile onFileDrop={onFileDrop} setFile={setFile}>
					<div className="upload-file">
						<p>o</p>
						<ButtonMain
							iconClass="upload-file-white"
							label={"Añade un archivo"}
							action={triggerInputClick}
						></ButtonMain>
						<input ref={inputRef} type="file" hidden onChange={(e) => onFileDrop(e.target.files)} />
					</div>
					{file ? (
						<div className="file-uploaded">
							<img src={fileIcon} alt="" />
							<p>{file.name}</p>
							<ButtonMain
								iconClass="upload-file-white"
								label="Enviar"
								action={onUploadFile}
								customClass="small-button"
							/>
						</div>
					) : null}
				</DragAndDropFile>
				<div className="files-list-container">
					{!isLoadingPastFiles ? (
						localFileList.map((file, index) => {
							return (
								<ReportContent
									buttonAction={() => onFileDownload(file.idArchivo, file.nombreArchivo, true)}
									hasViewIcon={true}
									viewAction={() => onFileDownload(file.idArchivo, file.nombreArchivo, false)}
									buttonLabel={"Descargar"}
									document={file}
									key={index}
									subtitle={file.nombreArchivo}
									title={`Archivo num. ${file.idArchivo}`}
									icon={"note"}
									date={file.fecha}
									hasNoDownload={false}
								/>
							);
						})
					) : (
						<Loading />
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

export default FileUpload;
