import React from "react";
import Card from "../../../components/Card/Card";
import logo from "../../../assets/images/lg-baviera-color.svg";
import "./privacy.scss";

const PlanAmigoPrivacy = ({ onClose }) => {
  return (
    <div className="legal-info">
      <div className="logo-container">
        <a href="/" className="logo">
          <img alt="Clínica Baviera" src={logo} />
        </a>
      </div>
      <Card>
        <h1>Política de Privacidad Área del Paciente</h1>
        <h2>¿Quién es el Responsable del tratamiento?</h2>
        <p>
          CLINICA BAVIERA, S.A. (en adelante, CB), con CIF A80240427 y domicilio
          en Paseo de la Castellana 20, 28046, Madrid, será la responsable del
          tratamiento de los datos personales facilitados a través de la
          presente página web, así como de los facilitados telefónicamente o
          mediante la cumplimentación de cualquier formulario de clínica Baviera
          albergado en alguna otra página web.
        </p>
        <p>
          Puede ponerse en contacto con nuestro Delegado de Protección de Datos
          (DPD) mediante escrito dirigido a DPD@clinicabaviera.com o en el
          siguiente número de teléfono: 91 781 98 80.
        </p>
        <h2>
          ¿Con qué finalidad y en atención a que base legitimadora tratamos sus
          datos personales?
        </h2>
        <p>
          En el supuesto en el que Usted cumplimente alguno de los siguientes
          formularios habilitados en esta página Web, trataremos sus datos
          personales para atender su solicitud de información o sugerencias,
          siendo la base legitimadora de dicho tratamiento el consentimiento
          prestado por su parte al cumplimentar y enviar libre y voluntariamente
          el citado formulario:
        </p>
        <ul>
          <li>Formulario "Infórmate sin compromiso"</li>
          <li>Formulario "Te llamamos nosotros"</li>
          <li>Formulario "Pacientes internacionales"</li>
          <li>
            Formulario "Compañías aseguradoras/Solicita información sobre tu
            convenio"
          </li>
          <li>Formulario "Recibe información sobre nuestras promociones"</li>
          <li>Formulario "Área de prensa"</li>
          <li>Formulario "Atención al paciente"</li>
          <li>Formulario “Blog” sobre novedades y consejos de salud visual.</li>
        </ul>
        <h3>Cita online</h3>
        <p>
          Cuando cumplimente el formulario “Cita online” habilitado en el área
          de paciente de esta página Web trataremos sus datos personales para
          gestionar su solicitud para mantener una cita médica en una de
          nuestras clínicas, siendo la base legitimadora de dicho tratamiento el
          consentimiento prestado por su parte al cumplimentar y enviar dicho
          formulario.
        </p>
        <p>
          Le informamos que, en caso de que solicite una cita online, se le dará
          la posibilidad de cumplimentar un formulario cuya finalidad es
          agilizar el proceso de alta como paciente en nuestra clínica y
          acreditar su identidad. CB tratará los datos facilitados mediante
          dicho formulario en atención a su consentimiento, entendiéndose éste
          prestado por la libre cumplimentación del citado formulario, mientras
          que trataremos su DNI en atención a nuestro interés legítimo en
          acreditar su identidad o la del menor del que ostenta la patria
          potestad o tutela. En caso de que no cumplimente dicho formulario o no
          remita la documentación que se le solicite, el proceso de alta y
          verificación de identidad se realizará cuando acuda a nuestras
          instalaciones. En caso de que cumplimente dicho formulario y si Usted
          o, en su caso, el menor del que usted ostenta la patria potestad o
          tutela acude a la cita médica solicitada, dichos datos serán
          incorporados a su historia clínica, tratándolos con la finalidad de
          poder prestarle los servicios y tratamientos médicos contratados,
          gestionar y coordinar su historia clínica, gestionar la relación
          contractual mantenida con nuestra clínica, así como cumplir con
          cuantas obligaciones legales nos correspondan. En este sentido, CB
          llevará a cabo estos tratamientos en atención a la ejecución de la
          relación contractual que Usted formalice con la misma y en
          cumplimiento de cuantas obligaciones legales nos correspondan.
          Asimismo, en atención a nuestro interés legítimo, CB tratará sus datos
          personales, incluyendo, la comunicación de los mismos entre las
          compañías de nuestro grupo, con fines administrativos internos, con la
          finalidad de llevar a cabo nuestra gestión interna.
        </p>
        <h3>Videoconsulta</h3>
        <p>
          Por otro lado, si Usted cumplimenta el formulario “Pide tu
          videoconsulta gratis“habilitado en el área del paciente de esta página
          Web o en cualquier otra página Web, CB tratará sus datos personales
          para contactar con Usted para concretar fecha y hora en la que
          mantener una videoconsulta. Una vez concretada, se le enviará un
          correo electrónico de confirmación que contendrá un enlace a través
          del cual podrá conectarse a la videoconsulta, así como un SMS
          recordatorio. Le informamos que los datos que nos facilite en el
          transcurso de dicha videoconsulta, incluyendo datos de salud, serán
          tratados por CB para atender su consulta. La base legitimadora de
          dicho tratamiento es el consentimiento otorgado por usted al
          cumplimentar y enviar libremente el citado formulario y participar en
          la videoconsulta.
        </p>
        <p>
          En caso de que Usted o el menor del que ostenta la patria potestad o
          tutela ya sea paciente de CB, la videoconsulta será atendida por un
          profesional médico, mientras que si aún no lo es le atenderá un
          comercial u óptico que podrá asesorarle adecuadamente.
        </p>
        <p>
          En caso de que Usted o el menor del que ostente la patria potestad o
          tutela ya sea paciente de CB, los datos personales que nos facilite o
          que nuestros profesionales recaben o generen en el transcurso de la
          correspondiente videoconsulta, incluyendo sus datos de salud, serán
          incorporados en su historia clínica o en la del citado menor,
          tratándolos de conformidad con la información que le facilitamos
          cuando se dio de alta como paciente en CB y que puede consultar en
          cualquier momento en cualquiera nuestras clínicas. En este sentido, le
          recordamos que CB trata los datos incorporados en su historia clínica
          en atención a la ejecución de la relación contractual que Usted haya
          formalizado con la misma y en cumplimiento de cuantas obligaciones
          legales nos correspondan.
        </p>
        <p>
          Asimismo, le informamos que, en caso de que Usted o el citado menor ya
          sea paciente de CB, ésta podrá comunicar sus datos personales o los
          menor, incluido el informe médico o el resultado de la asistencia
          recibida en la videoconsulta en la que participe (i) al médico externo
          por parte del cual Usted o dicho menor venga derivado, en atención al
          contrato suscrito entre usted y el citado médico externo; (ii) al
          centro de reconocimiento de conductores por el cual usted pueda venir
          derivado, en atención al contrato suscrito entre usted y dicho centro;
          (iii) a la mutua colaboradora con la seguridad social por parte de la
          cual Usted pueda venir derivado, en atención a nuestro interés
          legítimo en gestionar la relación contractual entre CB y la
          correspondiente mutua, mantenida en virtud de lo establecido por la
          Ley General de la Seguridad Social; (iv) a la compañía de seguros de
          la que usted o el menor del que usted ostenta la patria potestad o
          tutela sea asegurado o cliente, con la finalidad exclusiva de
          gestionar con aquellas la prestación de los servicios objeto de
          cobertura o para la aplicación de los descuentos correspondientes y
          facturar el gasto sanitario de conformidad con el artículo 99 de la
          Ley 20/2015, de 14 de julio, de ordenación, supervisión y solvencia de
          las entidades aseguradoras y reaseguradoras; (v) a las autoridades
          sanitarias, judiciales o a la administración tributaria si fueran
          requeridos por dichas autoridades en el marco de una investigación,
          inspección, procedimiento o similar, en los casos previstos legalmente
          siempre y cuando dicho requerimiento se encuentre debidamente
          justificado y fuera necesario para la finalidad de la misma.
        </p>
        <p>
          Usted confirma y garantiza la veracidad y exactitud de los datos
          aportados y que éstos se ajustan a su estado actual o al estado actual
          del menor del que ostenta la patria potestad o tutela.{" "}
        </p>
        <p>
          Asimismo, le informamos que las citadas “videoconsultas” se realizarán
          a través de Topbox, de manera que Nexmo Limited, como titular de dicha
          herramienta, podrá tener acceso a los datos personales facilitados en
          el marco de la videoconsulta en la que Usted o el menor del que
          ostente la patria potestad o tutela participe, como consecuencia de
          los servicios que CB le ha contratado. Nexmo Limited es una compañía
          ubicada en Canadá que, a su vez, tiene subcontratados proveedores de
          servicios fuera del Espacio Económico Europeo, produciéndose, por
          tanto, una transferencia internacional de los citados datos. Dichas
          transferencias internacionales cumplen con las garantías adecuadas,
          habiendo suscrito Nexmo Limited las correspondientes cláusulas
          contractuales tipo.
        </p>
        <p>
          CB no se hace responsable del tratamiento que Nexmo Limited pueda
          hacer del resto de datos que ésta pueda recabar directamente como
          consecuencia del uso por su parte de Topbox en la medida en que los
          servicios contratados por CB para prestarle a Usted el servicio de
          videoconsultas no requieren que Nexmo Limited realice ningún
          tratamiento adicional al necesario para la realización de las
          videoconsultas.
        </p>
        <h3>Área del Paciente</h3>
        <p>
          Por otro lado, en caso de que solicite su registro o el del menor del
          que usted ostenta la patria potestad o tutela en el “Área del
          paciente”, a través del formulario "Área Paciente", CB tratará los
          datos personales facilitados mediante la cumplimentación del mismo
          para gestionar el alta y acceso a dicha Área y a nuestra Aplicación
          “Clínica Baviera” (si se la descarga), así como para prestarle los
          servicios solicitados a través de dicha Área y gestionar su relación
          con CB. CB tratará dichos datos en atención a su consentimiento,
          entendiéndose éste prestado mediante la libre cumplimentación del
          formulario habilitado.
        </p>
        <h3>Plan Amigo</h3>
        <p>
          En el caso de que Usted sea un paciente de CB operado en alguna de
          nuestras clínicas podrá participar en el Plan Amigo, a través del
          “Área del Paciente” o de otras web de CB, mediante la cumplimentación
          del formulario de participación. CB tratará los datos facilitados
          mediante dicho formulario en atención a su consentimiento,
          entendiéndose éste prestado por la libre cumplimentación del citado
          formulario, mientras que trataremos su DNI en atención a nuestro
          interés legítimo en acreditar su identidad o la del menor del que
          ostenta la patria potestad o tutela. Dentro del Plan Amigo se le
          solicitarán los datos relativos al amigo al que quiere invitar a
          conocer CB, por lo que será necesario que previamente Usted cuente con
          el consentimiento o autorización de aquél para la comunicación de sus
          datos a CB y que le haya informado del funcionamiento del Plan Amigo.
          Clínica Baviera únicamente tratará dichos datos con la finalidad de
          gestionar su participación y la del citado Amigo, de conformidad con
          las bases legales y mecánica de dicho programa. CB únicamente conocerá
          la identidad de “su amigo” cuando éste cumplimente el formulario que
          le remitiremos por correo electrónico, para poder concertar con él una
          visita.{" "}
        </p>
        <p>
          Asimismo, en caso de que Usted cumplimente voluntariamente el
          cuestionario que encontrará en la “pantalla de gestión” del citado
          Plan Amigo, CB tratará los datos facilitados a través del mismo con la
          finalidad ir adaptando la escalera de premios que se ofrecerán por
          participar en dicho Plan a todos los usuarios. El tratamiento de sus
          datos personales con esta finalidad se basará en el consentimiento
          prestado por su parte al cumplimentar, de manera voluntaria y
          opcional, el citado cuestionario.
        </p>
        <p>
          Envío de comunicaciones comerciales Adicionalmente, siempre que así lo
          consienta expresamente, sus datos podrán ser tratados con la finalidad
          de remitirle por cualquier medio, incluido por medios electrónicos,
          información comercial y publicitaria de los servicios ofrecidos por
          CB. Envío de comunicaciones comerciales
        </p>
        <h3>¿Por cuánto tiempo conservaremos sus datos?</h3>
        <p>Los datos personales tratados con la finalidad de atender su solicitud de información, cita médica o sugerencias serán tratados durante el tiempo necesario para atender dicha solicitud o sugerencia. En caso de que Ud. haya consentido el envío de comunicaciones comerciales por parte de CB, sus datos serán tratados con esta finalidad mientras usted no revoque su consentimiento.</p>
        <p>
        En caso de que mantenga una videoconsulta con uno de nuestros profesionales (médicos o no) conservaremos sus datos personales hasta que acuda a una de nuestras clínicas, con el fin de que el profesional médico que allí le atienda conozca los motivos de su visita y, en todo caso, si usted o el menor del que usted ostenta la patria potestad o tutela ya es paciente de CB, conservaremos los datos personales que pasen a formar parte de su historia clínica o la del citado menor el plazo durante el cual nos obligue la legislación aplicable en materia sanitaria.
        </p>
        <p>Por otro lado, los datos facilitados mediante la cumplimentación del formulario para darse de alta en el “Área del paciente” serán tratados y conservados mientras no solicite su baja de dicha área. </p>
        <p>
        En caso de que Usted participe en el Plan Amigo, CB únicamente tratará los datos personales relativos a su amigo facilitados mediante la cumplimentación del formulario de participación, hasta que aquél reciba el correo electrónico invitándole a participar.
        </p>
        <p>Una vez transcurridos los plazos de conservación indicados, los datos ser conservarán debidamente bloqueados para su puesta a disposición a jueces y tribunales, Ministerio Fiscal o Administraciones Públicas competentes, en particular de las autoridades de protección de datos, para la exigencia de posibles responsabilidades derivadas de su tratamiento y solo por el plazo de prescripción de las mismas. Transcurrido ese plazo se procederá a su destrucción.</p>
        <h3>¿Obligatoriedad de facilitar los datos personales que se le solicitan?</h3>
        <p>Los datos personales que se le solicitan en cada formulario son los estrictamente necesarios para poder atender su solicitud, registro o participación, de manera que en caso de no facilitarlos no podremos atender adecuadamente su solicitud, registro o participación.</p>
        <h3>¿Cuáles son sus derechos?</h3>
        <p>Todo interesado puede revocar el consentimiento, en su caso, prestado, así como ejercer sus derechos de acceso, rectificación, supresión, oposición, portabilidad y limitación, mediante escrito dirigido al Departamento de Asesoría Jurídica de Clínica Baviera, S.A., sito en Paseo de la Castellana 20, 28046, Madrid o a datospersonales@grupobaviera.es, acreditando debidamente su identidad. Igualmente puede presentar, cuando lo considere oportuno, una reclamación ante la Agencia Española de Protección de Datos, especialmente, si considera infringidos sus derechos.</p>
      </Card>
    </div>
  );
};

export default PlanAmigoPrivacy;
