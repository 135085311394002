import React, { useMemo, useReducer, useContext } from "react";
import locationReducer, { SET_LAST_LOCATION, DELETE_LAST_LOCATION } from "./locationReducer";

const LastLocationContext = React.createContext();

const LastLocationProvider = (props) => {
	// SET LAST LOCATION
	const [stateLocation, dispatch] = useReducer(locationReducer, {});

	const setLastLocation = async (locationString) => {
		try {
			const lastLocation = locationString.split("/").includes("Informes")
				? "/Paciente/Informes"
				: locationString;

			dispatch({
				type: SET_LAST_LOCATION,
				lastLocation,
			});
		} catch (error) {
			throw new Error(error);
		}
	};

	const deleteLastLocation = async () => {
		try {
			dispatch({
				type: DELETE_LAST_LOCATION,
			});
		} catch (error) {}
	};

	const value = useMemo(() => {
		return {
			stateLocation,
			setLastLocation,
			deleteLastLocation,
		};
	}, [stateLocation]);

	return (
		<LastLocationContext.Provider value={value}>{props.children}</LastLocationContext.Provider>
	);
};

const useCustomUseLocation = () => useContext(LastLocationContext);
export { LastLocationContext, useCustomUseLocation };
export default LastLocationProvider;
