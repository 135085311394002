import React from "react";
import recomend from "../../../../assets/images/recomend-friend-plan.jpg";
import selectGift from "../../../../assets/images/select-gift-friend-plan.jpg";
import DiscoverCard from "./DiscorverCard/DiscoverCard";
import "./discover.scss";

/**
 * Componente que renderiza el contenido de la ruta /Paciente/Plan/Descubre-plan-amigo
 */

const Discover = ({data}) => {
	/////////////////////////////
	// Configuración del componente
	/////////////////////////////
	const configs = {
		steps: [
			{
				title: "1. Invita a tus amigos",
				text: data.invita,
				url: "/Paciente/Plan/Recomienda-clinica-baviera-tus-amigos",
				urlLabel: "Invita a tus amigos",
				image: recomend,
				imageAlt: "Imagen de prueba",
			},
			{
				title: "2. Selecciona tu premio",
				text: data.selecciona,
				// image:
				url: "/Paciente/Plan/Premios",
				urlLabel: "Ver premios",
				image: selectGift,
				imageAlt: "Imagen de prueba",
			},
		],
	};

	/////////////////////////////
	// Renderizado del componente
	/////////////////////////////

	return (
		<React.Fragment>
			<div className="steps-container">
				<h2>Descubre el Plan Amigo</h2>
				{configs.steps.map((step, index) => {
					return <DiscoverCard {...step} key={index} />;
				})}
			</div>
		</React.Fragment>
	);
};

export default Discover;
