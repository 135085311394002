import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./provider";
import LastLocationProvider from "./lastLocationProvider";
import PlanAmigoProvider from "./plan-amigo-provider";

ReactDOM.render(
	<React.StrictMode>
		<LastLocationProvider>
			<AuthProvider>
				<PlanAmigoProvider>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</PlanAmigoProvider>
			</AuthProvider>
		</LastLocationProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
