import React, { useEffect, useRef, useState } from 'react';
import * as pdfjs from 'pdfjs-dist/es5/build/pdf';
import { saveAs } from 'file-saver';
import ButtonMain from '../ButtonMain/ButtonMain';
import ModalBox from '../ModalBox/ModalBox';

const PDFViewer = ({ base64String, fileName, onClose }) => {
    const canvasRef = useRef(null);
    const [viewportWidth, setViewportWidth] = useState(0);
    const [viewportHeight, setViewportHeight] = useState(0);
    useEffect(() => {
        const loadingTask = pdfjs.getDocument({ data: atob(base64String) });

        loadingTask.promise.then(pdf => {
            console.log('PDF loaded');

            // Obtener la primera página
            pdf.getPage(1).then(page => {
                console.log('Page loaded');

                // Obtener el tamaño disponible
                const parentWidth = canvasRef.current.parentElement.offsetWidth;
                const scale = parentWidth / page.getViewport({ scale: 1 }).width;
                const viewport = page.getViewport({ scale });
                setViewportWidth(viewport.width);
                setViewportHeight(viewport.height);
                // Actualizar el tamaño del canvas
                const canvas = canvasRef.current;
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                const pixelRatio = 1;
                canvas.width = viewport.width * pixelRatio;
                canvas.height = viewport.height * pixelRatio;
                canvas.style.width = `${viewport.width}px`;
                canvas.style.height = `${viewport.height}px`;

                // Renderizar PDF en el canvas
                const context = canvas.getContext('2d');
                context.scale(pixelRatio, pixelRatio); // Escalar el contexto para la densidad de píxeles

                const renderContext = {
                    canvasContext: context,
                    viewport: viewport
                };
                const renderTask = page.render(renderContext);
                renderTask.promise.then(() => {
                    console.log('Page rendered');
                });

                
            });
        }, reason => {
            console.error(reason);
        });
    }, [base64String]);

    const downloadPDF = () => {
        try {
            // Decodifica el base64 y crea un Uint8Array a partir del string
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            const blob = new Blob([byteArray], { type: 'application/pdf' });

            // Guarda el Blob como un archivo con el nombre proporcionado
            saveAs(blob, fileName);

        } catch (error) {
            console.error('Error al descargar el PDF:', error);
        }
    };

    return (
        <ModalBox onToggle={() => onClose()}>
            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                <canvas ref={canvasRef} width={viewportWidth} height={viewportHeight} style={{ maxWidth: '100%' }}></canvas>
                <div 
                    style={{ zIndex: 1000 }}
                >
                <ButtonMain
                    label={'Descargar'}
                    iconClass="download-white"
                    type="button"
                    action={async () => {
                        downloadPDF(base64String, fileName, "pdf");
                    }}
                />
                </div>
                
            </div>

        </ModalBox>
    );
};

export default PDFViewer;
