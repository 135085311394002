import React, { useEffect, useState } from "react";
import IframeComponent from "../../../components/Iframe/Iframe";
import Card from "../../../components/Card/Card";
import { EMBEDDED_VIDEO_URL } from "../../../constants";
import logo from "../../../assets/images/lg-baviera-color.svg";
import "./videoconference.scss";
import { Suspense } from "react";
import ModalBox from "../../../components/ModalBox/ModalBox";
import ButtonMain from "../../../components/ButtonMain/ButtonMain";
import ButtonBack from "../../../components/ButtonBack/ButtonBack";
import Loading from "../../../components/Loading/Loading";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../provider";

const VideoConference = (props) => {
  /////////////////////////////
  // Configuración del componente
  /////////////////////////////

  const [isNoDevices, setIsNoDevices] = useState(false);
  const [isNoPermissions, setIsNoPermissions] = useState(false);
  const [stream, setStream] = useState(null);

  const { state } = useAuth();

  const history = useHistory();

  const urlEmbedded = `${EMBEDDED_VIDEO_URL}${props.match.params.room}`;
  useEffect(() => {
    if(stream)
      return () => stream.getTracks().forEach((track) => track.stop());
  }, [stream]);

  useEffect(() => {
    renderIframe();
  }, []);

  const goBack = () => history.goBack();

  /////////////////////////////
  // Funciones de renderizado
  /////////////////////////////

  /**
   * @description Con Javascript nativo se comprueba si el usuario tiene micrófono y webcam
   * Si el usuario no tiene ninguno de los dos dispositivos se setea isNoPermissions para que
   * se genere un error.
   *
   * Si cuenta con los dispositivos se comprueba si los permisos están activados. Si no lo están
   * se setea isNoPermissions a true para generar un error.
   *
   * En caso contrario se setea stream igual a mediaStream 
   *
   *
   */

  const renderIframe = async () => {
    var p = navigator.mediaDevices.getUserMedia({ audio: true, video: true });

    return p
      .then(function (mediaStream) {
        setStream(mediaStream);
        if (mediaStream.active !== true) setIsNoPermissions(true);
      })
      .catch(function (err) {
        setIsNoPermissions(true);
      });
  };

  const renderNoDevicesModal = () => {
    const LazyModal = React.lazy(() => import("../../../components/ModalBox/ModalBox"));

    return (
      <Suspense
        fallback={
          <ModalBox>
            <Loading />
          </ModalBox>
        }
      >
        <LazyModal onToggle={setIsNoDevices} hasCloseButton={true}>
          <h3>No dispones de cámara o micrófono</h3>

          <ButtonMain label="Aceptar" type={"button"} action={goBack} />
        </LazyModal>
      </Suspense>
    );
  };

  const renderNoPermissionsModal = () => {
    const LazyModal = React.lazy(() => import("../../../components/ModalBox/ModalBox"));

    return (
      <Suspense
        fallback={
          <ModalBox>
            <Loading />
          </ModalBox>
        }
      >
        <LazyModal onToggle={setIsNoDevices} hasCloseButton={true}>
          <h3>
            No hay permisos de micrófono y cámara o no dispones de estos periféricos para realizar la videoconsulta
          </h3>

          <ButtonMain label="Aceptar" type={"button"} action={goBack} />
        </LazyModal>
      </Suspense>
    );
  };

  /////////////////////////////
  // Renderizado del componente
  /////////////////////////////

  return (
    <div className="videocall">
      <div className="logo-container">
        <a href="/" className="logo">
          <img alt="Clínica Baviera" src={logo} />
        </a>
        {isNoDevices ? renderNoDevicesModal() : null}
        {isNoPermissions ? renderNoPermissionsModal() : null}
      </div>
      
      <div className="main-content">
        <Card>
        <ButtonBack />
          {!isNoDevices && !isNoPermissions ? (
            <IframeComponent
              src={urlEmbedded}
              frameBorder="0"
              allow="microphone; camera"
              allowFullScreen
              title="video"
              width="1024"
              height="600"
            />
          ) : null}
        </Card>
      </div>
    </div>
  );
};

export default VideoConference;
