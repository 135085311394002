import React from "react";
import Tab from "./Tab/Tab";
import "./reportTabs.scss";
import Updating from "../../../../components/Updating/Updating";

/**
 * 
 * @param {Boolean} isRefreshing 
 * @param {*} childrenComp 
 * @param {Object} activeTab 
 * @param {Boolean} loading 
 * @param {Function} onClickTabItem
 * @param {*} children
 * @param {Function} setError 
 * 
 * @description Tabs que se muestran en la ruta /Pacientes/Informes
 * Reciben los datos necesarios para renderizar cada una de las tabs 
 * y pasa las props a éstas
 * 
 */

const ReportTabs = ({isRefreshing, childrenComp, activeTab, loading, onClickTabItem, children, setError}) => {
  return (
    <div className="tabs-container">
     {!loading && <Updating isRefreshing={isRefreshing} />} 
      <ul className="tab-list">
        {childrenComp.map((tab, index) => {
          return (
            <li className="tab-container" key={index}>
              <Tab
                label={tab.label}
                activeTab={activeTab}
                handleClick={onClickTabItem}
                url={tab.url}
                setError={setError}
              ></Tab>
            </li>
          );
        })}
      </ul>

      <div className="tab-content reports">{children}</div>
    </div>
  );
};

export default ReportTabs;
