import React, { useState } from "react";
import { createPortal } from "react-dom";
import Iframe from 'react-iframe';

const IframeComponent = ({ children, ...props }) => {
  const [contentRef, setContentRef] = useState(null);
  const mountNode = contentRef?.contentWindow?.document?.body;
  
  

  return (
    <Iframe 
        url= {props.src}
        width={props.width}
        height={props.height}
        frameBorder="0"
        id="myId"
        allow = "camera; microphone;"
        className="myClassname"
        display="initial"
        position="relative"/>
  );
};

export default IframeComponent;
