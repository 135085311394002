import React from "react";
import moment from "moment";
import "./dateBox.scss";
/**
 * 
 * @param {Object} item Contendrá las propiedades de una fecha
 * @param {function} onClick Define el comportamiento del componente una vez pulsado
 * 
 * @description Componente que genera un card en la 
 * que se renderiza la información relativa a una fecha. 
 * Al pulsar en este card esta fecha se selecciona.
 */

const DateBox = ({ item, onClick }) => {
  const getWeekDay = () => {
    switch (moment(item.date).day()) {
      case 1:
        return "Lunes";
      case 2:
        return "Martes";
      case 3:
        return "Miércoles";
      case 4:
        return "Jueves";
      case 5:
        return "Viernes";
      case 6:
        return "Sábado";
      case 7:
        return "Domingo";
      default: 
        return "Lunes"  
    }
  };

  const clickHandler = () => onClick(item);

  return (
    <div onClick={clickHandler} className={`date-box ${item.selected ? "date-selected" : ""}`}>
      <p className="day-number">{moment(item.date).format("DD")}</p>
      <p className="day-name">{getWeekDay()}</p>
    </div>
  );
};

export default DateBox;
