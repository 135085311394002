import React from "react";
import { useAuth } from "../../../../../provider";
import { Form, Formik } from "formik";
import * as yup from "yup";

import InputBlock from "../../../../../components/InputBlock/InputBlock";
import ButtonMain from "../../../../../components/ButtonMain/ButtonMain";
import iconWarning from "../../../../../assets/images/icons/warning-color-icon.svg";
import "./changePassword.scss";
import ErrorDialog from "../../../../../components/ErrorDialog/ErrorDialog";
import ErrorHandler from "../../../../../components/ErrorHandler/ErrorHandler";

/**
 *
 * @param {Function} onSubmitChangePassword Función que se ejecuta cuando se hace un submit del formulario de cambio de password
 * @param {String} errorMessage Texto de error del mensaje
 * @param {Function} setErrorMessage Función que setea el texto del mensaje.
 * Es utilizado para setear el error a null cuando se interactúa con el formulario
 *
 * @description Este componente se encarga de renderizar el formulario de cambio de password.
 * La gestión del submit se realiza en el padre {Settings}.
 *
 *
 */

const ChangePassword = ({ onSubmitChangePassword, errorMessage, setErrorMessage }) => {
  /////////////////////////////
  // Configuración del componente
  /////////////////////////////

  const { state } = useAuth();

  /**
   * @description Esquema de validación de yup
   */

  const reviewSchema = yup.object({
    currentPassword: yup.string().required("La contraseña es obligatoria"),
    password: yup
      .string()
      .required("La contraseña es obligatoria")

      // La siguiente expresión regular indica que se requiere: 
      //  -algún caracter numérico ***(?=\w*\d)***
      //  -algún caracter alfabético en mayúsculas ***(?=\w*[A-Z])***
      //  -entre 6 y 8 caracteres. ***{6,8}***

      .matches(
        /^(?=\w*\d)(?=\w*[A-Z]).{6,150}$/,
				"Las contraseñas deben tener al menos 6 caracteres y al menos una letra mayúscula (A-Z) y un número (0-9)."
			),
    confirmPassword: yup.string().when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf(
          [yup.ref("password")],
          "La contraseña y su confirmación no coinciden. Por favor verifique los valores introducidos."
        ),
    }),
  });

  /////////////////////////////
  // Gestión de eventos y servicios
  /////////////////////////////

  /**
   * @description Renderizado de errores provenientes del servidor
   */

  const renderServerErrors = () => (errorMessage ? <ErrorHandler errorMessage={errorMessage} /> : null);

  /**
   * @description Renderizado del componente
   */

  return (
    <React.Fragment>
      <div className="change-password-account">
        <div className="top-container">
          <h1>Cambiar contraseña</h1>
        </div>
        <div className="flex-container">
          <div className="left">
            <div className="setPassword-requirements">
              <img className="warning-icon" src={iconWarning} alt="Icono alerta" />
              <p className="ninja">Antes de empezar, debes cambiar la contraseña por una que tú elijas.</p>
              <p className="heavy-text">Tu nueva contraseña debe tener:</p>
              <ul className="requirements-list">
                <li>Mínimo 6 caracteres.</li>
                <li>Al menos, una letra mayúscula.</li>
                <li>Al menos, un número.</li>
                <li> No incluir símbolos.</li>
              </ul>
            </div>
          </div>

          <Formik
            initialValues={{
              idClinica: state.user.idClinica,
              idPaciente: state.user.idPaciente,
              currentPassword: "",
              password: "",
              confirmPassword: "",
            }}
            validationSchema={reviewSchema}
            onSubmit={(values, actions) => {
              actions.resetForm();
              onSubmitChangePassword(values);
            }}
          >
            {(props) => (
              <Form onSubmit={props.handleSubmit}>
                {renderServerErrors()}
                <div
                  className="form-group"
                  onClick={() => {
                    if (errorMessage) {
                      if (errorMessage) {
                        setErrorMessage(null);
                      }
                    }
                  }}
                >
                  <InputBlock
                    label="Introduce tu contraseña actual"
                    placeholder="Contraseña actual"
                    values={props.values.currentPassword}
                    handleBlur={props.handleBlur}
                    handleChange={props.handleChange}
                    paramChange="currentPassword"
                    paramBlur="currentPassword"
                    type={"password"}
                  />

                  {props.touched.currentPassword && props.errors.currentPassword ? (
                    <ErrorDialog text={props.errors.currentPassword} />
                  ) : null}
                </div>
                <div
                  className="form-group"
                  onClick={() => {
                    if (errorMessage) {
                      if (errorMessage) {
                        setErrorMessage(null);
                      }
                    }
                  }}
                >
                  <InputBlock
                    label="Introduce tu nueva contraseña"
                    placeholder="Contraseña nueva"
                    values={props.values.password}
                    handleBlur={props.handleBlur}
                    handleChange={props.handleChange}
                    paramChange="password"
                    paramBlur="password"
                    type={"password"}
                  />
                  {props.touched.password && props.errors.password ? (
                    <ErrorDialog text={props.errors.password} />
                  ) : null}
                </div>
                <div
                  className="form-group"
                  onClick={() => {
                    if (errorMessage) {
                      if (errorMessage) {
                        setErrorMessage(null);
                      }
                    }
                  }}
                >
                  <InputBlock
                    label="Introduce tu nueva contraseña otra vez"
                    placeholder="Confirma tu contraseña"
                    values={props.values.confirmPassword}
                    handleBlur={props.handleBlur}
                    handleChange={props.handleChange}
                    paramChange="confirmPassword"
                    paramBlur="confirmPassword"
                    type={"password"}
                  />
                  {props.touched.confirmPassword && props.errors.confirmPassword ? (
                    <ErrorDialog text={props.errors.confirmPassword} />
                  ) : null}
                </div>
                <ButtonMain type={"submit"} label="Continuar" action={props.handleSubmit}></ButtonMain>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChangePassword;
