import React, { Suspense } from "react";
import ButtonMain from "../../../../components/ButtonMain/ButtonMain";
import ReportContent from "../../../../components/ReportContent/ReportContent";
import Loading from "../../../../components/Loading/Loading";
import RequestReport from "./RequestReport/RequestReport";
import ModalBox from "../../../../components/ModalBox/ModalBox";
import noFilesBackground from "../../../../assets/images/no-files.jpg";
import ErrorDialog from "../../../../components/ErrorDialog/ErrorDialog";
import ErrorHandler from "../../../../components/ErrorHandler/ErrorHandler";

const MedicalReports = ({
  data,
  reportTypes,
  onRequestReport,
  getReportPdf,
  error,
  setModalOpen,
  modalOpen,
  loading,
  errorMessage,
}) => {
  /////////////////////////////
  // Gestión de eventos y servicios
  /////////////////////////////

  const mapReportTypes = () => {
    let result = [];

    if (reportTypes) {
      reportTypes.forEach((item) => {
        if (item.esJustificante === false) {
          result.push({
            label: item.Descripcion,
            value: item.IdTipoInforme,
            key: item.IdTipoInforme,
            isNote: item.esJustificante,
            isRequired: item.esObligatorioObservaciones
          });
        }
      });

    }
    return result;
  };

  const openModal = () => setModalOpen(true);

  const onSubmitAction = (values) => {
    onRequestReport(values);
  };

  //////////////////////////
  //Funciones de renderizado
  //////////////////////////

  const renderModal = () => {
    const LazyModal = React.lazy(() => import("../../../../components/ModalBox/ModalBox"));

    return (
      <Suspense
        fallback={
          <ModalBox>
            <Loading />
          </ModalBox>
        }
      >
        <LazyModal onToggle={setModalOpen}>
          {loading ? (
            <Loading />
          ) : (
            <RequestReport data={data} reportTypes={mapReportTypes()} onSubmitAction={onSubmitAction} error={error} />
          )}
        </LazyModal>
      </Suspense>
    );
  };

  /**
   * @description Si no hay ninguna fecha disponible se renderizará este contenido
   */

  const renderEmpty = () => {
    return (
      <React.Fragment>
        <div className="empty-container">
          <img src={noFilesBackground} alt="" />
          <p className="no-content-info">En estos momentos no tienes informes disponibles</p>
        </div>
      </React.Fragment>
    );
  };

  /**
   * @description Renderizado de errores provinientes del servidor
   */

  const renderErrors = () => (errorMessage ? <ErrorHandler errorMessage={errorMessage} /> : null);

  const renderElements = () => {
    return (
      <React.Fragment>
        {data.map((item, index) => {
          return (
            <ReportContent
              key={index}
              title={item.idInformeMedico}
              date={item.fecha}
              icon="report"
              buttonLabel="Descargar"
              buttonAction={() => getReportPdf(item, true)}
              viewAction={() => getReportPdf(item, false)} document={item}
              type={item.descripcion}
            ></ReportContent>
          );
        })}
      </React.Fragment>
    );
  };

  /////////////////////////////
  // Renderizado del componente
  /////////////////////////////

  const renderAll = () => {
    if (!data && loading) {
      return <Loading />;
    }
    if (data) {
      if (data.length <= 0 && !loading) {
        return renderEmpty();
      } else {
        return renderElements();
      }
    }
  };

  return (
    <div>
      <div className="request-report">
        <p>Si lo necesitas puedes solicitar aquí tus informes médicos o quirúrgicos</p>

        {error ? <ErrorDialog text={error} /> : null}
        <ButtonMain label="Solicitar Informe" action={openModal}></ButtonMain>
      </div>
      {renderAll()}
      {renderErrors()}
      {modalOpen ? renderModal() : null}
    </div>
  );
};

export default MedicalReports;
