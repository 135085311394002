import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../provider";
import Layout from "../Layout/Layout";


/**
 * 
 * @param {Component} component Componente que se renderiza en la ruta
 * @param {any} rest El resto de props que se han pasado al componente. 
 * @description PrivateRoute se utiliza para renderizar una ruta si el usuario 
 * se ha logueado y el contenido de la ruta solo está disponible para los usuarios 
 * registrados. 
 * 
 * PrivateRoute recibe como props un componente y las props de este componente. 
 * Devuelve/renderiza el componente con sus props si el usuario está logueado. 
 * Si no, nos redirecciona a login
 *  
 */

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { state } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        !state.isLoggedIn ? (
          <Redirect to="/intro/login" />
        ) : (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }
    />
  );
};

export default PrivateRoute;
