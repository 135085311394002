import React, { useState, useEffect, Suspense } from 'react'

// Components

import {
  BrowserRouter,
  Link,
  Redirect,
  Route,
  useHistory
} from 'react-router-dom'
import Tabs from '../../../components/Tabs/Tabs'
import ModalBox from '../../../components/ModalBox/ModalBox'
import Loading from '../../../components/Loading/Loading'
import ErrorHandler from '../../../components/ErrorHandler/ErrorHandler'
import ButtonMain from '../../../components/ButtonMain/ButtonMain'
import recomendIcon from '../../../assets/images/icons/recomend-color-icon.svg'
import prizesIcon from '../../../assets/images/icons/gift-color-icon.svg'
//import accede_pa_img from "../../../assets/images/friend-plan.jpg";
import accede_pa_img from '../../../assets/images/accede_pa.jpg'
import errorregistro_img from '../../../assets/images/errorregistro.jpg'
import registro_img from '../../../assets/images/registro.jpg'
import user_not_valid_img from '../../../assets/images/error_pa.jpg'

// import sectionIcon from "../../../assets/images/icons/gift-color-icon.svg";
import './plan.scss'
import * as api from '../../../services/plan-amigo-service'

// Pages

import Amazon from './Prizes/Amazon/Amazon'
import Discover from './Discover/Discover'
import Fuel from './Prizes/Fuel/Fuel'
import Hotel from './Prizes/Hotel/Hotel'
import Prizes from './Prizes/Prizes'
import Spa from './Prizes/Spa/Spa'
import FriendsIndicator from './FriendsIndicator/FriendsIndicator'
import { usePlanAmigo } from '../../../plan-amigo-provider'
import { useAuth } from '../../../provider'
import Decathlon from './Prizes/Decathlon/Decathlon'
import Recomend from './Recomend/Recomend'
import ErrorDialog from '../../../components/ErrorDialog/ErrorDialog'
import MessageModal from '../../../components/MessageModal/MessageModal'
import PrizesDetail from './Prizes/PrizesDetail/PrizesDetail'

let childrenComp = [
  {
    label: 'Descubre el Plan Amigo',
    url: '/Paciente/Plan/Descubre-plan-amigo',
    sectionTitle: null,
    sectionText: null,
    sectionIcon: null,
    index: 0
  },
  {
    label: '1: Invita a tus amigos',
    url: '/Paciente/Plan/Recomienda-clinica-baviera-tus-amigos',
    sectionTitle: 'Recomienda',
    sectionText: '',
    sectionIcon: recomendIcon,
    index: 1
  },
  {
    label: '2: Premios',
    url: '/Paciente/Plan/Premios',
    sectionTitle: 'Premios',
    sectionText:'',
    sectionIcon: prizesIcon,
    index: 2
  }
]

/**
 * Componente que contiene la lógica de enrutado del plan amigo
 * así como la gestión del renderizado del indicador de amigos
 * disponibles
 */

const Plan = () => {
  /////////////////////////////
  // Configuración del componente
  /////////////////////////////


  const [isPrivacyAccepted, setIsPrivacyAccepted] = useState(false)
  const [checkedPrivacyAccepted, setCheckedPrivacyAccepted] = useState(false)
  const [activeTab, setActiveTab] = useState(childrenComp[0])
  const [currentLocation, setCurrentLocation] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [modalErrorOpen, setModalErrorOpen] = useState(false)
  const [hasErrors, setHasErrors] = useState(false)
  const { state } = useAuth()
  const {
    planAmigoState,
    setUserHasPlan,
    setFriendsAndPrizes,
    setPrivacyToAccepted
  } = usePlanAmigo()

  const history = useHistory()
  /**
   * UseEffect que actualiza los datos globales del plan amigo si no han sido actualizados ya
   * @see getPlanAmigoData
   */
  const { hasAcceptedPrivacy } = planAmigoState
  /**
   * Obtener los datos estaticos de la api de PlanAmigo
   */
  useEffect(() => {
    let fetch = true;
    if(planAmigoState.isUserInPlan != false){
      api.getTextosEstaticos()
      .then(res => {
        if(fetch && res.status === 200){
          //Descubre
          const {descubre, invita, premios} = res.body;
          childrenComp[0] = {...childrenComp[0], ...descubre}
          childrenComp[1] = {...childrenComp[1], ...invita, sectionText: invita.recomienda}
          const premiosText = `${premios.premios.description} <ul>
            ${
              premios.premios.options.map(li => (`<li>${li}</li>`)).toString().replace(/,/g,'')
            }
          </ul>`
          childrenComp[2] = {...childrenComp[2], ...premios, sectionText: premiosText}
        }
      })
      .then(()=> setLoading(false))
      return () => {
        fetch = false;
      }
    }
    
  }, [planAmigoState.isUserInPlan])
  

  /**Repinta al cambiar el estado de isUserInPla para cargar los datos*/
  useEffect(() => {
    if (
      planAmigoState.isUserInPlan?.hasPlan == false &&
      planAmigoState.isUserInPlan?.code === 2
    )
      registerInPlanAmigo()
  }, [planAmigoState.isUserInPlan])

  /**
   * Actualizar el tab activo cuando cambie la navegación
   */
  useEffect(() => {
    const activatedTab = childrenComp.find(
      tab => tab.url === window.location.pathname
    )
    if (activatedTab) {
      setActiveTab(activatedTab)
    }
  }, [window.location.pathname])

  /**
   * Registro del usuario en el plan amigo después
   * @param {{dni:string, mail:string, nombre:string}} userData Datos devueltos por el propio backend de plan amigo
   */
  const registerInPlanAmigo = async () => {
    try {
      setLoading(true)
      const res = await api.registerUser()
      await getPlanAmigoData()
      setLoading(false)
    } catch (error) {
      setErrorMessage(error.message)
      setLoading(false)
      setModalErrorOpen(true)
    }
  }

  /**
   *
   * @param {Object} data
   * @param {String} data.idClinica
   * @param {String} data.idPaciente
   *
   * Llamada para conseguir los puntos de amigos invitados y los premios conseguidos
   *
   *
   */

  const getPlanAmigoData = async () => {
    try {
      setLoading(true)
      const res = await api.requestPointsAndPrizesByDNI()
      const {
        puntos,
        premios,
        status,
        url_pedir_cita_amigos,
        status_code
      } = res

      if (status_code === 3) {
        setUserHasPlan({ hasPlan: true })
        setFriendsAndPrizes(
          puntos.disponibles,
          [],
          premios,
          true,
          url_pedir_cita_amigos
        )
      }
    } catch (error) {
      setErrorMessage(error.message)
      setModalErrorOpen(true)
    }
    setLoading(false)
  }

  /////////////////////////////
  // Gestión de eventos y servicios
  /////////////////////////////

  /**
   *
   * @param {String} tabLabel
   * Matchea la tab actual con su label para setearla a activa.
   */

  const onClickTabItem = url => {
    const activatedTab = childrenComp.find(tab => tab.url === url)
    setActiveTab(activatedTab)
  }

  const planTabsProps = {
    childrenComp,
    activeTab,
    setActiveTab,
    onClickTabItem,
    setCurrentLocation,
    currentLocation
  }

  /**
   * Renderiza un modal de error
   */
  const renderErrorModal = () => {
    const LazyModal = React.lazy(() =>
      import('../../../components/ModalBox/ModalBox')
    )
    const closeAndClearError = () => {
      setModalErrorOpen(false)
      setErrorMessage(null)
    }

    return (
      <Suspense
        fallback={
          <ModalBox>
            <Loading />
          </ModalBox>
        }
      >
        <LazyModal onToggle={closeAndClearError}>
          <ErrorHandler errorMessage={errorMessage} />
          <ButtonMain
            action={closeAndClearError}
            label='OK'
            actionParams={false}
          />
        </LazyModal>
      </Suspense>
    )
  }

  /**
   * Renderiza un modal de carga
   */
  const renderLoading = () => {
    return (
      <ModalBox>
        <Loading />
      </ModalBox>
    )
  }

  /**
   * Renderizar si el usuario tiene el plan amigo activo
   */

  const renderPlanAmigo = () => {
    return (
      <div className='plan-amigo'>
        {modalErrorOpen && renderErrorModal()}
        <BrowserRouter>
          <Tabs {...planTabsProps}>
            {/*Temporal hasta que aprueben la sección de recomendar, oculto la cabecera, una vez aprobado quitar la primera comprobación de currentLocation*/}

            <div className='block-top'>
              {routes.includes(currentLocation) ? (
                <div className='col-left'>
                  <div className='block-top-icon'>
                    <img
                      src={currentChildren?.sectionIcon}
                      alt='Icono de altavoz'
                    />
                    <h2>{currentChildren?.sectionTitle}</h2>
                  </div>
                  <div className='block-top-info'>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: currentChildren?.sectionText
                      }}
                    ></p>
                  </div>
                </div>
              ) : null}

              {currentLocation !== '/Paciente/Plan/Descubre-plan-amigo' && (
                <div className='col-right'>
                  <FriendsIndicator
                    numberOfFriends={planAmigoState.numberOfFriends}
                  />
                </div>
              )}
            </div>
            <Route path='/Paciente/Plan'>
              <Redirect to='/Paciente/Plan/Descubre-plan-amigo'></Redirect>
            </Route>
            <Route exact path='/Paciente/Plan/Descubre-plan-amigo'>
              <div className='discover-page'>
                <Discover data={childrenComp[0]} setCurrentLocation={setCurrentLocation} />
              </div>
            </Route>
            <Route
              exact
              path='/Paciente/Plan/Recomienda-clinica-baviera-tus-amigos'
            >
              <Recomend data={childrenComp[1]} setCurrentLocation={setCurrentLocation}></Recomend>
            </Route>
            <Route exact path='/Paciente/Plan/Premios'>
              <div className='prizes-page'>
                <Prizes setCurrentLocation={setCurrentLocation}></Prizes>
              </div>
            </Route>
            <Route exact path='/Paciente/Plan/Premios/Elige-tu-spa'>
              <Spa setCurrentLocation={setCurrentLocation} />
            </Route>
            <Route exact path='/Paciente/Plan/Premios/prize-detail'>
              <PrizesDetail setCurrentLocation={setCurrentLocation} />
            </Route>
            <Route exact path='/Paciente/Plan/Premios/50€-en-tarjeta-decathlon'>
              <Decathlon setCurrentLocation={setCurrentLocation} />
            </Route>
            <Route exact path='/Paciente/Plan/Premios/Tarjeta-amazon'>
              <Amazon setCurrentLocation={setCurrentLocation} />
            </Route>
            <Route
              exact
              path='/Paciente/Plan/Premios/elige-tu-hotel-experiencia-ruralka'
            >
              <div className='hotels-page'>
                <Hotel setCurrentLocation={setCurrentLocation} />
              </div>
            </Route>
          </Tabs>
        </BrowserRouter>
      </div>
    )
  }

  /**
   * Renderizar si el usuario no tiene plan amigo
   *
   */
  const renderHasNoPlanAmigo = isUserInPlan => {
    /**
     * Renderiza el contenido para registrarse en el PA y cargar sus datos
     * @returns Fragment
     */
    const registerComponent = () => {
      return <React.Fragment>{loading && renderLoading()}</React.Fragment>
    }
    /**
     * Renderiza el contenido si el usuario no esta apto para registrarse en el PA
     * @returns Fragment
     */
    const userNotValid = () => {
      return (
        <React.Fragment>
          <h2 className='subtitle'>
            Lo sentimos pero no cumples los requisitos para acceder al Plan
            Amigo.
          </h2>
          <div className='empty-container'>
            <img
              className='plan-image'
              src={user_not_valid_img}
              alt='No tienes plan amigo'
            />
          </div>
        </React.Fragment>
      )
    }

    switch (isUserInPlan.code) {
      case 2:
        return registerComponent()
      default:
        return userNotValid()
    }
  }

  const checkUserEmail = () => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (state.userData?.email && re.test(state.userData.email)) {
      return null
    } else {
      return (
        <Suspense>
          <ModalBox hasCloseButton={true}>
            <h2 className='subtitle'>
              Actualiza tu dirección de e-mail para recibir los premios de tu
              Plan Amigo.
            </h2>
            <div className='empty-container'>
              <img
                className='plan-image'
                src={errorregistro_img}
                alt='No tienes plan amigo'
              />
              {/* <p className="no-content-info">
              Pendiente de copies de validación de email por baviera.
              </p> */}
            </div>
            <div className='accept-button'>
              <ButtonMain
                label='Actualizar'
                action={() => {
                  history.push('/Paciente/Cuenta/Datos?edit=email')
                }}
              />
            </div>
          </ModalBox>
        </Suspense>
      )
    }
  }

  /**
   * Se matchea la url actual con la url de uno de los elementos del array childrenComp
   * para que cada vez que se renderice el componente
   * sólo se renderice uno de los hijos actuales
   * @type {Object}
   * @property {String} label
   * @property {String} url
   * @property {String} sectionTitle
   * @property {String} sectionText
   * @property {String} sectionIcon
   * @property {Number} index
   *
   */

  const currentChildren = childrenComp.find(el => el.url === currentLocation)
  
  const routes = [
    '/Paciente/Plan/Premios',
    '/Paciente/Plan/Recomienda-clinica-baviera-tus-amigos'
  ]

  return (
    loading ? renderLoading() :
      (<React.Fragment>
        {state.userData && checkUserEmail()}
        <React.Fragment>
          {state.globalLoading && <Loading />}
          {!planAmigoState.isUserInPlan.hasPlan && !state.globalLoading
            ? renderHasNoPlanAmigo(planAmigoState.isUserInPlan)
            : null}
          {planAmigoState.isUserInPlan.hasPlan && !state.globalLoading
            ? renderPlanAmigo()
            : null}
        </React.Fragment>
        <React.Fragment>
          <div className='main-cta'>
            <div className='cta'>
              <p
                className='no-content-info'
                style={{
                  fontWeight: 'bold',
                  color: 'black',
                  fontFamily: 'Gotham',
                  textAlign: 'center'
                }}
              >
                ¿Tienes dudas con tus premios? ¡Te ayudamos!
              </p>
              <div className='info'>
                <a
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  rel='noreferrer'
                  href='mailto:infoplanamigo@clinicabaviera.com'
                  target='_blank'
                  className='email'
                >
                  {' '}
                  infoplanamigo@clinicabaviera.com
                </a>
                <a
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  rel='noreferrer'
                  href='tel:+34622817897'
                  target='_blank'
                  className='telefono'
                >
                  622 817 897
                </a>
              </div>
            </div>
          </div>
        </React.Fragment>
      </React.Fragment>)
  )
}

export default Plan
