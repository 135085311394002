import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import Loading from "../../../../components/Loading/Loading";
import ButtonMain from "../../../../components/ButtonMain/ButtonMain";
import { useAuth } from "../../../../provider";
import "./makeCall.scss";
import ErrorHandler from "../../../../components/ErrorHandler/ErrorHandler";

const MakeCall = ({
	loading,
	reasons,
	handleCallSubmit,
	errorMessage,
	errorStatus,
	setErrorMessage,
	setErrorStatus,
}) => {
	const [reasonSelected, setReasonSelected] = useState(false);

	const { state } = useAuth();

	const buttonRef = useRef(null);

	useEffect(() => {
		if (reasonSelected) {
			buttonRef.current.scrollIntoView({ behavior: "smooth" });
			setReasonSelected(false);
		}
	}, [reasonSelected, buttonRef]);

	const renderErrors = () =>
		errorMessage || errorStatus ? <ErrorHandler errorMessage={errorMessage} errorStatus={errorStatus} /> : null;

	return (
		<React.Fragment>
			{loading ? (
				<Loading />
			) : (
				<React.Fragment>
					<div className="top-modal-info">
						<h3>Tu médico te llama</h3>
						<p className="bottom-space">
							Habla con tu médico y resuelve todas tus dudas.
							<br /> Describe el motivo de tu consulta:
						</p>
					</div>
					<Formik
						initialValues={{
							idClinica: state.user.idClinica,
							idPaciente: state.user.idPaciente,
							idMotivo: 1,
							comentario: "",
						}}
						onSubmit={(values, actions) => {
							actions.resetForm();
							handleCallSubmit(values);
						}}
					>
						{(props) => (
							<Form onSubmit={props.onSubmit}>
								{renderErrors()}
								<div>
									<div className="radio-group form-container">
										{reasons.map((reason, index) => {
											return (
												<div className="form-control" key={index}>
													<label className="radio-label">
														<input
															type="radio"
															name="idMotivo"
															value={props.idMotivo}
															onClick={() => {
																setReasonSelected(true);
																if (errorMessage || errorStatus) {
																	setErrorStatus(null);
																	setErrorMessage(null);
																}
															}}
														/>
														<span>{reason.label}</span>
													</label>
												</div>
											);
										})}
									</div>
								</div>
								<div className="form-control bottom-space textarea-content">
									<label className="textarea-label">
										<span>Explícanos brevemente de qué se trata</span>
										<textarea
											rows="4"
											cols="50"
											placeholder="Comentario..."
											onBlur={props.handleBlur("comentario")}
											onChange={props.handleChange("comentario")}
											onClick={() => {
												if (errorMessage || errorStatus) {
													setErrorStatus(null);
													setErrorMessage(null);
												}
											}}
										/>
									</label>
								</div>
								<div ref={buttonRef}></div>
								<ButtonMain label="Llamadme" action={props.handleSubmit} type={props.onSubmit} />
							</Form>
						)}
					</Formik>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

export default MakeCall;
