import React from "react";
import "./snackbar.scss";
import { useAuth } from "../../provider";

/**
 * 
 * @param {Object} props
 * @param {any} props.children Componentes que se renderizan dentro del componente
 * @param {String} props.text Texto que se renderizará en el componente
 *   
 */

const Snackbar = (props) => {
  const { children, text } = props;

  const { clearError } = useAuth();


  return (
    <div className="snackbar visible">
      <div className="snackbar-content">
        {children}
        <p className="snackbar-message">
        {text}
        </p>
      </div>
      <button className="close-button" onClick={() => clearError()} />
    </div>
  );
};

export default Snackbar;
