import React, { useEffect, useRef, useState } from "react";
import ErrorDialog from "../../../../components/ErrorDialog/ErrorDialog";
import Loading from "../../../../components/Loading/Loading";
import TimeBox from "../../../../components/TimeBox/TimeBox";
import DateBox from "../../../../components/DateBox/DateBox";
import moment from "moment";
import { useAuth } from "../../../../provider";
import * as api from "../../../../services/services";
import "./makeConference.scss";
import { Link } from "react-router-dom";
import ErrorHandler from "../../../../components/ErrorHandler/ErrorHandler";
import noCalendarBackground from "../../../../assets/images/no-calendar.jpg";
import { date } from "yup";

const MakeConference = () => {
	/////////////////////////////
	// Configuración del componente
	/////////////////////////////

	const [errorMessage, setErrorMessage] = useState(null);
	const [errorStatus, setErrorStatus] = useState(null);
	const [privacyAccepted, setPrivacyAccepted] = useState(false);
	const [success, setSuccess] = useState(null);
	const [loading, setLoading] = useState(false);
	const { state } = useAuth();
	const [dataDates, setDataDates] = useState([]);
	const [selectedDate, setSelectedDate] = useState(null);
	const [dataTimes, setDataTimes] = useState([]);
	const [selectedTime, setSelectedTime] = useState(null);
	const [reason, setReason] = useState(null);
	const [acceptPrivacy, setAcceptPrivacy] = useState(false);
	const [hasAcceptedPrivacy, setHasAcceptedPrivacy] = useState(true);
	const [placeHolders, setPlaceHolders] = useState([]);
	const [hasAppointments, setHasAppointments] = useState(false);

	const containerRef = useRef(null);
	const dateRef = useRef(null);
	const timesRef = useRef(null);
	const privacyRef = useRef(null);

	const hasElements = () => {
		return placeHolders.length > 0;
	};

	useEffect(() => {
		getDataAsync();
	}, []);

	/**
	 * @description Autoscroll cuando alguna se selecciona alguna opción del formulario
	 *
	 */

	useEffect(() => {
		if (reason) {
			dateRef.current.scrollIntoView();
		}
	}, [reason]);

	useEffect(() => {
		if (selectedDate) {
			timesRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [selectedDate]);

	useEffect(() => {
		if (selectedTime) {
			privacyRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [selectedTime]);

	/////////////////////////////
	// Gestión de eventos y servicios
	/////////////////////////////

	/**
	 * @description Llamada a la API mediante la cual se consiguen las fechas con citas disponibles.
	 */

	async function getDataAsync() {
		setLoading(true);
		try {
			let response = await api.getConferences(state.user);

			setPlaceHolders(response);
			setDataDates(buildDateElements(response));
			setLoading(false);
		} catch (error) {
			if (Number(error.message) === -14) {
				setHasAppointments(true);
			}

			setLoading(false);
			setErrorMessage(error.message);
			setErrorStatus(error.status);
		}
	}

	/**
	 *    @param {Object} item
	 *    @param {Boolean} item.selected
	 *    @param {Date} item.date
	 *
	 *    @description Cuando se clicka en una fecha se entra en
	 *    esta función que comprueba si la fecha está seleccionada
	 *    Si no ha sido seleccionada previamente se construyen y formatean
	 *    las horas de cita libres en la fecha seleccionada.
	 *    @see buildDateElements
	 */

	const onClickDate = (item) => {
		let result = [...dataDates];
		if (item.selected) {
			result.forEach((e) => (e.selected = false));
			setSelectedDate(null);
			buildTimeElements(null);
		} else {
			result.forEach((e) => (e.selected = e.date == item.date));
			setSelectedDate(item.date);
			buildTimeElements(item.date);
		}
		setDataDates(result);
	};

	/**
	 *    @param {Object} item
	 *    @param {Boolean} item.selected
	 *    @param {Date} item.time
	 *
	 *    @description Cuando se clicka en una hora se entra en
	 *    esta función que comprueba si la hora está seleccionada
	 *    Si no ha sido seleccionada previamente se setea la fecha
	 *    pasada por parámetros como la hora seleccionada.
	 *    @see buildTimeElements
	 */

	const onClickTime = (item) => {
		let result = [...dataTimes];
		if (item.selected) {
			result.forEach((e) => (e.selected = false));
			setSelectedTime(null);
		} else {
			result.forEach((e) => (e.selected = e.time == item.time));
			setSelectedTime(item.time);
		}
		setDataTimes(result);
	};

	/**
	 *
	 *    @description Una vez que se han seleccionado una fecha y
	 *    una hora se podrá envíar una petición al servidor para que
	 *    se reserve la hora en el día seleccionado.
	 *    @see onClickDate
	 *    @see onClickTime
	 *
	 *
	 */

	const onConfirmButton = async () => {
		// setModalConfirmOpen(false);

		var selected = placeHolders.filter((x) => x.fecha === selectedDate && x.horaRealCita === selectedTime);
		if (selected.length > 0) {
			var values = {
				idClinicaPaciente: state.userData.idClinica,
				idClinicaVideoLlamada: selected[0].idClinica,
				idMedico: selected[0].idMedico,
				idProceso: selected[0].idProceso,
				horaRealCita: selected[0].horaRealCita,
				horaInicio: selected[0].horaInicio,
				horaFin: selected[0].horafin,
				idPaciente: state.userData.idPaciente,
				nombre: state.userData.nombre,
				primerApellido: state.userData.primerApellido,
				segundoApellido: state.userData.segundoApellido,
				telefono: state.userData.telefono,
				dni: state.userData.dni,
				observaciones: reason,
				fechaNacimiento: moment(state.userData.fechaNacimiento).format("DD/MM/yyyy"),
				fecha: moment(selected[0].fecha).format("DD/MM/yyyy"),
			};

			try {
				setLoading(true);

				var response = await api.requestConference(values);

				if (response === 0 || response === true || response === "")
					setSuccess("Tu petición se ha realizado con éxito");

				setLoading(false);
			} catch (error) {
				setLoading(false);
				setErrorMessage(error.message);
				setErrorStatus(error.status);
			}
		}
	};

	const onHandleChange = (e) => setReason(e.target.value);

	/////////////////////////////
	// Formateo y gestión de datos y fechas
	/////////////////////////////

	/**
	 *
	 * @param {Object} response
	 * @description Formatea el listado de fechas proveniente del servidor
	 */

	const buildDateElements = (response) => {
		let uniqueDays = [],
			result = [];

		for (let i = 0; i < response.length; i++) {
			const element = response[i];
			if (uniqueDays.indexOf(element.fecha) === -1) {
				uniqueDays.push(element.fecha);
			}
		}
		uniqueDays.sort().forEach((e) => {
			result.push({ date: e, selected: false });
		});
		return result;
	};

	/**
	 * @param {Object} item
	 * @description Formatea el listado de horas proveniente del servidor
	 */

	const buildTimeElements = (item) => {
		if (item) {
			let uniqueTimes = [],
				result = [];

			for (let i = 0; i < placeHolders.length; i++) {
				const element = placeHolders[i];
				if (element.fecha === item) {
					if (uniqueTimes.indexOf(element.horaRealCita) === -1) {
						uniqueTimes.push(element.horaRealCita);
					}
				}
			}

			uniqueTimes.forEach((e) => {
				result.push({ time: e, selected: false });
			});
			setDataTimes(result);
		}
	};

	/**
	 * @description Gestiona el evento click en el botón que simula estar desabilitado
	 */

	const handleClickOnDisabledButton = () => {
		if (selectedDate && selectedTime) {
			setHasAcceptedPrivacy(false);
		}
	};
	/**
	 * @description Desabilita el botón de submit si no se han rellenado todos los campos
	 */

	const disabledButton = () => {
		return selectedTime == null || selectedTime === "" || acceptPrivacy === false;
	};

	

	//////////////////////////
	//Funciones de renderizado
	//////////////////////////

	/**
	 * @description Renderiza las fechas
	 */

	const renderDates = () => {
		if (reason != null && reason !== "") {
			return (
				<div ref={dateRef}>
					<p className="label-text top-space-large">Selecciona el día que prefieres.</p>
					<div className="days-container">
						{dataDates.map((date, index) => {
							return <DateBox item={date} onClick={onClickDate} key={index}></DateBox>;
						})}
					</div>
				</div>
			);
		} else return null;
	};

	/**
	 * @description Renderiza las horas
	 */

	const renderTimes = () => {
		if (reason && selectedDate) {
			return (
				<div ref={timesRef}>
					<p className="label-text top-space-large">Elige la hora que mejor te venga:</p>
					<div className="times-container">
						{dataTimes.map((time, index) => {
							return <TimeBox item={time} key={index} onClick={onClickTime} />;
						})}
					</div>
				</div>
			);
		} else return null;
	};

	/**
	 * @description Renderiza la sección con las condiciones de privacidad
	 */

	const renderPrivacy = () => {
		if (selectedDate && selectedTime) {
			return (
				<label className="legal-checkbox">
					<input type="checkbox" checked={acceptPrivacy} onChange={() => setAcceptPrivacy(!acceptPrivacy)} />
					<span>
						He leído y acepto la{" "}
						<Link target="_blank" to="/intro/privacidad">
							Política de privacidad
						</Link>
					</span>
				</label>
			);
		}
	};

	/**
	 * @description Si el usuario no ha aceptado las condiciones de privacidad
	 * pero pulsa el botón de confirmación se renderiza este componente
	 */

	const renderPrivacyError = () => {
		if (selectedDate && selectedTime && !acceptPrivacy && !hasAcceptedPrivacy) {
			return <ErrorDialog text={"Debes aceptar la Política de Privacidad para continuar"} />;
		} else {
			return null;
		}
	};

	/**
	 * @description Renderizado del botón de confirmación.
	 * Si no se ha seleccionado ninguna hora o no se ha
	 * aceptado la privacidad estará desactivado
	 */
	const renderConfirmButton = () => {
		if (!acceptPrivacy) {
			return (
				<button className="button-app top-space-large disabled-button" onClick={handleClickOnDisabledButton}>
					Confirmar cita
				</button>
			);
		} else if (selectedDate && selectedTime && acceptPrivacy) {
			return (
				<button className="button-app top-space-large" disabled={disabledButton()} onClick={onConfirmButton}>
					Confirmar cita
				</button>
			);
		}
	};

	/**
	 * @description Renderizado de errores provinientes del servidor
	 */

	const renderErrors = () =>
		errorMessage || errorStatus ? <ErrorHandler errorMessage={errorMessage} errorStatus={errorStatus} /> : null;

	/**
	 * @description Renderizado del modal de éxito en la petición
	 */

	const renderSuccess = () => (success ? <div className="success-text">{success}</div> : null);

	/**
	 * @description Si no hay ninguna fecha disponible se renderizará este contenido
	 */

	const renderEmpty = () => {
		let text = hasAppointments
			? "Ya tienes una videoconsulta agendada. Para cualquier cambio ponte en contacto con tu clínica."
			: "Lo sentimos pero actualmente no disponemos de ningún hueco libre en nuestras agendas. Por favor inténtelo más tarde.";
		return (
			<div className="no-videocall-date">
				<img src={noCalendarBackground} alt="" />
				<h3 className="message">{text}</h3>
			</div>
		);
	};

	/**
	 * @description Renderizado de los elementos si no hay una petición exitosa
	 */

	const renderElements = () => {
		return (
			<React.Fragment>
				{success ? (
					renderSuccess()
				) : (
					<div>
						{renderErrors()}
						<h3>Solicita una videoconsulta</h3>
						<p>
							Habla con nuestros médicos y resuelve tus dudas estés donde estés. <br /> Describe el motivo
							de tu consulta.
						</p>
						<div className="textarea-container">
							<label>
								<span>Explícanos brevemente de qué se trata.</span>
								<textarea
									name="descripcion"
									placeholder="Comentario"
									onChange={onHandleChange}
								></textarea>
							</label>
						</div>

						<div className="selector">
							<div className="dates">{renderDates()}</div>
							<div className="times">{renderTimes()}</div>
							<div className="privacy"> {renderPrivacy()}</div>
							<div className="privacy-error">{renderPrivacyError()}</div>
							<div className="confirm" ref={privacyRef}>
								{renderConfirmButton()}
							</div>
						</div>
					</div>
				)}
			</React.Fragment>
		);
	};

	/////////////////////////////
	// Renderizado del componente
	/////////////////////////////

	return <React.Fragment>{loading ? <Loading /> : hasElements() ? renderElements() : renderEmpty()}</React.Fragment>;
};

export default MakeConference;
