import React, { useEffect, useRef, useState } from "react";
import "./dragAndDropFile.scss";
import dragDropIcon from '../../assets/images/icons/drag-drop-icon-w.svg';

const DragAndDropFile = ({ children, onFileDrop, setFile }) => {
	const dropRef = useRef();

	const [isDragging, setIsDragging] = useState(false);
	let [dragCounter, setDragCounter] = useState(0);

	const clearFile = () => setFile(null)

	const handleDragIn = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setDragCounter(dragCounter++);
		if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
			setIsDragging(true);
		}
	};

	const handleDragOut = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setDragCounter(dragCounter--);
		if (dragCounter > 0) {
			return false;
		}
		setIsDragging(false);
	};

	const handleDrag = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setFile(null)
	};

	const handleDrop = (e) => {
		e.preventDefault();
        e.stopPropagation();
        setIsDragging(false)
        if(e.dataTransfer.files && e.dataTransfer.files.length > 0){
            onFileDrop(e.dataTransfer.files)
			setDragCounter(0)
		}
	};

	useEffect(() => {
		let div = dropRef.current;
		div.addEventListener("dragenter", handleDragIn);
		div.addEventListener("dragleave", handleDragOut);
		div.addEventListener("dragover", handleDrag);
		div.addEventListener("drop", handleDrop);

		return () => {
			div.removeEventListener("dragenter", handleDragIn);
			div.removeEventListener("dragleave", handleDragOut);
			div.removeEventListener("dragover", handleDrag);
			div.removeEventListener("drop", handleDrop);
		};
	}, []);

	return (
		<div ref={dropRef} className="drag-and-drop-container">
				<div className={`drag-and-drop-overlay ${isDragging ? 'is-dragging' : ''}`}>
					<div className="drag-and-drop-text">
						<p>Arrastra aquí tu archivo</p>
						<p>(pdf, jpg, png) 5Mb Máximo</p>
						<div className="drag-drop-icon">
							<img src={dragDropIcon} alt="arrastra aquí tus archivos"/>
						</div>
					</div>
				</div>
			{children}
		</div>
	);
};

export default DragAndDropFile;
