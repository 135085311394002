import React from "react";
import MenuItem from "./MenuItem/MenuItem";
import logo from "../../assets/images/lg-baviera-white.svg";
import "./menu.scss";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../provider";
/**
 * 
 * @param {any} props
 * @param {Array} props.menuItems Lista de elementos que se renderizarán en la barra de menu
 * @description Componente encargado de renderizar el menu de navegación de la página.  
 */

const Menu = (props) => {
  const { menuItems } = props;
  const location = useLocation();
  const { state } = useAuth();
  let newMenuItems = []
  if (state?.userData?.planAmigo === false) {
    newMenuItems = menuItems.filter(item => item.id !== 4);
  } else {
    newMenuItems = [...menuItems];
  }
  const renderedItems = newMenuItems.map((item) => {

    const currentLocation = location.pathname.split("/")[2];
    let labelToUpperCase = item.label.charAt(0).toUpperCase() + item.label.slice(1);

    if (labelToUpperCase === "Mi cuenta") {
      labelToUpperCase = "Cuenta"
    } if (labelToUpperCase === "Plan amigo") {
      labelToUpperCase = "Plan"
    }
    return (
      <li key={item.id} className={labelToUpperCase === currentLocation ? "menu-item selected" : "menu-item"}>
        <MenuItem menuItem={item} />
      </li>
    );
  });

  return (
    <React.Fragment>
      <nav className="sidebar">
        <Link to="/Paciente/Cuenta" className="logo">
          <img alt="Clínica Baviera" src={logo} />
        </Link>
        <ul className="menu">{renderedItems}</ul>
      </nav>
    </React.Fragment>
  );
};

export default Menu;
