import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../provider";
import SignInLayout from "../SingInLayout/SignInLayout";


/**
 * 
 * @param {Component} component Componente que se renderiza en la ruta
 * @param {any} rest El resto de props que se han pasado al componente. 
 * @description PublicRoute se utiliza para renderizar una ruta si el 
 * contenido de la ruta es accesible a todos los usuarios, registrados o no. 
 * 
 * PublicRoute recibe como props un componente y las props de este componente. 
 * Devuelve/renderiza el componente con sus props si el usuario no está logueado. 
 * Si no, nos redirecciona a /Paciente/Cuenta
 *  
 */


const PublicRoute = ({ component: Component, ...rest }) => {
  const { state } = useAuth();
  return (
    <Route
      {...rest}
      render={
        (props) => {
          if (state.isLoggedIn && !state.user?.isPasswordReset && !state.user?.isnewUser) {
            return <Redirect to="/Paciente/Cuenta" />
          }else if(state.user?.isPasswordReset || state.user?.isnewUser){
            return <Redirect to="/intro/cambiarpassword" />
          }

          if (!state.isLoggedIn) {
            return (
              <SignInLayout {...props}>
                <Component {...props} />
              </SignInLayout>
            )
          }
        }
      }
    />
  );
};

export default PublicRoute;
