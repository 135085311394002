import React from "react";
import Loading from "../../components/Loading/Loading";
import "./modalBox.scss";

/**
 * @param {object} props
 * @param {Component} children Componentes que se cargan en el interior de este componente
 * @param {Function} onToggle Función que activa o desactiva el modal
 */

const ModalBox = (props) => {
  const { children, onToggle, loading, hasCloseButton, action } = props;

 
  return (
    <React.Fragment>
      <div className="overlay">
        <div className="modal-container">
          {hasCloseButton ? null : (
            <button className="close-button" onClick={action ? action : onToggle ? () => onToggle(null) : () => null} />
          )}

          {loading ? <Loading /> : <div className="modal-content">{children}</div>}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ModalBox;
