import React from 'react'
import Base64Page from '../components/Base64File/Base64File'
import { saveAs } from 'file-saver';

/**
 * @description recibe el label de un elemento creado dinámicamente y
 * lo formatea sin acentos ni espacios para poder usarlo como clase CSS
 */
export const setLabeltoClass = label => {
  if (label) {
    let labelString = label.split(' ')
    let lastWord = labelString[labelString.length - 1]
    //let labelFormated = label.replace(/ /g, "-"); esto es para cuado quiero eliminar los espacios y dejar un guión
    let cssClass = lastWord.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    return cssClass.toLowerCase()
  }
}

/**
 * @param {string} base64pdf
 * @param {string} docType
 * @param {string} pacientName
 * @description  * Crea un elemento `<a></a>`con
 * el pdf en base 64 y un nombre de archivo.
 * Una vez creado hace click en él
 */

export const downloadFile = (base64file, fileName, fileExt) => {
  try {
            // Decodifica el base64 y crea un Uint8Array a partir del string
            const byteCharacters = atob(base64file);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            const blob = new Blob([byteArray], { type: fileExt == 'pdf' ? `application/${fileExt}` : `image/${fileExt}`  });

            // Guarda el Blob como un archivo con el nombre proporcionado
            saveAs(blob, fileName);

        } catch (error) {
            console.error('Error al descargar el PDF:', error);
        }
}

export const openInNewTab = (base64file, fileName, fileExt) => {
	try {
		if (fileExt === 'pdf') {
			const obj = document.createElement('object')
			const downloadLink = document.createElement('a')
			downloadLink.href = `data:application/pdf;base64, ${base64file} download="documento.pdf"`
			downloadLink.innerText = 'Descargar'
			obj.appendChild(downloadLink)
			obj.style.width = '100%'
			obj.style.height = '842pt'
			obj.type = 'application/pdf'
			obj.data = `data:application/pdf;base64, ${base64file}`
			const w = window.open('')
			w.document.write(obj.outerHTML)
		} else {
			const image = new Image()
			image.src = `data:image/${fileExt};base64,${base64file}`
			const w = window.open('')
			w.document.write(image.outerHTML)
		}
	} catch (error) {
		console.error(error)
	}
  
}

export const getFileExtension = fileName => {
  const fileNameToArr = fileName.split('.')
  const fileExtension = fileNameToArr[fileNameToArr.length - 1]
  return fileExtension
}

export const setRoutesState = (route, isLoggedIn) => {
  if (route !== '/intro/login' && !isLoggedIn) {
  }
}

export const viewFile = (base64file, fileExt) => {
  const image = new Image()
  image.src = `data:image/${fileExt};base64,${base64file}`
  const w = window.open('')
  w.document.write(image.outerHTML)
}

export const base64ToBlob = base64 => {
  // decode base64 string, remove space for IE compatibility

  try {
		const binary = atob(base64.replace(/\s/g, ''))
		const length = binary.length
		const buffer = new ArrayBuffer(length)
		const view = new Uint8Array(buffer)
		for (let i = 0; i < length; i++) {
			view[i] = binary.charCodeAt(i)
		}
	
		// create the blob object with content-type "application/pdf"
	
		const blob = new Blob([view], { type: 'application/pdf' })
		const url = URL.createObjectURL(blob)
		window.open(url)
	} catch (error) {
		console.error(error)
	}


}
