import React from "react";
import "./timeBox.scss";

/**
 * 
 * @param {Object} item Objeto que contiene la información de la hora 
 * @param {Function} onClick Función quie se ejecuta cuando se hace click en el componente
 *
 * @description Renderiza la información relativa a una hora que previamente ha sido pasada por props.  
 * 
 */



const TimeBox = ({ item, onClick }) => {
  const clickHandler = () => onClick(item);

  const timeValue = () => {
    return item.time.indexOf(":") > -1 ? item.time : `${item.time.substring(0, 2)}:${item.time.substring(2)}`;
  };

  return (
    <div onClick={clickHandler} className={`time-box ${item.selected ? "time-selected" : ""}`}>
      <div>
        <p>{timeValue()}</p>
      </div>
    </div>
  );
};

export default TimeBox;
