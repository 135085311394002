import React, { useEffect, useRef } from "react";
import Card from "../../../../components/Card/Card";
import { months } from "../../../../components/constants";
import ButtonMain from "../../../../components/ButtonMain/ButtonMain";
import { useHistory } from "react-router-dom";
import iconUser from "../../../../assets/images/icons/user-b.svg";
import iconClock from "../../../../assets/images/icons/clock-b.svg";
import iconLocation from "../../../../assets/images/icons/location-b.svg";
import iconCalendar from "../../../../assets/images/icons/calendar-color-icon.svg";
import moment from "moment";

import "./appointmentCard.scss";

/**
 *
 * @param {Object} appointment Objeto que contiene información sobre la cita
 * @param {Object} date Objeto que contiene la información de fecha necesaria para el renderizado
 * y para la gestión del renderizado del botón de videoconsulta
 *
 * @description Este componente recibe los datos de la cita y se encarga de renderizarlos
 * Además se encarga de decidir si se debe de mostrar el botón de videoconsulta o no.
 * @see renderTodayButton
 */

const AppointmentCard = ({ appointment, date, dateRef, selectedDateString }) => {

	/////////////////////////////
	// Configuración del componente
	/////////////////////////////

	const history = useHistory();

	const compRef = useRef(null);

	// Si se decide poder modificar las citas descomentar lo siguiente

	// const handleAppointmentClick = () => {
	//   setChosenAppointment(appointment);
	//   onChangeAppointment(appointment);
	//   toggleChangeModal(true);
	// }

	/////////////////////////////
	// Gestión de eventos y servicios
	/////////////////////////////

	const handleVideoClick = (appointment) => {
		history.push({ pathname: `/Videoconsulta/${appointment.room}`, state: { appointment: appointment } });
	};

	const limitMonthChars = () => {
		const month = months[date.month - 1];
		let splittedMonth = null;
		if (month) {
			if (month.length > 5) {
				splittedMonth = month.substring(0, 3) + ".";
				return splittedMonth;
			} else return month;
		}
		return false;
	};

	/////////////////////////////
	// Funciones de renderizado
	/////////////////////////////

	/**
	 *
	 * @description Si el día de la cita es hoy, renderiza el botón de Videoconsulta
	 * Si no retorna null
	 */

	const renderTodayButton = () => {
		const today = new Date();

		const isToday = moment(appointment.fecha).format("DDMMYY") === moment(today).format("DDMMYY");

		// Si se decide poder modificar las citas descomentar lo siguiente

		// if (
		//   appointment.esModificable === true ||
		//   appointment.esModificable === "true" ||
		//   appointment.esModificable == 1
		// ) {
		//   return <ButtonMain label="Videollamada" action={handleVideoClick}></ButtonMain>;
		// } else

		if (appointment.idProceso === "10" && isToday) {
			return (
				<ButtonMain
					iconClass="arrow-right-white"
					label="Inicia aquí"
					action={() => handleVideoClick(appointment)}
				></ButtonMain>
			);
		} else {
			return null;
		}
	};

	/////////////////////////////
	// Renderizado del componente
	/////////////////////////////

	return (
		<div ref={compRef}>
			<Card customClass="appointment-card">
				<div className="date-container">
					<div className="icon">
						<img src={iconCalendar} alt="" />
					</div>
					<div className="date-info">
						<h4 className="appointment-title">{appointment.nombreProceso.toLowerCase().trim() === "videollamada" ? "Videoconsulta" : appointment.nombreProceso}</h4>
						<p className="date">
							{date.day}
							<span className="month">{limitMonthChars()}</span>
						</p>
					</div>
				</div>

				<div className="appointment-info-container">
					<div className="icon-info">
						<img src={iconUser} alt="" />
						{appointment.nombreMedico}
					</div>
					{
						(appointment.hora && appointment.hora !== '') && <div  className="icon-info"><img src={iconClock} alt="" />	{appointment.hora}</div>
					}
					
					<div className="icon-info">
						<img src={iconLocation} alt="" />
						{appointment.nombreClinica}
					</div>
					<div className="actions">{renderTodayButton()}</div>
				</div>
			</Card>
		</div>
	);
};

export default AppointmentCard;
