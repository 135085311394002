
import { initializeApp } from "firebase/app";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDLForuvgj1XNUf1x9R_2pZwcSFp9s76t8",
  authDomain: "pushbaviera.firebaseapp.com",
  databaseURL: "https://pushbaviera.firebaseio.com",
  projectId: "pushbaviera",
  storageBucket: "pushbaviera.appspot.com",
  messagingSenderId: "571408088370",
  appId: "1:571408088370:web:28835c8ecfbb750d552b2b"
};


const app = initializeApp(firebaseConfig);
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Le6W3gjAAAAAId4y9nEhw54puu4slrFyGmn21MI'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

const functions = getFunctions(app);
//connectFunctionsEmulator(functions, "localhost", 5001);

export  const onCallFunction = (name) => {
  return httpsCallable(functions, name).call()
}
