import React from 'react'
import Card from '../../../components/Card/Card'
import logo from '../../../assets/images/lg-baviera-color.svg'
import './privacy.scss'

const Privacy = ({ onClose }) => {
  return (
    <div className='legal-info'>
      <div className='logo-container'>
        <a href='/' className='logo'>
          <img alt='Clínica Baviera' src={logo} />
        </a>
      </div>
      <Card>
        <h1>Pol&iacute;tica de Privacidad</h1>
        <h2>
          <strong>
            &iquest;Qui&eacute;n es el Responsable del tratamiento?
          </strong>
        </h2>
        <p>
          CLINICA BAVIERA, S.A. (en adelante, CB), con CIF A80240427 y domicilio
          en Paseo de la Castellana 20, 28046, Madrid, ser&aacute; la
          responsable del tratamiento de los datos personales facilitados a
          trav&eacute;s de la presente p&aacute;gina web, as&iacute; como de los
          facilitados telef&oacute;nicamente o mediante la
          cumplimentaci&oacute;n de cualquier formulario de cl&iacute;nica
          Baviera albergado en alguna otra p&aacute;gina web.
        </p>
        <p>
          Puede ponerse en contacto con nuestro Delegado de Protecci&oacute;n de
          Datos (DPD) mediante escrito dirigido a&nbsp;
          <a href='mailto:DPD@clinicabaviera.com'>DPD@clinicabaviera.com</a>
          &nbsp;o en el siguiente n&uacute;mero de tel&eacute;fono: 91 781 98
          80.
        </p>
        <h2>
          <strong>
            &iquest;Con qu&eacute; finalidad y en atenci&oacute;n a que base
            legitimadora tratamos sus datos personales?
          </strong>
        </h2>
        <p>
          En el supuesto en el que Usted cumplimente alguno de los siguientes
          formularios habilitados en esta p&aacute;gina Web, trataremos sus
          datos personales para atender su solicitud de informaci&oacute;n o
          sugerencias, siendo la base legitimadora de dicho tratamiento el
          consentimiento prestado por su parte al cumplimentar y enviar libre y
          voluntariamente el citado formulario:
        </p>
        <ul>
          <li>
            Formulario "
            <strong>&nbsp;Inf&oacute;rmate sin compromiso&nbsp;</strong>"
          </li>
          <li>
            Formulario "<strong>&nbsp;Te llamamos nosotros&nbsp;</strong>"
          </li>
          <li>
            Formulario "<strong>&nbsp;Pacientes internacionales&nbsp;</strong>"
          </li>
          <li>
            Formulario "
            <strong>
              &nbsp;Compa&ntilde;&iacute;as aseguradoras/Solicita
              informaci&oacute;n sobre tu convenio&nbsp;
            </strong>
            "
          </li>
          <li>
            Formulario "
            <strong>
              &nbsp;Recibe informaci&oacute;n sobre nuestras promociones&nbsp;
            </strong>
            "
          </li>
          <li>
            Formulario "<strong>&nbsp;&Aacute;rea de prensa&nbsp;</strong>"
          </li>
          <li>
            Formulario "<strong>&nbsp;Atenci&oacute;n al paciente&nbsp;</strong>
            "
          </li>
          <li>
            Formulario "<strong>&nbsp;Blog&nbsp;</strong>" sobre novedades y
            consejos de salud visual.
          </li>
        </ul>
        <p>Cita online</p>
        <p>
          Cuando cumplimente el formulario "Cita online" habilitado en el
          &aacute;rea de paciente de esta p&aacute;gina Web trataremos sus datos
          personales para gestionar su solicitud para mantener una cita
          m&eacute;dica en una de nuestras cl&iacute;nicas, siendo la base
          legitimadora de dicho tratamiento el consentimiento prestado por su
          parte al cumplimentar y enviar dicho formulario.
        </p>
        <p>
          Le informamos que, en caso de que solicite una cita online, se le
          dar&aacute; la posibilidad de cumplimentar un formulario cuya
          finalidad es agilizar el proceso de alta como paciente en nuestra
          cl&iacute;nica y acreditar su identidad. CB tratar&aacute; los datos
          facilitados mediante dicho formulario en atenci&oacute;n a su
          consentimiento, entendi&eacute;ndose &eacute;ste prestado por la libre
          cumplimentaci&oacute;n del citado formulario, mientras que trataremos
          su DNI en atenci&oacute;n a nuestro inter&eacute;s leg&iacute;timo en
          acreditar su identidad o la del menor del que ostenta la patria
          potestad o tutela. En caso de que no cumplimente dicho formulario o no
          remita la documentaci&oacute;n que se le solicite, el proceso de alta
          y verificaci&oacute;n de identidad se realizar&aacute; cuando acuda a
          nuestras instalaciones. En caso de que cumplimente dicho formulario y
          si Usted o, en su caso, el menor del que usted ostenta la patria
          potestad o tutela acude a la cita m&eacute;dica solicitada, dichos
          datos ser&aacute;n incorporados a su historia cl&iacute;nica,
          trat&aacute;ndolos con la finalidad de poder prestarle los servicios y
          tratamientos m&eacute;dicos contratados, gestionar y coordinar su
          historia cl&iacute;nica, gestionar la relaci&oacute;n contractual
          mantenida con nuestra cl&iacute;nica, as&iacute; como cumplir con
          cuantas obligaciones legales nos correspondan. En este sentido, CB
          llevar&aacute; a cabo estos tratamientos en atenci&oacute;n a la
          ejecuci&oacute;n de la relaci&oacute;n contractual que Usted formalice
          con la misma y en cumplimiento de cuantas obligaciones legales nos
          correspondan. Asimismo, en atenci&oacute;n a nuestro inter&eacute;s
          leg&iacute;timo, CB tratar&aacute; sus datos personales, incluyendo,
          la comunicaci&oacute;n de los mismos entre las compa&ntilde;&iacute;as
          de nuestro grupo, con fines administrativos internos, con la finalidad
          de llevar a cabo nuestra gesti&oacute;n interna.
        </p>
        <p>Videoconsulta</p>
        <p>
          Por otro lado, si Usted cumplimenta el formulario "Pide tu
          videoconsulta gratis" habilitado en el &aacute;rea del paciente de
          esta p&aacute;gina Web o en cualquier otra p&aacute;gina Web, CB
          tratar&aacute; sus datos personales para contactar con Usted para
          concretar fecha y hora en la que mantener una videoconsulta. Una vez
          concretada, se le enviar&aacute; un correo electr&oacute;nico de
          confirmaci&oacute;n que contendr&aacute; un enlace a trav&eacute;s del
          cual podr&aacute; conectarse a la videoconsulta, as&iacute; como un
          SMS recordatorio. Le informamos que los datos que nos facilite en el
          transcurso de dicha videoconsulta, incluyendo datos de salud,
          ser&aacute;n tratados por CB para atender su consulta. La base
          legitimadora de dicho tratamiento es el consentimiento otorgado por
          usted al cumplimentar y enviar libremente el citado formulario y
          participar en la videoconsulta.
        </p>
        <p>
          En caso de que Usted o el menor del que ostenta la patria potestad o
          tutela ya sea paciente de CB, la videoconsulta ser&aacute; atendida
          por un profesional m&eacute;dico, mientras que si a&uacute;n no lo es
          le atender&aacute; un comercial u &oacute;ptico que podr&aacute;
          asesorarle adecuadamente.
        </p>
        <p>
          En caso de que Usted o el menor del que ostente la patria potestad o
          tutela ya sea paciente de CB, los datos personales que nos facilite o
          que nuestros profesionales recaben o generen en el transcurso de la
          correspondiente videoconsulta, incluyendo sus datos de salud,
          ser&aacute;n incorporados en su historia cl&iacute;nica o en la del
          citado menor, trat&aacute;ndolos de conformidad con la
          informaci&oacute;n que le facilitamos cuando se dio de alta como
          paciente en CB y que puede consultar en cualquier momento en
          cualquiera nuestras cl&iacute;nicas. En este sentido, le recordamos
          que CB trata los datos incorporados en su historia cl&iacute;nica en
          atenci&oacute;n a la ejecuci&oacute;n de la relaci&oacute;n
          contractual que Usted haya formalizado con la misma y en cumplimiento
          de cuantas obligaciones legales nos correspondan.
        </p>
        <p>
          Asimismo, le informamos que, en caso de que Usted o el citado menor ya
          sea paciente de CB, &eacute;sta podr&aacute; comunicar sus datos
          personales o los menor, incluido el informe m&eacute;dico o el
          resultado de la asistencia recibida en la videoconsulta en la que
          participe (i) al m&eacute;dico externo por parte del cual Usted o
          dicho menor venga derivado, en atenci&oacute;n al contrato suscrito
          entre usted y el citado m&eacute;dico externo; (ii) al centro de
          reconocimiento de conductores por el cual usted pueda venir derivado,
          en atenci&oacute;n al contrato suscrito entre usted y dicho centro;
          (iii) a la mutua colaboradora con la seguridad social por parte de la
          cual Usted pueda venir derivado, en atenci&oacute;n a nuestro
          inter&eacute;s leg&iacute;timo en gestionar la relaci&oacute;n
          contractual entre CB y la correspondiente mutua, mantenida en virtud
          de lo establecido por la Ley General de la Seguridad Social; (iv) a la
          compa&ntilde;&iacute;a de seguros de la que usted o el menor del que
          usted ostenta la patria potestad o tutela sea asegurado o cliente, con
          la finalidad exclusiva de gestionar con aquellas la prestaci&oacute;n
          de los servicios objeto de cobertura o para la aplicaci&oacute;n de
          los descuentos correspondientes y facturar el gasto sanitario de
          conformidad con el art&iacute;culo 99 de la Ley 20/2015, de 14 de
          julio, de ordenaci&oacute;n, supervisi&oacute;n y solvencia de las
          entidades aseguradoras y reaseguradoras; (v) a las autoridades
          sanitarias, judiciales o a la administraci&oacute;n tributaria si
          fueran requeridos por dichas autoridades en el marco de una
          investigaci&oacute;n, inspecci&oacute;n, procedimiento o similar, en
          los casos previstos legalmente siempre y cuando dicho requerimiento se
          encuentre debidamente justificado y fuera necesario para la finalidad
          de la misma.
        </p>
        <p>
          Usted confirma y garantiza la veracidad y exactitud de los datos
          aportados y que &eacute;stos se ajustan a su estado actual o al estado
          actual del menor del que ostenta la patria potestad o tutela.
        </p>
        <p>
          Asimismo, le informamos que las citadas "videoconsultas" se
          realizar&aacute;n a trav&eacute;s de Topbox, de manera que Nexmo
          Limited, como titular de dicha herramienta, podr&aacute; tener acceso
          a los datos personales facilitados en el marco de la videoconsulta en
          la que Usted o el menor del que ostente la patria potestad o tutela
          participe, como consecuencia de los servicios que CB le ha contratado.
          Nexmo Limited es una compa&ntilde;&iacute;a ubicada en Canad&aacute;
          que, a su vez, tiene subcontratados proveedores de servicios fuera del
          Espacio Econ&oacute;mico Europeo, produci&eacute;ndose, por tanto, una
          transferencia internacional de los citados datos. Dichas
          transferencias internacionales cumplen con las garant&iacute;as
          adecuadas, habiendo suscrito Nexmo Limited las correspondientes
          cl&aacute;usulas contractuales tipo.
        </p>
        <p>
          CB no se hace responsable del tratamiento que Nexmo Limited pueda
          hacer del resto de datos que &eacute;sta pueda recabar directamente
          como consecuencia del uso por su parte de Topbox en la medida en que
          los servicios contratados por CB para prestarle a Usted el servicio de
          videoconsultas no requieren que Nexmo Limited realice ning&uacute;n
          tratamiento adicional al necesario para la realizaci&oacute;n de las
          videoconsultas.
        </p>
        <h2>&Aacute;rea del Paciente</h2>
        <p>
          Por otro lado, en caso de que solicite su registro o el del menor del
          que usted ostenta la patria potestad o tutela en el "&Aacute;rea del
          paciente", a trav&eacute;s del formulario "&Aacute;rea Paciente", CB
          tratar&aacute; los datos personales facilitados mediante la
          cumplimentaci&oacute;n del mismo para gestionar el alta y acceso a
          dicha &Aacute;rea y a nuestra Aplicaci&oacute;n "Cl&iacute;nica
          Baviera" (si se la descarga), as&iacute; como para prestarle los
          servicios solicitados a trav&eacute;s de dicha &Aacute;rea y gestionar
          su relaci&oacute;n con CB. CB tratar&aacute; dichos datos en
          atenci&oacute;n a su consentimiento, entendi&eacute;ndose &eacute;ste
          prestado mediante la libre cumplimentaci&oacute;n del formulario
          habilitado.
        </p>
        <h2>Plan Amigo</h2>
        <p>
          En el caso de que Usted sea un paciente de CB operado en alguna de
          nuestras cl&iacute;nicas podr&aacute; participar en el Plan Amigo, a
          trav&eacute;s del "&Aacute;rea del Paciente" o de otras web de CB,
          mediante la cumplimentaci&oacute;n del formulario de
          participaci&oacute;n. CB tratar&aacute; los datos facilitados mediante
          dicho formulario en atenci&oacute;n a su consentimiento,
          entendi&eacute;ndose &eacute;ste prestado por la libre
          cumplimentaci&oacute;n del citado formulario, mientras que trataremos
          su DNI en atenci&oacute;n a nuestro inter&eacute;s leg&iacute;timo en
          acreditar su identidad o la del menor del que ostenta la patria
          potestad o tutela. Dentro del Plan Amigo no se le solicitar&aacute;n
          datos relativos a la persona a la que quiera invitar a conocer CB. No
          obstante, es necesario que Usted le facilite a la misma un
          c&oacute;digo &uacute;nico de usuario que podr&aacute; obtener a
          trav&eacute;s del &Aacute;rea de paciente y que estar&aacute; asociado
          de manera &uacute;nica a Usted. La persona invitada por Usted
          deber&aacute; mostrar dicho c&oacute;digo cuando acuda a alguna de
          nuestras cl&iacute;nicas y Cl&iacute;nica Baviera verificar&aacute;
          que efectivamente el c&oacute;digo mostrado es el asociado con su
          usuario, con la finalidad de gestionar su participaci&oacute;n y la de
          su amigo o familiar, de conformidad con las bases legales y
          mec&aacute;nica de dicho programa.
        </p>
        <p>Env&iacute;o de comunicaciones comerciales</p>
        <p>
          Adicionalmente, siempre que as&iacute; lo consienta expresamente, sus
          datos podr&aacute;n ser tratados con la finalidad de remitirle por
          cualquier medio, incluido por medios electr&oacute;nicos,
          informaci&oacute;n comercial y publicitaria de los servicios ofrecidos
          por CB.
        </p>
        <h2>
          <strong>
            &iquest;Por cu&aacute;nto tiempo conservaremos sus datos?
          </strong>
        </h2>
        <p>
          Los datos personales tratados con la finalidad de atender su solicitud
          de informaci&oacute;n, cita m&eacute;dica o sugerencias ser&aacute;n
          tratados durante el tiempo necesario para atender dicha solicitud o
          sugerencia. En caso de que Ud. haya consentido el env&iacute;o de
          comunicaciones comerciales por parte de CB, sus datos ser&aacute;n
          tratados con esta finalidad mientras usted no revoque su
          consentimiento.
        </p>
        <p>
          En caso de que mantenga una videoconsulta con uno de nuestros
          profesionales (m&eacute;dicos o no) conservaremos sus datos personales
          hasta que acuda a una de nuestras cl&iacute;nicas, con el fin de que
          el profesional m&eacute;dico que all&iacute; le atienda conozca los
          motivos de su visita y, en todo caso, si usted o el menor del que
          usted ostenta la patria potestad o tutela ya es paciente de CB,
          conservaremos los datos personales que pasen a formar parte de su
          historia cl&iacute;nica o la del citado menor el plazo durante el cual
          nos obligue la legislaci&oacute;n aplicable en materia sanitaria.
        </p>
        <p>
          Por otro lado, los datos facilitados mediante la
          cumplimentaci&oacute;n del formulario para darse de alta en el
          "&Aacute;rea del paciente" ser&aacute;n tratados y conservados
          mientras no solicite su baja de dicha &aacute;rea.
        </p>
        <p>
          Una vez transcurridos los plazos de conservaci&oacute;n indicados, los
          datos ser conservar&aacute;n debidamente bloqueados para su puesta a
          disposici&oacute;n a jueces y tribunales, Ministerio Fiscal o
          Administraciones P&uacute;blicas competentes, en particular de las
          autoridades de protecci&oacute;n de datos, para la exigencia de
          posibles responsabilidades derivadas de su tratamiento y solo por el
          plazo de prescripci&oacute;n de las mismas. Transcurrido ese plazo se
          proceder&aacute; a su destrucci&oacute;n.
        </p>
        <h2>
          <strong>
            &iquest;Obligatoriedad de facilitar los datos personales que se le
            solicitan?
          </strong>
        </h2>
        <p>
          Los datos personales que se le solicitan en cada formulario son los
          estrictamente necesarios para poder atender su solicitud, registro o
          participaci&oacute;n, de manera que en caso de no facilitarlos no
          podremos atender adecuadamente su solicitud, registro o
          participaci&oacute;n.
        </p>
        <h2>
          <strong>&iquest;Cu&aacute;les son sus derechos?</strong>
        </h2>
        <p>
          Todo interesado puede revocar el consentimiento, en su caso, prestado,
          as&iacute; como ejercer sus derechos de acceso, rectificaci&oacute;n,
          supresi&oacute;n, oposici&oacute;n, portabilidad y limitaci&oacute;n,
          mediante escrito dirigido al Departamento de Asesor&iacute;a
          Jur&iacute;dica de Cl&iacute;nica Baviera, S.A., sito en Paseo de la
          Castellana 20, 28046, Madrid o a&nbsp;
          <a href='mailto:datospersonales@grupobaviera.es'>
            datospersonales@grupobaviera.es
          </a>
          , acreditando debidamente su identidad. Igualmente puede presentar,
          cuando lo considere oportuno, una reclamaci&oacute;n ante la Agencia
          Espa&ntilde;ola de Protecci&oacute;n de Datos, especialmente, si
          considera infringidos sus derechos.
        </p>
      </Card>
    </div>
  )
}

export default Privacy
