import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../provider";
import "./select.scss";

const Select = (props) => {
	const { Options, actionOnSelect, prefijo = "Tu clínica", width, height } = props;
	const { state } = useAuth();
	const [isOpen, setIsOpen] = useState(false);
	const divRef = useRef(null);
	const onselectOption = (option) => {
		setIsOpen(false);
		actionOnSelect(option);
	}
	const handleClickOutside = (event) => {
		if (divRef.current && !divRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		const handleClick = (event) => handleClickOutside(event);
		document.addEventListener('click', handleClick);
		return () => {
			document.removeEventListener('click', handleClick);
		};
	}, []);

	return (
		<div className="select_component_container" ref={divRef}>
			<div className="select_componet_style" style={{ width: width, height: height }} onClick={() => { setIsOpen(!isOpen) }}>
				<span style={{ display: "flex", justifyContent: "flex-start", gap: "5px" }}>
					{prefijo ? <p className="prefijo_style">{prefijo}:</p> : ""}
					{state.isClinicSelected ? <p className="label_style">{state.isClinicSelected}</p> : ""}
				</span>
				<div className={`button-icon ${isOpen ? "open" : "close"}`}></div>
			</div>
			{isOpen && Options ?
				<div className="options_container" style={{ width: width }}>
					{Options?.length > 0 ? Options.map((item) => {
						return (
							<div className="option_select_style" key={item.value} onClick={() => onselectOption(item)}>{item.name}</div>
						);
					}) : ""}
				</div>
				: ""
			}
		</div>
	)
};

export default Select;
