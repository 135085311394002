import React, { useEffect, useState } from 'react'
import withoutrecipes from '../../../../assets/images/withoutrecipes.png'
import ErrorDialog from '../../../../components/ErrorDialog/ErrorDialog'
import Loading from '../../../../components/Loading/Loading'
import ReportContent from '../../../../components/ReportContent/ReportContent'
import { useAuth } from '../../../../provider'
import { MedicationServices } from '../../../../services/medications'
import './medication.scss'
import ModalBox from '../../../../components/ModalBox/ModalBox'
import PDFViewer from '../../../../components/PDFViewer/PdfViewer'


/**
 *
 * @param {String} error
 */

const Medication = ({ error, props }) => {
  const { state } = useAuth()
  const { idClinica, idPaciente } = state.user
  const [recipes, setRecipes] = useState(null)
  const [base64String, setBase64String] = useState(null)

  useEffect(() => {
    setRecipes(null);
    let isFetching = true
    const getRecetas = async () => {
      const result = await MedicationServices.getUserMedications({
        idClinica,
        idPaciente
      })
      return result
    }
    getRecetas().then(res => {
      if (isFetching) {
        setRecipes(res || [])
      }
    })
    return () => (isFetching = false)
  }, [state.userToken])

  const handleGetPDF = async (recipe, target = 'open') => {
    const result = await MedicationServices.getRecetaPdf({
      idClinica,
      idPaciente,
      recipe,
      target
    })
    console.log(result)
    if (result) setBase64String(result)
  }

  const renderElements = () => {
    return (
      <React.Fragment>
        {base64String &&
          <div style={{ width: '100%', height: '80vh' }}>
            <PDFViewer onClose={() => setBase64String(null)} base64String={base64String.base64str} fileName={base64String.fileName} />
          </div>
        }
        {recipes.map((item, index) => {
          return (
            <ReportContent
              key={index}
              title={item.medicamento}
              date={item.fecha}
              icon='report'
              buttonLabel='Descargar'
              buttonAction={() => handleGetPDF(item, 'download')}
              viewAction={() => handleGetPDF(item)}
              type={item.descripcion}
            ></ReportContent>
          )
        })}
      </React.Fragment>
    )
  }

  return !recipes ? (
    <Loading></Loading>
  ) : recipes.length > 0 ? (
    renderElements()
  ) : (
    <div className='empty-container'>
      {error ? <ErrorDialog text={error} /> : null}
      <img src={withoutrecipes} alt='' />
      <p className='no-content-info'>En este momento no tienes recetas disponibles.</p>
    </div>
  )
}

export default Medication
