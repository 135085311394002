import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../../provider";
import SignInLayout from "../SingInLayout/SignInLayout";

/**
 * 
 * @param {Component} component Componente que se renderiza en la ruta
 * @param {any} rest El resto de props que se han pasado al componente. 
 * @description PasswordRoute se utiliza para renderizar una ruta si el usuario 
 * se ha logueado pero aún no ha completado el formulario para renovar el password. 
 * 
 * PasswordRoute recibe como props un componente y las props de este componente. 
 * Devuelve el componente con sus props si el usuario está cargado en el estado. 
 * Si no nos redirecciona a login
 *  
 */

const PasswordRoute = ({ component: Component, ...rest }) => {


  const { state } = useAuth();


  return (
    <Route
      {...rest}
      render={(props) =>
        !state.user ? (
          <Redirect to="/intro/login" />
        ) : (
          <SignInLayout {...props}>
            <Component {...props} />
          </SignInLayout>
        )
      }
    ></Route>
  );
};

export default PasswordRoute;
