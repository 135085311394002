import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ButtonBack from "../ButtonBack/ButtonBack";
import { previousPathsArray } from "../constants";
import Updating from "../Updating/Updating";
import TabComponent from "./Tab/Tab-component";
import "./tabs.scss";
const Tabs = ({
	isRefreshing,
	childrenComp,
	activeTab,
	loading,
	onClickTabItem,
	children,
	setError,
	setCurrentLocation,
	currentLocation,
}) => {
	const history = useHistory();

	const goBack = () => {
		if (currentLocation) {
			const targetPath = previousPathsArray.find((item) => {
				return item.currentPath === currentLocation;
			});
			if (targetPath) {
				setCurrentLocation(targetPath.previousPath);
				return targetPath.previousPath;
			} else {
				setCurrentLocation("/Paciente/Plan/Descubre-plan-amigo");
				return "/Paciente/Plan/Descubre-plan-amigo";
			}
		} else if (currentLocation === "/Paciente/Plan/Descubre-plan-amigo") {
			return "/Paciente/Cuenta";
		} else {
			setCurrentLocation("/Paciente/Plan/Descubre-plan-amigo");
			return "/Paciente/Plan/Descubre-plan-amigo";
		}
	};
	useEffect(() => {
		setCurrentLocation(history.location.pathname);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history.location.pathname]);

	let icons = ['plan','recomendar','premios']
	return (
		<div className="container">
			<ButtonBack goBack={goBack} history={history} useCustomUrl={true} />

			{!loading && <Updating isRefreshing={isRefreshing} />}
			<ul className="tab-list">
				{childrenComp.map((tab, index) => {
					return (
						<li className="tab-container" key={index}>
							<TabComponent
								label={tab.label}
								activeTab={activeTab}
								handleClick={onClickTabItem}
								url={tab.url}
								customClass={icons[index]}
							></TabComponent>
						</li>
					);
				})}
			</ul>
			<div className="content">{children}</div>
		</div>
	);
};

export default Tabs;
