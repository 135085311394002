import React, { useEffect, useState } from "react";
import * as api from "../../../../../services/plan-amigo-service";
import "./hotel.scss";
import PrizesListPage from "../../../../../components/PrizesListPage/PrizesListPage";
import { usePlanAmigo } from "../../../../../plan-amigo-provider";
import MessageModal from "../../../../../components/MessageModal/MessageModal";
import ButtonMain from "../../../../../components/ButtonMain/ButtonMain";
import ExchangePrizesCard from "../ExchangePrizeCard/ExchangePrizesCard";
import { useAuth } from "../../../../../provider";
import InfinityScroll from "../../../../../components/InfinityScroll/InfinityScroll";
import { useHistory } from "react-router-dom";
import hotelsCover from "../../../../../assets/images/hotels.jpg";
import hotelsIcon from "../../../../../assets/images/icons/travel-icon.svg";
import useWindowSize from "../../../../../hooks/useWindowSize";
import ModalBox from "../../../../../components/ModalBox/ModalBox";
import Loading from "../../../../../components/Loading/Loading";

const Hotel = ({ setCurrentLocation }) => {
	const [provincesList, setProvincesList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [successMessage, setSuccessMessage] = useState(null);
	const [exchangeModalOpen, setExchangeModalOpen] = useState(false);
	const [numberOfHotels, setNumberOfHotels] = useState(0);
	const [localHotelList, setLocalHotelList] = useState([]);
	const { planAmigoState, setHotelList, setFriendsAndPrizes } = usePlanAmigo();
	const { state, setIsNotNearBottom } = useAuth();
	const [isCitySelected, setIsCitySelected] = useState(false);
	const { width } = useWindowSize();

	const { isNearBottom } = state;

	const history = useHistory();

	/**
	 * Setea la localización a la url correcta
	 */

	useEffect(() => {
		setCurrentLocation(history.location.pathname);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history.location.pathname]);

	/**
	 * Se setea la lista de provincias para el select y la lista de hoteles
	 * @see getDataAsync => lista de hoteles
	 * @see filterProvinces => filtro de provincias
	 */

	useEffect(() => {
		setLoading(true);
		getDataAsync();

		if ((planAmigoState.hotelList.length > 0) & (numberOfHotels <= 10)) {
			getNextHotel(planAmigoState.hotelList, numberOfHotels);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Se setea la lista de spas que se mostrarán en pantalla
	 */

	useEffect(() => {
		if (planAmigoState.hotelList.length > 0 && !isNearBottom) {
			getNextHotel(planAmigoState.hotelList, numberOfHotels);
			filterProvinces(planAmigoState.hotelList);
			setIsNotNearBottom();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [planAmigoState.hotelList]);

	/**
	 * Si el usuario está cerca del fondo, cargar más elementos
	 */

	useEffect(() => {
		if (isNearBottom && !isCitySelected && numberOfHotels !== 0) {
			getNextHotel(planAmigoState.hotelList, numberOfHotels);
			setIsNotNearBottom();
		}
		if (isNearBottom && planAmigoState.hotelList.length === localHotelList.length) {
			setIsNotNearBottom();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isNearBottom]);

	/////////////////////////////
	// Gestión de eventos y servicios
	/////////////////////////////

	/**
	 * Llamada a la api que devuelve en
	 * caso de éxito una lista de hoteles y un status de la petición
	 * y en caso de error un error
	 */

	const getDataAsync = async () => {
		try {
			setLoading(true);
			const hotels = await api.getHotelList();
			if (hotels.status === 200) {
				// Se setea la lista de hoteles en el state global

				await setHotelList(hotels.data.datos.hoteles);

				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			setErrorMessage(error);
		}
	};

	/**
	 *
	 * @param {String} province
	 * Filtra los spas para obtener aquellos cuya provincia coincide con el parámetro province
	 * Si se selecciona todas las provincias (value = "all") devuelve un listado de todas las provincias.
	 */

	const filterHotels = (province) => {
		setNumberOfHotels(0);
		setIsCitySelected(true);
		if (province === "all") {
			setIsCitySelected(false);
			setIsNotNearBottom();
			getNextHotel(planAmigoState.hotelList, numberOfHotels);
			return;
		}

		const filteredHotels = planAmigoState.hotelList.filter((hotel) => hotel.prov === province);
		setLocalHotelList(filteredHotels);
	};

	/**
	 *
	 * @param {Array.<{
	 * 		id: String,
	 * 		img_format: String,
	 * 		img_original: String,
	 * 		promo: String,
	 * 		prov: String,
	 * 		title: String,
	 * 		url: String,
	 * }>} prizesList
	 * Filtra las provincias para obtener una lista de ciudades a
	 * partir de las cuales se renderizará el select de selección de provincia.
	 */

	const filterProvinces = (prizesList) => {
		const provinceList = prizesList.reduce(
			(acc, curr) => (acc.includes(curr.prov) ? acc : [...acc, curr.prov]),
			[]
		);
		setProvincesList(provinceList.sort((a, b) => a.localeCompare(b)));
	};

	/**
	 *
	 ** @param {Array.<{
	 * 		id: String,
	 * 		img_format: String,
	 * 		img_original: String,
	 * 		promo: String,
	 * 		prov: String,
	 * 		title: String,
	 * 		url: String,
	 * }>} hotels
	 * @param {Number} size
	 *
	 *
	 *
	 */

	const getNextHotel = (hotels, size) => {
		const elements = hotels.slice(size, size + 10);
		setNumberOfHotels(size + 10);
		setLocalHotelList([...localHotelList, ...elements]);
		setLoading(false);
		return elements;
	};

	const hotelConfig = {
		title: "ELIGE TU PREMIO",
		subtitle: "Experiencia Ruralka",
		prizeDescription: `Disfruta de 2 noches de hotel en régimen de alojamiento y desayuno para dos personas. ¡Vive una experiencia única!`,
		prizesList: localHotelList,
		handleSelectChange: filterHotels,
		friendsTarget: 5,
	};

	//////////////////////////
	/// Gestión de eventos
	//////////////////////////

	/**
	 * Activa/desactiva el modal de canjeo de premio
	 */

	const toggleExchangeModal = () => setExchangeModalOpen(!exchangeModalOpen);

	/**
	 * Gestión de la llamada a la api que sirve para canjear el premio
	 */

	const exchangeModal = async () => {
		try {
			setLoading(true);
			const data = {
				"tipo-premio": 5,
			};
			setExchangeModalOpen(false);

			const res = await api.requestExchangePrize(data);

			if (Number(res.status) === 200 || res.status === "ok") {
				const res = await api.requestPointsAndPrizesByDNI();
      	const { puntos, premios, status, url_pedir_cita_amigos, status_code } =
        res;

				if (status_code === 3) {
					setFriendsAndPrizes(
						puntos.disponibles,
						[],
						premios,
						true,
						url_pedir_cita_amigos
					);
				}
				setLoading(false);
				setSuccessMessage("Has realizado tu solicitud con éxito");
			}

			if (res.status === 400 || res.body.status === "error") {
				setLoading(false);
				setExchangeModalOpen(false);
				setErrorMessage("Ha ocurrido un error con tu petición");
			}
		} catch (error) {
			setExchangeModalOpen(false);
			setErrorMessage(error);
			setLoading(false);
		}
	};

	const redirectToFriends = () =>
		history.push("/Paciente/Plan/Recomienda-clinica-baviera-tus-amigos");

	return (
		<React.Fragment>
			{loading && (
				<ModalBox>
					<Loading />
				</ModalBox>
			)}
			{exchangeModalOpen && (
				<MessageModal modalToggle={toggleExchangeModal} isError={false}>
					<div className="modal-icon">
						<img src={hotelsIcon} alt="" />
					</div>
					<h4>Canjea tu Hotel</h4>
					<p className="modal-exchange-description">
						¿Estás seguro de que quieres canjear tu premio o prefieres esperar a acumular más
						amigos?
					</p>
					<div className="flex-container">
						<ButtonMain customClass="tertiary" label="Voy a esperar" action={toggleExchangeModal} />
						<ButtonMain label="Canjear ya" action={exchangeModal} />
					</div>
				</MessageModal>
			)}
			{successMessage && (
				<MessageModal
					modalMessage="Has canjeado tu premio satisfactoriamente"
					setModalMessage={setSuccessMessage}
					isError={false}
				></MessageModal>
			)}
			{errorMessage && (
				<MessageModal
					modalMessage={errorMessage}
					setModalMessage={setErrorMessage}
					isError={true}
				></MessageModal>
			)}

			{!loading && (
				<React.Fragment>
					<InfinityScroll>
						<PrizesListPage {...hotelConfig} provincesList={provincesList} />
					</InfinityScroll>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

export default Hotel;
