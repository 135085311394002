export const API_URL = 'https://www.clinicabaviera.com/planamigo/app/api'

export const REGISTER = `${API_URL}/registro`
export const GET_PRIZES = `${API_URL}/tipos-premio-canjear`
export const GET_PRIZE = `${API_URL}/detalles-tipo-premio/`
export const GET_TEXTOS_ESTATICOS = `${API_URL}/textos-estaticos`
export const GET_SPA_LIST = `${API_URL}/listado/spa`;
export const GET_HOTEL_LIST = `${API_URL}/listado/experiencia`
export const REQUEST_POINTS_PRIZES_BY_DNI = `${API_URL}/puntos-premios-x-dni`
export const REQUEST_USER_CHECK = `${API_URL}/comprobacion-usuario`
export const REQUEST_EXCHANGE_PRIZE = `${API_URL}/canjear-premio`
export const REQUEST_INVITATION = `${API_URL}/invitar`






