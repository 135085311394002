import React, { useState, useEffect, Suspense } from "react";
import * as api from "../../../services/services";
import { useAuth } from "../../../provider";
import moment from "moment";
import CalendarPicker from "./CalendarPicker/CalendarPicker";
import Loading from "../../../components/Loading/Loading";
import ModalBox from "../../../components/ModalBox/ModalBox";
import noCalendarBackground from "../../../assets/images/no-calendar.jpg";

import AppointmentCard from "./AppointmentCard/AppointmentCard";
import "./calendarHandler.scss";
import ChangeAppointment from "./ChangeAppointment/ChangeAppointment";
import Updating from "../../../components/Updating/Updating";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";

/**
 * @description Componente que engloba toda la lógica referente a las citas del paciente
 */

const CalendarHandler = () => {
  /////////////////////////////
  // Configuración del componente
  /////////////////////////////

  const [dataTimes, setDataTimes] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorStatus, setErrorStatus] = useState(null);
  const [openChangeAppointment, setOpenChangeAppointment] = useState(false);
  const [dataPlaceHolders, setDataPlaceHolders] = useState([]);
  const [loading, setLoading] = useState(false);
  const { state, setUserAppointments } = useAuth();


  const [dataDates, setDataDates] = useState(null);
  const [chosenAppointment, setChosenAppointment] = useState({});

  const [selectedDateString, setSelectedDateString] = useState(null);

  // const [dateClick, setDateClick] = useState(false);

  /**
   * @description Dependiendo del número de citas genera la clase que tendrá cada componente CardAppointment
   * @returns {string} citasCSSClass
   * @see {CardAppointment}
   */

  const createCitasLengthClass = () => {
    if (state.userAppoinments) {
      let citasCSSClass = `citas-length-${state.userAppoinments.length}`;
      return citasCSSClass;
    }
  };

  const citasLengthClass = createCitasLengthClass();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getDataAsync();
    }
    return () => { mounted = false }
  }, [state.userToken]);

  /////////////////////////////
  // Gestión de servicios
  /////////////////////////////

  /**
   * @description Hace una petición a la API para conseguir las citas del paciente.
   * Una vez conseguidas se guardan en el estado local y global
   */

  async function getDataAsync() {
    setIsRefreshing(true);
    setLoading(true);
    try {
      if (state.userAppoinments) {
        setLoading(false);
      }
      let response = await api.getAppointments(state.user);
      const sortedDates = response.sort((a, b) => (moment(a.fecha) > moment(b.fecha) ? 1 : -1));

      setUserAppointments(sortedDates);
      setLoading(false);
      setIsRefreshing(response ? false : true);
    } catch (error) {
      setLoading(false);
      setIsRefreshing(false);
      setErrorMessage(error.message);
      setErrorStatus(error.status);
    }
  }

  /**
   *
   * @param {Object} item
   * @description Hace una petición a la API para conseguir todas las horas libres.   */

  async function getAgendaDataAsync(item) {
    try {
      let parameters = {
        idMedico: item.idMedico,
        idProceso: item.idProceso,
        fecha: item.fecha,
      };
      console.log(parameters)
      let response = await api.getAgendaPlaceHolders(parameters);

      await setDataPlaceHolders(response);
      await buildDateElements(response);

      // setLoading(false);
    } catch (e) {
      // setLoading(false);
    }
  }

  /////////////////////////////
  // Formateo y gestión de datos y fechas
  /////////////////////////////

  /**
   *
   * @param {Array} placeHolders
   * @description Transforma las fechas libres provenientes del backend un formato utilizable en el componente.
   * También filtra los días, eliminando aquellos que estén repetidos.
   *
   */

  const buildDateElements = (placeHolders) => {
    let uniqueDays = [],
      result = [];

    for (let i = 0; i < placeHolders.length; i++) {
      const element = placeHolders[i];
      if (element.fecha === -1) {
        uniqueDays.push(element.fecha);
      }
    }

    uniqueDays.sort().forEach((e) => {
      result.push({ date: e, selected: false });
    });
    setDataDates(result);
  };

  /**
   *
   * @param {Array} placeHolders
   * @description Transforma las horas libres provenientes del backend un formato utilizable en el componente.
   * También filtra las horas, eliminando aquellos que estén repetidos.
   *
   */
  const buildTimeElements = (placeHolders) => {
    if (selectedDate) {
      let uniqueTimes = [],
        result = [];

      for (let i = 0; i < placeHolders.length; i++) {
        const element = placeHolders[i];
        if (element.fecha === selectedDate) {
          if (uniqueTimes.indexOf(element.hora) === -1) {
            uniqueTimes.push(element.hora);
          }
        }
      }

      uniqueTimes.forEach((e) => {
        result.push({ time: e, selected: false });
      });
      setDataTimes(result);
    }
  };

  /**
   *
   * @param {Date} d
   * @description Formatea una fecha en UTC a strings
   */

  const formatUTCtoDate = (d) => {
    let date = new Date(d);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return (date = {
      day: Number(dd),
      month: Number(mm),
      year: Number(yyyy),
    });
  };

  const dateToString = (date) => {
    return moment(date).format("DD/MM/yyyy").split("/").join("");
  };

  /**
   *
   * @param {Object} item Fecha seleccionada
   *
   *
   */

  const onClickDate = (item) => {
    let result = [...dataDates];

    if (item.selected) {
      result.forEach((e) => (e.selected = false));
      setSelectedDate(null);
    } else {
      result.forEach((e) => (e.selected = e.date === item.date));
      setSelectedDate(item.date);
    }

    setDataDates(result);
    buildTimeElements(dataTimes);
  };

  /////////////////////////////
  // Funciones de renderizado
  /////////////////////////////

  /**
   * @description Renderiza el modal de cambio de cita
   */

  const renderChangeAppointmentModal = () => {
    const LazyModal = React.lazy(() => import("../../../components/ModalBox/ModalBox"));

    return (
      <Suspense
        fallback={
          <ModalBox>
            <Loading />
          </ModalBox>
        }
      >
        <LazyModal onToggle={setOpenChangeAppointment} loading={dataPlaceHolders && dataDates ? false : true}>
          <ChangeAppointment
            appointment={chosenAppointment}
            placeHolders={dataPlaceHolders}
            selectedDate={selectedDate}
            loading={dataDates ? true : false}
            freeDates={dataDates}
            onConfirmAppointment={onConfirmAppointment}
            onClickDate={onClickDate}
          // dateClicked={dateClick}
          />
        </LazyModal>
      </Suspense>
    );
  };

  const onChangeAppointment = async (item) => {
    await getAgendaDataAsync(item);
  };

  const onConfirmAppointment = async (values) => {
    setOpenChangeAppointment(false);

    // try {
    //   await api.updateAppointment(values);
    //   setModalSuccessOpen(true);
    // } catch (error) {
    //   setModalErrorOpen(true);
    // }
  };

  const renderError = () =>
    errorMessage || errorStatus ? <ErrorHandler errorMessage={errorMessage} errorStatus={errorStatus} /> : null;

  /////////////////////////////
  // Renderizado del componente
  /////////////////////////////

  /**
   * @description Renderizado del componente CalendarHandler
   */

  return (
    <React.Fragment>
      {!loading ? (
        <React.Fragment>
          <div className="dates-container">
            <Updating isRefreshing={isRefreshing} />
            {renderError()}
            <h2 className="subtitle">Gestiona tus citas</h2>
            <div className="card-container">
              <div className="calendar-container">
                <CalendarPicker
                  appointmentDates={state.userAppoinments}
                  setSelectedDateString={setSelectedDateString}
                />
              </div>
              <div className={`appointments-container ${citasLengthClass}`}>
                {loading ? (
                  <Loading />
                ) : state.userAppoinments && state.userAppoinments.length > 0 ? (
                  state.userAppoinments.map((cita, index) => {
                    return (!selectedDateString || selectedDateString === dateToString(cita.fecha)) && (
                      <AppointmentCard
                        appointment={cita}
                        key={index}
                        date={formatUTCtoDate(cita.fecha)}
                        toggleChangeModal={setOpenChangeAppointment}
                        setChosenAppointment={setChosenAppointment}
                        onChangeAppointment={onChangeAppointment}
                      />
                    );
                  })
                ) : (
                  <div className="no-calendar empty-container">
                    <img src={noCalendarBackground} alt="" />
                    <p className="no-content-info">En estos momentos no tienes citas</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <Loading />
      )}

      {openChangeAppointment ? renderChangeAppointmentModal() : null}
    </React.Fragment>
  );
};

export default CalendarHandler;
