import React from "react";
import iconActive from "../../../../assets/images/icons/friend-active-color-icon.svg";
import iconInactive from "../../../../assets/images/icons/friend-inactive-color-icon.svg";
import "./friendsIndicator.scss";

/**
 *
 */

//let friends = 6;

const FriendsIndicator = ({numberOfFriends}) => {

	/**
	 * @description Número de veces que se deberá renderizar el número de amigos.
	 */

	const n = 5;

	return (
		<div className="friends-indicator-container">
			<p>
				Tienes {numberOfFriends} {numberOfFriends !== 1 ? `amigos operados` : `amigo operado`}
			</p>
			<ul className="friends-list">
				{[...Array(n)].map((_, index) => {
					return (
						<li  key={index}>
							<img src={numberOfFriends >= index + 1 ? iconActive : iconInactive} alt="" />
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default FriendsIndicator;
