import React, { useEffect, useState } from "react";
import { useAuth } from "../../provider";
import ErrorDialog from "../ErrorDialog/ErrorDialog";

/**
 * 
 * @param {string} errorMessage String con el texto de un error de servidor
 * @param {string} errorStatus String con el texto de un error de servidor
 * 
 * @description Los dos errores (errorMessage, errorStatus) 
 * se filtran para decidir que tipo de componente se debe de utilizar 
 * para renderizarlo. 
 * 
 * Si el mensaje del error es un string cuyo valor pasado a número entero es menor que 401
 * se retorna un ErrorDialog
 * 
 * Por el contrario, si el error es mayor que 401, se llama a una función de 
 * Redux que guarda el texto del error en el estado global. 
 * Este error del estado global será renderizado por App.js, 
 * el componente de más alto nivel de la aplicación  
 */

const ErrorHandler = ({ errorMessage, errorStatus }) => {
  const [error, setError] = useState(null);

  const { setGlobalError } = useAuth();

  useEffect(() => {
    if (Number(errorMessage) === 401 || errorStatus === 401) {
    // if (Number(errorMessage) >= 401) {
      if (errorMessage === "401") setGlobalError("No tiene autorización para realizar esta operación");
      if (errorStatus === 401) setGlobalError("No tiene autorización para realizar esta operación");
      if ( errorMessage === "204") setGlobalError("Hubo un error en la ejecución de tu peticion. Vuelve a intentarlo");
  

      else setGlobalError("Ocurrió un error inesperado")
    } else {
      if (errorMessage === null) setError("Parametros de entrada incorrectos");
      else if (errorMessage === "" || errorStatus === "401") setError("Se ha producido un errorFromServer. La sesión ha caducado");
      else if (errorMessage === "-1") setError("Los parámetros introducidos no son correctos");
      else if (errorMessage === "-2") setError("Los parámetros necesarios para continuar no han sido introducidos");
      else if (errorMessage === "-3") setError("Parametros de entrada incorrectos");
      else if (errorMessage === "-4") setError("Parametros de entrada incorrectos. La petición no se ha podido realizar con éxito");
      else if (errorMessage === "-5") setError("Información incorrecta. Contacta con tu clínica.");
      else if (errorMessage === "-6") setError("Información incorrecta. Contacta con tu clínica.");
      else if (errorMessage === "-8") setError("No se ha podido enviar el email.");
      else if (errorMessage === "-11") setError("Parametros de entrada incorrectos. Error al localizar el informe");
      else if (errorMessage === "-13") setError("Servicio de videoconsulta no disponible.");
      else if (errorMessage === "-14") setError("Ha superado el número de videoconsultas activas por paciente.");
      else if (errorMessage === "-15") setError("El motivo de la consulta no está disponible para el paciente.");
      else if (errorMessage === "-23") setError("Para acceder ponte en contacto con tu clínica.");
      else if (errorMessage === "-24") setError("La contraseña antigua coincide con la nueva. Elige otra contraseña");
      else if (errorStatus === "204") setError("Hubo un error en la ejecución de tu peticion. Vuelve a intentarlo");
      else if (errorStatus === "El archivo no puede ser mayor que 5MB") setError("El archivo no puede ser mayor que 5MB");
      else if (errorStatus === "204" || errorMessage === "204") setError("Hubo un error en la ejecución de tu peticion. Vuelve a intentarlo");
      else if (errorStatus === "401" || errorMessage === "401") setError("No tiene autorización para realizar esta operación");
      else setError("Ocurrió un error inesperado")

    }
  }, []);


  return error ? <ErrorDialog text={error} /> : null;
};

export default ErrorHandler;
