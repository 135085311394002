import React from "react";
import "./buttonMain.scss";

/**
 *
 * @param {object} props
 * @param {string} props.label String que se pintara, si existe, dentro del botón
 * @param {string} props.icon Icono del botón
 * @param {string} props.customClass Clase personalizada. Depende del contexto en que se renderice el botón
 * @param {function} props.action Acción que se realizará cuando el botón se pulse. Puede ser un submit o un toggle.
 */
const ButtonMain = (props) => {
  const { label, iconClass, customClass, action, actionParams, type = "button", isSubmitting, dirty, renference } = props;

  function handleClick(event) {
    if(event) event.preventDefault()
    if (action) action(actionParams);
  }

  return (
    <React.Fragment>
      {type === "link" ? (
        <a
          href={action}
          target="_blank"
          rel="noreferrer"
          className={`${!customClass ? "button-app" : `button-app ${customClass}`} ${!label ? "icon-button" : ""}`}
          ref={renference}
        >
          {!label ? "" : <span className="button-label">{label ? label : null}</span>}
          {!iconClass ? "" : <span className={`button-icon ${iconClass}`}></span>}
        </a>
      ) : (
        <button
          type={type}
          onClick={handleClick}
          className={`${!customClass ? "button-app" : `button-app ${customClass}`} ${!label ? "icon-button" : ""}`}
          disabled={isSubmitting || dirty}
          ref={renference}
        >
          {!label ? "" : <span className="button-label">{label}</span>}
          {!iconClass ? "" : <span className={`button-icon ${iconClass}`}></span>}
        </button>
      )}
    </React.Fragment>
  );
};

export default ButtonMain;
