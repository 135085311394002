import * as axios from 'axios'
import * as c from '../constants'
import moment from 'moment'
import { onCallFunction } from './firebase'

export async function authenticate() {
  try {
    return localStorage.getItem('userToken')
  } catch (e) {
    throw handler(e)
  }
}
export async function getHeadersAsync(require_token?: boolean = true) {
  let token = await authenticate()
  return {
    headers: {
      Authorization: require_token ? token : '',
      'Content-Type': 'application/json',
      'X-VERSION': 331
    }
  }
}

export async function login(data) {
  try {
    let options = await getHeadersAsync(false)
    let res = await axios.post(c.LOGIN, JSON.stringify(data), options)
    return {
      user: res.data
    }
  } catch (e) {
    throw handler(e)
  }
}

export async function loginSession(userToken) {
  try {
    let options = await getHeadersAsync()
    let res = await axios.post(c.SESSION, null, options)
    return {
      user: res.data
    }
  } catch (e) {
    throw handler(e)
  }
}

export async function getBudgets(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let url = `${c.GET_BUDGETS}?`
    let res = await axios.get(url, options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function getBudgetPdf(data, id) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let url = `${c.GET_BUDGET_PDF}?&idPresupuesto=${id}`
    let res = await axios.get(url, options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function getInvoices(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let url = `${c.GET_INVOICES}`
    let res = await axios.get(url, options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function getInvoicePdf(data, id, serie) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let url = `${c.GET_INVOICE_PDF}?idFactura=${id}&serie=${serie}`
    let res = await axios.get(url, options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function getFinances(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let url = `${c.GET_FINANCES}`
    let res = await axios.get(url, options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function getReports(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let url = `${c.GET_REPORTS}?`
    let res = await axios.get(url, options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function getReportPdf(data, id) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let url = `${c.GET_REPORT_PDF}?&idInforme=${id}`
    let res = await axios.get(url, options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function getMedications(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let url = `${c.GET_MEDICATIONS}?`
    let res = await axios.get(url, options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function getMedicationPdf(data, id) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let url = `${c.GET_MEDICATION_PDF}?&idMedicacion=${id}`
    let res = await axios.get(url, options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function getNotes(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let url = `${c.GET_NOTES}?`
    let res = await axios.get(url, options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}

// export function downloadFile(base64data, id){
//     try
//     {
//         if (data == null) throw new Error("401");
//         let pdf_base64 = `data:application/pdf;base64,${base64data}`;
//         let pdfLocation = `${RNFetchBlob.fs.dirs.DownloadDir}/${id}.pdf`;
//         RNFetchBlob.fs.writeFile(pdfLocation, pdf_base64, 'base64');
//         return pdfLocation;
//     }
//     catch (e) {
//         throw handler(e);
//     }
// }
export async function getReportTypes(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let url = `${c.GET_REPORT_TYPES}`
    let res = await axios.get(url, options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function getScores(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let url = `${c.GET_SCORES}?`
    let res = await axios.get(url, options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function getContactReasons(data) {
  try {
    let options = await getHeadersAsync()
    let url = `${c.GET_CONTACT_REASONS}`
    let res = await axios.get(url, options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function getAppointments(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let url = `${c.GET_APPOINTMENTS}`
    let res = await axios.get(url, options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function getPastAppointments(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let url = `${c.GET_PAST_APPOINTMENTS}`
    let res = await axios.get(url, options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function getIncomingAppointments(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let url = `${c.GET_INCOMING_APPOINTMENTS}?`
    let res = await axios.get(url, options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function getAlerts(data) {
  try {
    let responseAlerts = await getIncomingAppointments(data)
    let incoming = responseAlerts.filter(a => a.idPaciente == data.idPaciente)
    var today = moment(new Date()).format('DDMMYY')
    let responseAppointments = await getAppointments(data)
    let appointments = responseAppointments.filter(
      a => moment(a.fecha).format('DDMMYY') == today
    )
    return {
      incoming: incoming,
      appointments: appointments
    }
  } catch (e) {
    throw handler(e)
  }
}
export async function getOperations(data) {
  try {
    let options = await getHeadersAsync()
    let url = `${c.GET_OPERATIONS}?`
    let res = await axios.get(url, options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function getAgendaPlaceHolders(data) {
  try {
    let options = await getHeadersAsync()
    let url = `${c.GET_PLACEHOLDERS}?idMedico=${data.idMedico}&idProceso=${data.idProceso}&fecha=${data.fecha}`
    let res = await axios.get(url, options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function getUserData(data) {
  try {
    let options = await getHeadersAsync()
    let url = `${c.GET_USER_DATA}?`
    let res = await axios.get(url, options);
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function getConferences(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let url = `${c.GET_CONFERENCES}?`
    let res = await axios.get(url, options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function getNotifications(data) {
  try {
    // if (data == null) throw new Error("401");
    let options = await getHeadersAsync()
    let url = `${c.GET_NOTIFICATIONS}?`
    let res = await axios.get(url, options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function setNotifications(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let res = await axios.post(
      c.SET_NOTIFICATIONS,
      JSON.stringify(data),
      options
    )
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function forgotPassword(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let res = await axios.post(
      c.FORGOT_PASSWORD,
      JSON.stringify(data.dni),
      options
    )
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function deleteUser(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let res = await axios.post(c.DELETE_USER, JSON.stringify(data), options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function resetPassword(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let res = await axios.post(c.RESET_PASSWORD, JSON.stringify(data), options)

    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function updateUser(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let res = await axios.post(c.SET_USER_DATA, JSON.stringify(data), options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function requestContact(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let res = await axios.post(c.REQUEST_CONTACT, JSON.stringify(data), options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function updateAppointment(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let res = await axios.post(c.SET_APPOINTMENT, JSON.stringify(data), options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function requestConference(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let res = await axios.post(
      c.REQUEST_CONFERENCE,
      JSON.stringify(data),
      options
    )
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function requestReport(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let res = await axios.post(c.REQUEST_REPORT, JSON.stringify(data), options)

    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function requestScore(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let res = await axios.post(c.REQUEST_SCORE, JSON.stringify(data), options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}
export async function updateProfile(userId, data) {
  try {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    }

    const form_data = new FormData()
    for (let key in data) form_data.append(key, data[key])

    let res = await axios.put(
      `${c.SET_USER_DATA}/${userId}`,
      form_data,
      options
    )
    return res.data
  } catch (e) {
    throw handler(e)
  }
}

export async function uploadFile(data, file) {
  try {
    let token = await authenticate()

    const options = {
      headers: {
        Accept: 'application/pdf',
        'Content-Type': 'application/json',
        Authorization: token
      }
    }

    const form_data = new FormData()
    form_data.append('file', file)

    let url = `${c.UPLOAD_FILE}`

    let res = await axios.post(url, form_data, options)
    return res
  } catch (e) {
    throw handler(e)
  }
}

/**
 *
 * @param {Object} data
 * @param {String} data.idClinica
 * @param {String} data.idPaciente
 * Devuelve una lista de los archivos subidos por el paciente
 * @returns
 */

export async function getFileList(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let url = `${c.GET_UPLOADED_FILES}`
    let res = await axios.get(url, options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}

/**
 *
 * @param {Object} data
 * @param {String} data.idClinica
 * @param {String} data.idPaciente
 * @param {Number} data.idArchivo
 * Devuelve un string que contiene el base64 del documento
 * @returns {Object{nombreArchivo: String, content: String}}
 */
export async function getBase64File(data) {
  try {
    if (data == null) throw new Error('401')
    let options = await getHeadersAsync()
    let url = `${c.GET_FILE_PDF}?idArchivo=${data.idArchivo}`
    let res = await axios.get(url, options)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}

export function handler(err) {
  let error = err
  // Error 😨
  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */

    if (error.response.data.Message)
      return new Error(error.response.data.Message)

    if (error.response.data && error.response.data !== '')
      return new Error(error.response.data)
  } else if (error.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */
    return new Error(error.request)
  } else {
    // Something happened in setting up the request and triggered an Error
    if (error && error.message) return new Error(error.message)
    else return new Error(error)
  }
}
