import React, { useMemo, useReducer, useContext } from "react";

import planAmigoReducer, {
	initialState,
	SET_PRIVACY_TO_ACCEPTED,
	SET_FRIENDS_AND_PRIZES,
	SET_HOTEL_LIST,
	SET_SPA_LIST,
	USER_HAS_PLAN,
} from "./plan-amigo-reducer";

const PlanAmigoContext = React.createContext();

function PlanAmigoProvider(props) {
	const [state, dispatch] = useReducer(planAmigoReducer, initialState || {});

	const setPrivacyToAccepted = async (value) => {
		try {
			dispatch({
				type: SET_PRIVACY_TO_ACCEPTED,
				data: { isPrivacyAccepted: value },
			});
		} catch (error) {
			throw new Error(error);
		}
	};

	/**
	 *
	 * @param {Boolean} isUserInPlan Setea en el estado si el usuario tiene plan amigo o no.
	 */

	const setUserHasPlan = async (isUserInPlan) => {
		try {
			dispatch({
				type: USER_HAS_PLAN,
				data: { isUserInPlan },
			});
		} catch (error) {
			throw new Error(error);
		}
	};

	/**
	 *
	 * @param {Object} friends
	 * @param {[Object]} prizes
	 * @param {Boolean} hasBeenUpdated
	 *
	 * Pasa al estado la respuesta del servidor del plan Amigo.
	 *
	 */
	const setFriendsAndPrizes = async (
		numberOfFriends,
		friendsList,
		prizes,
		hasBeenUpdated,
		referralUrl
	) => {
		try {
			dispatch({
				type: SET_FRIENDS_AND_PRIZES,
				data: { numberOfFriends, friendsList, prizes, hasBeenUpdated, referralUrl },
			});
		} catch (error) {
			throw new Error(error);
		}
	};

	/**
	 *
	 * @param {Array.<{
	 * 		id: String,
	 * 		img_format: String,
	 * 		img_original: String,
	 * 		promo: String,
	 * 		prov: String,
	 * 		title: String,
	 * 		url: String,
	 * }>} hotelList
	 *
	 * Envía la lista acción de setear la lista de hoteles en el store global
	 *
	 */

	const setHotelList = async (hotelList) => {
		try {
			dispatch({
				type: SET_HOTEL_LIST,
				data: hotelList,
			});
		} catch (error) {
			throw new Error(error);
		}
	};

	/**
	 *
	 * @param {Array.<{
	 * 		id: String,
	 * 		img_format: String,
	 * 		img_original: String,
	 * 		promo: String,
	 * 		prov: String,
	 * 		title: String,
	 * 		url: String,
	 * }>} spaList
	 *
	 * 	 Envía la lista acción de setear la lista de spas en el store global
	 */

	const setSpaList = async (spaList) => {
		try {
			dispatch({
				type: SET_SPA_LIST,
				data: spaList,
			});
		} catch (error) {
			throw new Error(error);
		}
	};

	const value = useMemo(() => {
		return {
			setPrivacyToAccepted,
			setUserHasPlan,
			planAmigoState: state,
			setFriendsAndPrizes,
			setHotelList,
			setSpaList,
		};
	}, [state]);

	return <PlanAmigoContext.Provider value={value}>{props.children}</PlanAmigoContext.Provider>;
}

const usePlanAmigo = () => useContext(PlanAmigoContext);
export { usePlanAmigo, PlanAmigoContext };
export default PlanAmigoProvider;
