import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import "./forgotPassword.scss";
import Card from "../../../components/Card/Card";
import InputBlock from "../../../components/InputBlock/InputBlock";
import ButtonMain from "../../../components/ButtonMain/ButtonMain";
import * as api from "../../../services/services";
import { useHistory } from "react-router-dom";
import ButtonBack from "../../../components/ButtonBack/ButtonBack";
import ErrorDialog from "../../../components/ErrorDialog/ErrorDialog";
import Loading from "../../../components/Loading/Loading";
import ModalBox from "../../../components/ModalBox/ModalBox";

const ForgotPassword = () => {
  /////////////////////////////
  // Configuración del componente
  /////////////////////////////

  const [error, setError] = useState();

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [reciveSms, setReciveSms] = useState(false)
  /////////////////////////////
  // Gestión de eventos y servicios
  /////////////////////////////

  /**
   *
   * @param {object} values
   * @description Gestiona la respuesta de onSubmitForgetPassword
   * Si la respuesta del servidor es exitosa (se ha enviado un password) redirecciona a/login
   * Si no hay éxito en la respuesta se crea un mensaje de error
   *
   */

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      let response = await api.forgotPassword(values);
      if (response === "") {
        setReciveSms(true)
        setTimeout(() => {
          history.push("/intro/login");
        }, 5000);
       
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      if (error.message === "-2") setError("Parámetros de entrada no validos.");
      if (error.message === "-18") setError("Error al enviar el sms.");
      if (error.message === "-19") setError("El paciente no tiene un móvil vinculado al área de paciente.");
      if (error.message === "-23") setError("El DNI no está registrado o está bloqueado.");
    }
  };

  /////////////////////////////
  // Funciones de renderizado
  /////////////////////////////

  /**
   *
   * @description Esquema de validación del formulario
   * @see {renderFormik}
   *
   */

  const validationSchema = yup.object({
    dni: yup
      .string()
      .required("El campo NIF/NIE es obligatorio")
      .matches(
        /^((([X-Z])|([LM])){1}([-]?)([0-9]{7})([-]?)([a-zA-Z]{1}))|(([0-9]{8})([-]?)([a-zA-Z]))/,
        "El formato de NIF/NIE es incorrecto"
      ),
  });

  /**
   *
   * @description Si hay un error devuelve un mensaje
   *
   */

  const renderErrors = () => (error ? <ErrorDialog text={error} /> : null);

  /**
   *
   * @returns {Component}
   * @description Genera un componente formulario con la librería Formik que valida los
   * valores User y Password
   *
   */

  const renderFormik = () => {
    return (
      <React.Fragment>
        {loading ? (
          <Loading />
        ) : (
          <React.Fragment>
            {/* Título */}
            <h1>¿Has olvidado tu contraseña?</h1>
            {renderErrors()}
            <Formik
              initialValues={{ dni: "" }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                actions.resetForm();
                handleSubmit(values);
              }}
            >
              {(props) => (
                <Form onSubmit={props.onSubmit} className="form-container">
                  <p>
                    Introduce tu número de identificación (NIF o NIE) y te enviaremos un SMS con las instrucciones para
                    reactivar tu contraseña.
                  </p>
                  <div
                    className="form-group"
                    onClick={() => {
                      if (error) {
                        setError(null);
                      }
                    }}
                  >
                    <InputBlock
                      label="NIF/NIE"
                      type="text"
                      placeholder="Ejemplo 00000000X"
                      handleChange={props.handleChange}
                      paramChange="dni"
                      values={props.values.dni}
                      handleBlur={props.handleBlur}
                      paramBlur="dni"
                    />
                  </div>

                  {props.touched.dni && props.errors.dni ? <ErrorDialog text={props.errors.dni} /> : null}

                  <ButtonMain
                    label="Continuar"
                    iconClass="arrow-right-white"
                    action={props.handleSubmit}
                    type="submit"
                  />
                </Form>
              )}
            </Formik>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  /////////////////////////////
  // Renderizado del componente
  /////////////////////////////

  /***
   *
   * @return {Component}
   * @see {renderFormik}
   * @description Render el formulario de Formik
   *
   */

  return (
    <Card customClass="forgot-card">
      <ButtonBack />
      {reciveSms && <ModalBox onToggle={()=>setReciveSms(false)}>
				<div className="success-text"  style={{maxWidth: "80vw"}}>Te hemos enviado un sms con una contraseña provisional, accede con ella para poder cambiar tu contraseña actual.</div>
			</ModalBox>}
      {renderFormik()}
    </Card>
  );
};

export default ForgotPassword;
