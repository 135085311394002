import React from "react";
import { useHistory } from "react-router-dom";
import ButtonMain from "../../../../../components/ButtonMain/ButtonMain";
import "./discoverCard.scss";

/**
 *
 * @param {String} title
 * @param {String} text
 * @param {String} image
 * @param {String} imageAlt
 * @param {String} url
 * @param {String} urlLabel
 *
 * Renderiza un card con los parámetros anteriores
 *
 */

const DiscoverCard = ({ title, text, image, imageAlt, url, urlLabel }) => {
	const history = useHistory();

	const redirecTo = () => {
		history.push(url);
	};

	return (
		<div className="step-container">
			<div className="step">
				<div className="step-left">
					<img src={image} alt={imageAlt} />
				</div>
				<div className="step-right">
					<h3 className="title">{title}</h3>
					<p>{text}</p>
					<ButtonMain action={redirecTo} className="actions" label={urlLabel} />
				</div>
			</div>
		</div>
	);
};

export default DiscoverCard;
